// Amenities layers configs
import uk_amenities from "@/config/amenities_maps/uk_amenities_map.json";
import osm_bus_amenities from "@/config/amenities_maps/osm_bus_map.json";
import local_transport_amenities from "@/config/amenities_maps/local_transport_map.json";
import us_amenities from "@/config/amenities_maps/us_amenities_map.json";
import ev_charger from "@/config/amenities_maps/ev_charger_map.json";
import community_facilities from "@/config/amenities_maps/community_facilities_map.json";
import ea_reservoir_flood_extents_map_config from "@/config/amenities_maps/ea_reservoir_flood_extents_map.json";
import ea_flood_risk_zones_map_config from "@/config/amenities_maps/ea_flood_risk_zones_map.json";
import ea_main_rivers_map_config from "@/config/amenities_maps/ea_main_rivers_map.json";
import ea_surface_water_flood_risk_extents_map_config from "@/config/amenities_maps/ea_surface_water_flood_risk_extents_map.json";
import national_cycle_network_map_config from "@/config/amenities_maps/national_cycle_network_map.json";
import uk_aq_surrounding_land_uses_map_config from "@/config/amenities_maps/uk_aq_surrounding_land_uses_map.json";
import uk_aq_monitoring_sites_map_config from "@/config/amenities_maps/uk_aq_monitoring_sites_map.json";
import uk_aq_construction_impact_map_config from "@/config/amenities_maps/uk_aq_construction_impact_map.json";
import uk_aq_receptor_map_config from "@/config/amenities_maps/uk_aq_receptor_map.json";

// Toolbox icons
import SiteLocationMapIcon from "@/assets/icons/site_location_map.svg?url";
import AmenitiesMapIcon from "@/assets/icons/amenities_map.svg?url";
import BusServicesIcon from "@/assets/icons/bus_services.svg?url";
import CyclingIsochroneIcon from "@/assets/icons/cycling_isochrone.svg?url";
import ChargerIcon from "@/assets/icons/charger.svg?url";
import CommunityFacilitiesMapIcon from "@/assets/icons/groups_2.svg?url";
import FloodRiskMapIcon from "@/assets/icons/flood_risk.svg?url";

export interface AmenityConfig {
    name: string;
    sourceLink: string;
    sourceText: string;
    legend: string[];
}

export interface AmenitiesMapConfig {
    name: string;
    icon_url?: string;
    amenities: Record<string, AmenityConfig>;
    amenities_list_title?: string;
    isochrone_travel_mode_selector?: boolean;
}

export const amenitiesMaps: Record<string, AmenitiesMapConfig> = Object.freeze({
    cycling_map: {
        name: "Cycling Access Map",
        icon_url: CyclingIsochroneIcon,
        amenities: national_cycle_network_map_config,
        amenities_list_title: "Show cycle networks",
        isochrone_travel_mode_selector: false,
    },
    amenities_map: {
        name: "Amenities Map",
        icon_url: AmenitiesMapIcon,
        amenities: uk_amenities,
    },
    osm_bus_map: {
        name: "Bus Map",
        icon_url: BusServicesIcon,
        amenities: osm_bus_amenities,
    },
    local_transport_map: {
        name: "Local Transport Facilities Map",
        icon_url: BusServicesIcon,
        amenities: local_transport_amenities,
    },
    us_amenities_map: {
        name: "Amenities Map",
        icon_url: AmenitiesMapIcon,
        amenities: us_amenities,
    },
    ev_charger_map: {
        name: "Electric Vehicle Charger Map",
        icon_url: ChargerIcon,
        amenities: ev_charger,
    },
    community_facilities_map: {
        name: "Community Facilities Map",
        icon_url: CommunityFacilitiesMapIcon,
        amenities: community_facilities,
    },
    ea_reservoir_flood_extents_map: {
        name: "Reservoir Flood Extents Map",
        icon_url: FloodRiskMapIcon,
        amenities: ea_reservoir_flood_extents_map_config,
    },
    ea_main_rivers_map: {
        name: "EA Main Rivers Map",
        icon_url: FloodRiskMapIcon,
        amenities: ea_main_rivers_map_config,
    },
    ea_flood_risk_zones_map: {
        name: "Flood Risk Zones Map",
        icon_url: FloodRiskMapIcon,
        amenities: ea_flood_risk_zones_map_config,
    },
    ea_surface_water_flood_risk_extents_map: {
        name: "Surface Water Flood Risk Extents Map",
        icon_url: FloodRiskMapIcon,
        amenities: ea_surface_water_flood_risk_extents_map_config,
    },
    national_cycle_network_map: {
        name: "National Cycle Network Map",
        icon_url: CyclingIsochroneIcon,
        amenities: national_cycle_network_map_config,
    },
    aq_surrounding_land_uses_map: {
        name: "Surrounding Land Uses Map",
        icon_url: SiteLocationMapIcon,
        amenities: uk_aq_surrounding_land_uses_map_config,
    },
    aq_monitoring_sites_map: {
        name: "Monitoring Sites Map",
        icon_url: SiteLocationMapIcon,
        amenities: uk_aq_monitoring_sites_map_config,
    },
    aq_construction_impact_map: {
        name: "Construction Impact Map",
        icon_url: SiteLocationMapIcon,
        amenities: uk_aq_construction_impact_map_config,
    },
    aq_receptor_map: {
        name: "Receptor Map",
        icon_url: SiteLocationMapIcon,
        amenities: uk_aq_receptor_map_config,
    },
});
