<template>
    <div ref="map_root" class="map" :style="cssVars"></div>
    <MapOverlay ref="overlay" :overlay_information="store.maps[props.mapKey]?.overlayInformation ?? {}" />
    <CSSLoader />
</template>

<script setup>
import { useFormDataStore } from "@/stores/FormDataStore";
import { useUserStore } from "@/stores/UserStore";
import { ref, onMounted, onUnmounted, computed, watch, nextTick } from "vue";
import SchemeflowMap from "../maps/map";
import {
    aspectRatios,
    isochroneDefaults,
    isochroneDefaultStyles,
    siteBoundaryLegendItem,
    highwayMapLegendItems,
    maxInitialZoomLevel,
    northArrows,
    northArrowColor,
} from "../maps/constants";
import { Style, Fill, Stroke } from "ol/style";
import convert from "color-convert";
import CSSLoader from "@/components/CSSLoader.vue";
import { legendItemFromConfig } from "@/maps/configIcons";
import GeoJSON from "ol/format/GeoJSON";
import { getLineDashArray } from "@/maps/annotationstyles";
import { bbox } from "@turf/bbox";
import { buffer } from "@turf/buffer";
import { featureCollection } from "@turf/helpers";
import MapOverlay from "./MapOverlay.vue";
import { watchDebounced } from "@vueuse/core";
import { SFLegendItem, SFLegendIconRectangle } from "@/maps/sflegend";

const store = useFormDataStore();
const userStore = useUserStore();

const props = defineProps({
    mapKey: String,
    isochroneProfile: {
        type: String,
        default: "select",
    },
    legendConfigMasterList: {
        type: Array,
        default: () => [],
    },
    amenities: {
        type: Object,
        default() {
            return {};
        },
    },
    suppressErrors: {
        type: Boolean,
        default: false,
    },
    markReadyForExportMilliseconds: {
        type: Number,
    },
    mapWidthOverride: {
        type: String,
    },
});

const map_root = ref(null);
const overlay = ref(null);
let mapReadyForExportTimeout;

// Calculate legend background color
// Always white with opacity
const legendBackgroundColor = computed(() => {
    let opacity = store.formData[props.mapKey].styles.legend_bg_opacity;
    // Convert opacity integer (1-255) to hex (00-ff)
    opacity = Math.abs(opacity || 204)
        .toString(16)
        .padStart(2, "0");
    let color = `#ffffff${opacity}`;
    return color;
});

const scaleBarRightMargin = computed(() => {
    const scaleBarIsOnTheRight =
        store.formData[props.mapKey].styles.scale_bar === "top-right" ||
        store.formData[props.mapKey].styles.scale_bar === "bottom-right";
    const scaleBarIsInBox = store.formData[props.mapKey].styles.scale_bar_in_box;

    if (!scaleBarIsOnTheRight) {
        return "auto";
    }
    if (scaleBarIsInBox) {
        return "8px";
    }
    return "20px";
});

const cssVars = computed(() => {
    // Scale bar
    let scaleBarVars = {
        "--sf-map-scale-bar-display": store.formData[props.mapKey].styles.scale_bar === "none" ? "none" : "block",
        "--sf-map-scale-bar-bottom":
            store.formData[props.mapKey].styles.scale_bar === "bottom-left" ||
            store.formData[props.mapKey].styles.scale_bar === "bottom-right"
                ? "8px"
                : "auto",
        "--sf-map-scale-bar-right": scaleBarRightMargin.value,
        "--sf-map-scale-bar-left":
            store.formData[props.mapKey].styles.scale_bar === "top-left" ||
            store.formData[props.mapKey].styles.scale_bar === "bottom-left"
                ? "8px"
                : "auto",
        "--sf-map-scale-bar-top":
            store.formData[props.mapKey].styles.scale_bar === "top-left" ||
            store.formData[props.mapKey].styles.scale_bar === "top-right"
                ? "8px"
                : "auto",
        "--sf-map-scale-bar-padding": store.formData[props.mapKey].styles.scale_bar_in_box ? "10px" : "unset",
        "--sf-map-scale-bar-border": store.formData[props.mapKey].styles.scale_bar_in_box ? "1px solid black" : "unset",
        "--sf-map-scale-bar-background": store.formData[props.mapKey].styles.scale_bar_in_box
            ? `rgb(255 255 255 / ${store.formData[props.mapKey].styles.legend_bg_opacity / 255})`
            : "unset",
        "--sf-map-scale-bar-first-marker-top": store.formData[props.mapKey].styles.scale_bar_in_box ? "10px" : "unset",
        "--sf-map-scale-step-text-bottom": store.formData[props.mapKey].styles.scale_bar_in_box ? "0" : "unset",
    };

    // Legend
    let legendVars = {
        "--sf-map-legend-display": store.formData[props.mapKey].styles.legend === "none" ? "none" : "block",
        "--sf-map-legend-background": legendBackgroundColor.value,
        "--sf-map-legend-bottom":
            store.formData[props.mapKey].styles.legend === "bottom-left" ||
            store.formData[props.mapKey].styles.legend === "bottom-right"
                ? "8px"
                : "auto",
        "--sf-map-legend-right":
            store.formData[props.mapKey].styles.legend === "top-right" ||
            store.formData[props.mapKey].styles.legend === "bottom-right"
                ? "8px"
                : "auto",
        "--sf-map-legend-left":
            store.formData[props.mapKey].styles.legend === "top-left" ||
            store.formData[props.mapKey].styles.legend === "bottom-left"
                ? "8px"
                : "auto",
        "--sf-map-legend-top":
            store.formData[props.mapKey].styles.legend === "top-left" ||
            store.formData[props.mapKey].styles.legend === "top-right"
                ? "8px"
                : "auto",
    };
    let otherMapVars = {
        "--sf-map-aspect-ratio": store.formData[props.mapKey].styles.aspect_ratio || aspectRatios["16:9"],
    };

    if (props.mapWidthOverride) {
        otherMapVars["--sf-map-width"] = props.mapWidthOverride;
    }

    // Hide north arrow image if
    return {
        ...otherMapVars,
        ...scaleBarVars,
        ...legendVars,
    };
});

// watchers set up in async function must be explicitly unwatched to avoid
// memory leaks
let unwatchLegendConfig;
let unwatchSiteMarker;
let unwatchIsochroneGeoJSON;
let unwatchIsochroneStyle;
let unwatchBaseLayers;
let unwatchColorFilters;
let unwatchNorthArrow;
let unwatchSiteBoundaryBufferZones;

onMounted(async () => {
    store.maps[props.mapKey] = new SchemeflowMap({
        redlinePolygonSource: store.redlinePolygonSource,
        scaleBarUnits: userStore.thisRegionConfig.scaleBarUnits,
        mapKey: props.mapKey,
    });

    // Load red line polygon from store
    if (store.formData.redline_polygon_geojson) {
        store.maps[props.mapKey].updateRedlinePolygon(JSON.parse(store.formData.redline_polygon_geojson));
    }

    // Update position and zoom of map when it moves
    store.maps[props.mapKey].getMap().on("moveend", storeMapView);

    // Move map to position
    if (store.formData[props.mapKey].center_latitude && store.formData[props.mapKey].center_longitude) {
        store.maps[props.mapKey].setCenter(
            store.formData[props.mapKey].center_longitude,
            store.formData[props.mapKey].center_latitude
        );
    } else {
        store.maps[props.mapKey].setCenter(store.longitude, store.latitude);
    }

    // Set zoom
    if (store.formData[props.mapKey].zoom) {
        store.maps[props.mapKey].setZoom(store.formData[props.mapKey].zoom);
    } else {
        store.maps[props.mapKey].setZoom(store.formData.sitemap_zoom);
    }

    // If no (map-specific) site location value in database, use scheme location
    if (!store.formData[props.mapKey].site_marker_location) {
        store.formData[props.mapKey].site_marker_location = {
            longitude: store.formData.analysis_longitude,
            latitude: store.formData.analysis_latitude,
        };
    }
    // Plot site location marker
    store.maps[props.mapKey].setPinLocation(
        store.formData[props.mapKey].site_marker_location.longitude,
        store.formData[props.mapKey].site_marker_location.latitude
    );

    // Update site marker position in formData when it is moved
    store.maps[props.mapKey].siteLocationPointSource.on("change", function () {
        const newFeatures = store.maps[props.mapKey].siteLocationPointSource.getFeatures();
        if (newFeatures.length) {
            const newPosition = newFeatures[0].getGeometry().flatCoordinates;
            store.formData[props.mapKey].site_marker_location.longitude = newPosition[0];
            store.formData[props.mapKey].site_marker_location.latitude = newPosition[1];
        }
    });

    // Show/hide site location pin / site boundary based on value in formData
    store.maps[props.mapKey].toggleOverlayLayer("site_marker", store.formData[props.mapKey].styles.site_marker);
    store.maps[props.mapKey].toggleOverlayLayer("redline", store.formData[props.mapKey].styles.site_boundary);

    // Show/hide isochrones based on prop value
    store.maps[props.mapKey].toggleOverlayLayer("isochrones", true);

    store.maps[props.mapKey].setTarget(map_root.value);
    store.maps[props.mapKey].setOverlayElement(overlay.value.overlay_element);

    // North Arrow configuration
    unwatchNorthArrow = watch(
        [
            () => store.formData[props.mapKey].styles.north_arrow_style,
            () => store.formData[props.mapKey].styles.north_arrow_color,
            () => store.formData[props.mapKey].styles.compass,
            () => store.formData[props.mapKey].styles.custom_north_arrow,
            () => store.formData[props.mapKey].styles.aspect_ratio,
        ],
        async ([style, color, position, customArrow, aspectRatio]) => {
            // If position is 'none', no arrow to show
            // Or if custom style selected but no custom icon set, no arrow to show
            if (position === "none" || (style === "custom" && !customArrow?.object_path)) {
                store.maps[props.mapKey].setNorthArrow();
                return;
            }

            let northArrowContainer = document.createElement("div");

            if (style !== "custom") {
                northArrowContainer.innerHTML = northArrows[style];

                const svgElement = northArrowContainer.querySelector("svg");
                if (svgElement) {
                    svgElement.setAttribute("width", "32");
                    svgElement.setAttribute("height", "45");
                    svgElement.style.fill = northArrowColor[color];
                    svgElement.style.stroke = northArrowColor[color];
                }
            } else {
                const imgElement = document.createElement("img");
                imgElement.setAttribute("src", `/api/storage/icons/download/${customArrow.object_path}`);
                imgElement.setAttribute("width", "32");
                imgElement.setAttribute("height", "45");

                northArrowContainer.appendChild(imgElement);
            }

            await nextTick();
            let mapWidth = 600; // default map width in pixels, use this for fallback
            let mapHeight = mapWidth / aspectRatio;
            const mapElement = document.querySelector(".map");
            if (mapElement) {
                // Use actual map element size if it is non-zero
                // When doing pregen, element size always gives zero
                mapWidth = mapElement.offsetWidth || mapWidth;
                mapHeight = mapElement.offsetHeight || mapHeight;
            }

            const rightOffsetString = `-${mapWidth - 8}px`;
            const bottomOffsetString = `-${mapHeight - 8}px`;

            northArrowContainer.style.position = "absolute";
            northArrowContainer.style.width = "32px";
            northArrowContainer.style.height = "45px";
            northArrowContainer.style.left = ["top-left", "bottom-left"].includes(position) ? "8px" : "auto";
            northArrowContainer.style.right = ["top-right", "bottom-right"].includes(position)
                ? rightOffsetString
                : "auto";
            northArrowContainer.style.top = ["top-left", "top-right"].includes(position) ? "8px" : "auto";
            northArrowContainer.style.bottom = ["bottom-left", "bottom-right"].includes(position)
                ? bottomOffsetString
                : "auto";

            store.maps[props.mapKey].setNorthArrow(northArrowContainer);
        },
        { immediate: true }
    );

    // legend configuration
    // masterList is a list of other keys of legend_config, selecting which are
    // displayed in the legend and in which order. The lists associated with
    // other keys can then be updated by relevant components without reference
    // to other elements within the legend
    store.maps[props.mapKey].legend_config = {
        masterList: props.legendConfigMasterList,
        site_loc: [siteBoundaryLegendItem],
        highway: highwayMapLegendItems,
        bus_stops: [],
        rail_stations: [
            legendItemFromConfig({ item: "rail_station", mapKey: props.mapKey }),
            legendItemFromConfig({ item: "metro_station", mapKey: props.mapKey }),
        ],
        isochrones: [],
        amenities: [],
        road_accidents: [],
        public_transport_isochrones: [],
        buffer_zones: [],
    };

    unwatchLegendConfig = watch(
        [() => store.maps[props.mapKey].legend_config, () => store.formData[props.mapKey].styles.legend_title],
        () => {
            updateMapLegend();
        },
        {
            deep: true,
            immediate: true,
        }
    );

    unwatchBaseLayers = watch(
        () => store.formData[props.mapKey].styles.base_map,
        (newValue) => {
            store.maps[props.mapKey].setBaseLayer(newValue);
        },
        {
            immediate: true,
        }
    );

    unwatchSiteMarker = watch(
        [
            () => store.formData[props.mapKey].styles.site_marker,
            () => store.formData[props.mapKey].styles.site_boundary,
        ],
        () => {
            let newSiteLocConfig = [];

            // Site marker
            if (store.formData[props.mapKey].styles.site_marker) {
                newSiteLocConfig.push(legendItemFromConfig({ item: "site_location", mapKey: props.mapKey }));
            }

            // Site boundary
            if (store.formData[props.mapKey].styles.site_boundary) {
                newSiteLocConfig.push(siteBoundaryLegendItem);
            }

            store.maps[props.mapKey].legend_config.site_loc = newSiteLocConfig;

            // Toggle layers on map
            store.maps[props.mapKey].toggleOverlayLayer("redline", store.formData[props.mapKey].styles.site_boundary);
            store.maps[props.mapKey].toggleOverlayLayer("site_marker", store.formData[props.mapKey].styles.site_marker);
        },
        {
            immediate: true,
        }
    );

    unwatchColorFilters = watch(
        [() => store.formData[props.mapKey].styles.grayscale_filter, () => store.formData[props.mapKey].styles.opacity],
        () => {
            store.maps[props.mapKey].setColorFilters(
                store.formData[props.mapKey].styles.grayscale_filter,
                store.formData[props.mapKey].styles.opacity
            );
        },
        {
            immediate: true,
        }
    );

    // Set up isochrones if required
    // if no saved values, use appropriate defaults
    // This is (still) necessary because when maps are intially created, they get isochrone config from default style,
    // which is stored under styles. This is then transferred to the isochrone config when the map is loaded.
    if (!store.formData[props.mapKey].isochrone_config) {
        // If there is a firm specific default for map style, use that, else use global default
        if (store.formData[props.mapKey].styles.isochrone_config) {
            store.formData[props.mapKey].isochrone_config = store.formData[props.mapKey].styles.isochrone_config;
        } else {
            store.formData[props.mapKey].isochrone_config = isochroneDefaults[props.isochroneProfile];
        }
    }
    // If no isochrone styles saved, initialise with default values
    if (!store.formData[props.mapKey].isochrone_styles) {
        // if there is a firm specific default for map style, use that, else use global default
        if (store.formData[props.mapKey].styles.isochrone_styles) {
            store.formData[props.mapKey].isochrone_styles = store.formData[props.mapKey].styles.isochrone_styles;
        } else {
            store.formData[props.mapKey].isochrone_styles = isochroneDefaultStyles;
        }
    }

    // If map isochrone styles list is shorter than the firm and global default lists, augment it
    if (store.formData[props.mapKey].isochrone_styles.length < isochroneDefaultStyles.length) {
        for (
            let idx = store.formData[props.mapKey].isochrone_styles.length;
            idx < isochroneDefaultStyles.length;
            idx++
        ) {
            store.formData[props.mapKey].isochrone_styles.push(
                store.formData[props.mapKey].styles.isochrone_styles[idx] ?? isochroneDefaultStyles[idx]
            );
        }
    }

    // Initial isochrone display
    store.maps[props.mapKey].toggleOverlayLayer(
        "isochrones",
        store.formData[props.mapKey].isochrone_config.show_isochrones
    );

    // If isochrone is stored, use that to draw map. If not, fetch
    // isochrone if isochrone should be displayed
    if (
        store.formData[props.mapKey].isochrone_config?.show_isochrones &&
        store.formData[props.mapKey].isochrone_geojson
    ) {
        store.maps[props.mapKey].setIsochrone(store.formData[props.mapKey].isochrone_geojson);
        if (store.formData[props.mapKey].isochrone_geojson.bbox?.length) {
            store.maps[props.mapKey].fitMapView(store.formData[props.mapKey].isochrone_geojson.bbox);
        }
    } else if (store.formData[props.mapKey].isochrone_config.show_isochrones) {
        try {
            await store.fetchIsochrones(props.mapKey, { suppressErrors: props.suppressErrors });
            store.maps[props.mapKey].setIsochrone(store.formData[props.mapKey].isochrone_geojson);
            if (store.formData[props.mapKey].isochrone_geojson.bbox?.length) {
                store.maps[props.mapKey].fitMapView(store.formData[props.mapKey].isochrone_geojson.bbox);
            }
        } catch (error) {
            console.error("Error fetching isochrones:", error);
        }
    }

    // Store updated map view after fitting to isochrone
    storeMapView();

    unwatchIsochroneGeoJSON = watch(
        [
            () => store.formData[props.mapKey].isochrone_geojson,
            () => store.formData[props.mapKey].isochrone_config.show_isochrones,
        ],
        ([isochrone_data, show_isochrones]) => {
            // If an isochrone is currently selected for editing, geojson change
            // has come from the map, so don't modify map
            if (store.maps[props.mapKey].isochroneSelectInteraction.getFeatures().getLength() > 0) {
                return;
            }

            // Set isochrone on map
            if (isochrone_data) {
                store.maps[props.mapKey].setIsochrone(isochrone_data);
            }
            store.maps[props.mapKey].toggleOverlayLayer("isochrones", show_isochrones);

            // fit view to data if bbox provided by backend
            if (isochrone_data?.bbox?.length && show_isochrones) {
                store.maps[props.mapKey].fitMapView(isochrone_data.bbox);
            }
        }
    );

    // Watch for changes to isochrone styles, and update isochrones and legend
    unwatchIsochroneStyle = watch(
        () => store.formData[props.mapKey].isochrone_styles,
        () => {
            updateIsochroneStyle();
            store.updateIsochroneLegend(props.mapKey);
        },
        {
            deep: true,
            immediate: true,
        }
    );

    // if needed, reposition map to include appropriate features.
    // If map has bbox_to_fit set, use it and unset
    // Do this at the end of onMounted function to ensure this is the view
    // actually displayed (view may be changed by isochrone config, for exmaple)
    if (store.formData[props.mapKey].bbox_to_fit?.length) {
        const origZoom = store.maps[props.mapKey].getZoom();
        store.maps[props.mapKey].fitMapView(store.formData[props.mapKey].bbox_to_fit);
        //  Zoom out one level to make sure features really are in view (not right on the edge)
        store.maps[props.mapKey].adjustZoom(-0.25);

        // if Zoom level is closer than default view was, zoom out to default
        // zoom level
        if (store.maps[props.mapKey].getZoom() > origZoom) {
            store.maps[props.mapKey].setZoom(origZoom);
        }

        // If the current map type has a maximum zoom level, ensure new zoom
        // level is less than that. Only when using bbox_to_fit -- user is
        // allowed to demand a more zoomed in map without it being reset when
        // the map is next loaded
        let maxInitialZoom;
        if (props.mapKey in maxInitialZoomLevel) {
            maxInitialZoom = maxInitialZoomLevel[props.mapKey];

            if (store.maps[props.mapKey].getZoom() > maxInitialZoom) {
                store.maps[props.mapKey].setZoom(maxInitialZoom);
            }
        }

        // Set bbox to null as intial view has been set, to avoid zooming to
        // default view on reload after user adjustment
        store.formData[props.mapKey].bbox_to_fit = null;

        // Store new map view given by bbox_to_fit to map
        storeMapView();
    }

    watch(
        [() => store.formData[props.mapKey].amenities_layers],
        () => {
            setAmenitiesOverlayLayers();
            setAmenitiesLegendItems();
        },
        {
            immediate: true,
            deep: true,
        }
    );

    // Create text annotions
    if (store.formData[props.mapKey]?.text_annotations?.features.length) {
        store.maps[props.mapKey].setTextAnnotations(store.formData[props.mapKey].text_annotations.features);
    }

    // Listen to changes to text annotations vector source and update database
    // Naive approach recreates all annotations for any change. If performance
    // issues arise, listen to the events when adding, deleting or modifying
    // individual features, and make the small changes required by each event.
    store.maps[props.mapKey].textAnnotationsSource.on("change", storeUpdatedTextAnnotations);

    // Create shape annotations
    if (store.formData[props.mapKey]?.shape_annotations?.features.length) {
        store.maps[props.mapKey].setShapeAnnotations(store.formData[props.mapKey].shape_annotations);
    }

    // Listen to changes to shape annotations vector source and update database
    store.maps[props.mapKey].shapeAnnotationsSource.on("change", storeUpdatedShapeAnnotations);

    store.maps[props.mapKey].isochroneModifyInteraction.on("modifyend", storeModifiedIsochronePolygons);

    // Listen for isochrone vertex deletion event and store updated polgon vertices
    store.maps[props.mapKey].getMap().on("isochroneVertexDeleted", storeModifiedIsochronePolygons);

    // Buffer zones
    if (!store.formData[props.mapKey].site_boundary_buffer_zones) {
        // Create default site boundary buffer zone config if it doesn't yet exist
        store.formData[props.mapKey].site_boundary_buffer_zones = {
            show_buffer_zones: false,
        };
    }

    unwatchSiteBoundaryBufferZones = watchDebounced(
        () => store.formData[props.mapKey].site_boundary_buffer_zones,
        (newBufferZonesConfig) => {
            if (!newBufferZonesConfig?.show_buffer_zones || !newBufferZonesConfig?.buffer_zones?.length) {
                store.maps[props.mapKey].clearSiteBoundaryBufferZones();
                store.maps[props.mapKey].toggleOverlayLayer("siteBoundaryBufferZones", false);
                store.maps[props.mapKey].legend_config.buffer_zones = [];
                return;
            }

            const geoJson = getBufferZonesGeoJSON(
                JSON.parse(store.formData.redline_polygon_geojson),
                newBufferZonesConfig
            );
            const stylesMap = getBufferZoneStyles(newBufferZonesConfig);
            const legendItems = getBufferZoneLegendItems(newBufferZonesConfig);

            store.maps[props.mapKey].setSiteBoundaryBufferZones(geoJson);
            store.maps[props.mapKey].setSiteBoundaryBufferZoneStyles(stylesMap);
            store.maps[props.mapKey].toggleOverlayLayer("siteBoundaryBufferZones", true);
            store.maps[props.mapKey].legend_config.buffer_zones = legendItems;
        },
        {
            deep: true,
            immediate: true,
            debounce: 500,
        }
    );

    // If props.markReadyForExportMilliseconds is set, wait for that many
    // milliseconds then set mapsReadyForExport to true
    if (props.markReadyForExportMilliseconds !== undefined) {
        mapReadyForExportTimeout = setTimeout(() => {
            store.maps[props.mapKey].getMap().once("rendercomplete", () => {
                store.mapsReadyForExport[props.mapKey] = true;
            });
            store.maps[props.mapKey].getMap().renderSync();
        }, props.markReadyForExportMilliseconds);
    }
});

function storeMapView() {
    const center = store.maps[props.mapKey].getCenter();
    store.formData[props.mapKey].center_longitude = center[0];
    store.formData[props.mapKey].center_latitude = center[1];
    store.formData[props.mapKey].zoom = store.maps[props.mapKey].getZoom();
}

function updateMapLegend() {
    const legendItems = [];

    // For each category item in the legend config master list, get all items
    // for legend from that category
    store.maps[props.mapKey].legend_config.masterList.forEach((item) => {
        legendItems.push(...store.maps[props.mapKey].legend_config[item]);
    });

    store.maps[props.mapKey].setLegendItems(legendItems);
    store.maps[props.mapKey].setLegendTitle(store.formData[props.mapKey].styles.legend_title);
}

function updateIsochroneStyle() {
    const isoStyles = [];

    for (let idx = 0; idx < 12; idx++) {
        isoStyles.push(getIsochroneStyle(idx));
    }

    store.maps[props.mapKey].setIsochroneStyles(isoStyles);
}

function createOLStyleFromConfig(style) {
    // Calcuate fill colour and opacity
    const fillColor = style.fill_color.substring(0, 7);
    const fillOpacity = style.show_fill ? parseInt(style.fill_color.substring(7, 9), 16) / 255 : 0.0;

    const fillColorValue = [...convert.hex.rgb(fillColor), fillOpacity];

    // Outline style and opacity
    const outlineColor = style.outline_color.substring(0, 7);
    const outlineOpacitySubstring = style.outline_color.length == 9 ? style.outline_color.substring(7, 9) : "FF";
    let outlineOpacity = parseInt(outlineOpacitySubstring, 16) / 255;
    if (style.outline_style === "none" || style.show_outline === false) {
        outlineOpacity = 0.0;
    }
    const outlineColorValue = [...convert.hex.rgb(outlineColor), outlineOpacity];

    // Set line style, using appropriate values for isochrones and legend /display
    const lineCap = style.outline_style === "dotted" ? "round" : "butt";
    const lineShape = style.outline_style === "dotted" ? "rounded" : "square";

    return new Style({
        fill: new Fill({
            color: fillColorValue,
        }),
        stroke: new Stroke({
            color: outlineColorValue,
            lineCap: lineCap,
            lineDash: getLineDashArray(style.outline_style, style.outline_width, lineShape),
            width: style.outline_width,
        }),
    });
}

function getIsochroneStyle(index) {
    const isochroneStyleConfig = store.formData[props.mapKey].isochrone_styles[index] ?? isochroneDefaultStyles[index];

    return createOLStyleFromConfig(isochroneStyleConfig);
}

function setAmenitiesLegendItems() {
    store.maps[props.mapKey].legend_config.amenities = Object.keys(props.amenities).flatMap((key) =>
        store.formData[props.mapKey].amenities_layers?.[key]
            ? props.amenities[key].legend.map((item) => legendItemFromConfig({ item: item, mapKey: props.mapKey }))
            : []
    );
}

function setAmenitiesOverlayLayers() {
    // for each layer, toggle based on the value of the form input
    if (store.formData[props.mapKey].amenities_layers) {
        for (const [key, value] of Object.entries(store.formData[props.mapKey].amenities_layers)) {
            store.maps[props.mapKey].toggleOverlayLayer(key, value);
        }
    }
}

function storeUpdatedTextAnnotations() {
    const sourceFeatures = store.maps[props.mapKey].textAnnotationsSource.getFeatures();

    const format = new GeoJSON({
        featureProjection: "ESPG:3857",
        dataProjection: "ESPG:3857",
    });
    const geoJSONFeatureCollection = format.writeFeaturesObject(sourceFeatures);

    store.formData[props.mapKey].text_annotations = geoJSONFeatureCollection;
}

function storeUpdatedShapeAnnotations() {
    const sourceFeatures = store.maps[props.mapKey].shapeAnnotationsSource.getFeatures();

    const format = new GeoJSON({
        featureProjection: "ESPG:3857",
        dataProjection: "ESPG:3857",
    });
    const geoJSONFeatureCollection = format.writeFeaturesObject(sourceFeatures);

    store.formData[props.mapKey].shape_annotations = geoJSONFeatureCollection;
}

function storeModifiedIsochronePolygons() {
    const sourceFeatures = store.maps[props.mapKey].isochroneSource.getFeatures();

    const format = new GeoJSON({
        featureProjection: "ESPG:3857",
        dataProjection: "ESPG:3857",
    });
    const geoJSONFeatureCollection = format.writeFeaturesObject(sourceFeatures);

    // Add bbox to geojson features
    const featureCollectionBbox = bbox(geoJSONFeatureCollection);
    geoJSONFeatureCollection.bbox = featureCollectionBbox;

    store.formData[props.mapKey].isochrone_geojson = geoJSONFeatureCollection;
}

function getBufferZonesGeoJSON(inputFeatureCollection, bufferZonesConfig) {
    // For each buffer zone radius, create a feature collection defining (multi)
    // polygons for buffer zones at that radius around all features in the
    // inputFeatureCollection
    const bufferZoneFeatureCollectionsByRadius = bufferZonesConfig.buffer_zones.map((bz) => {
        const radiusBufferZone = buffer(inputFeatureCollection, bz.radius / 1000, { units: "kilometers" });

        // Add a property defining radius to each feature of the feature collection
        radiusBufferZone.features.forEach((feat) => {
            if (feat.properties) {
                feat.properties.radius = bz.radius;
            } else {
                feat.properties = { radius: bz.radius };
            }
        });
        return radiusBufferZone;
    });

    // Flatten all the radius-based feature collections into a single feature
    // collection. Sort from largest to smallest radius, so that inner buffer
    // zones are drawn on top of outer buffer zones.
    const combinedFeatureCollection = featureCollection(
        bufferZoneFeatureCollectionsByRadius
            .flatMap((fc) => fc.features)
            .sort((a, b) => b.properties.radius - a.properties.radius)
    );
    return combinedFeatureCollection;
}

function getBufferZoneStyles(bufferZonesConfig) {
    // Create a mapping from radius of a buffer zone feature to its style
    const radiusStyleMap = Object.fromEntries(
        bufferZonesConfig.buffer_zones.map((bz) => {
            const radius = bz.radius;
            const style = createOLStyleFromConfig(bz.style);
            return [radius, style];
        })
    );
    return radiusStyleMap;
}

function getBufferZoneLegendItems(bufferZonesConfig) {
    return bufferZonesConfig.buffer_zones.map(
        (bz) =>
            new SFLegendItem({
                title: `${bz.radius} m`,
                icon: new SFLegendIconRectangle({
                    fillColor: bz.style.fill_color.substring(0, 7),
                    fillOpacity: bz.style.show_fill ? parseInt(bz.style.fill_color.substring(7, 9), 16) / 255 : 0.0,
                    outlineColor: bz.style.outline_color.substring(0, 7),
                    outlineStyle: bz.style.show_outline ? bz.style.outline_style : "none",
                    outlineWidth: 2,
                }),
            })
    );
}

onUnmounted(() => {
    // Cancel setTimeout if it is still pending
    if (mapReadyForExportTimeout) {
        clearTimeout(mapReadyForExportTimeout);
    }

    // Set mapsReadyForExport to false
    store.mapsReadyForExport[props.mapKey] = false;

    unwatchLegendConfig();
    unwatchSiteMarker();
    unwatchBaseLayers();
    unwatchColorFilters();
    unwatchNorthArrow();
    unwatchSiteBoundaryBufferZones();

    if (unwatchIsochroneGeoJSON) {
        unwatchIsochroneGeoJSON();
    }

    if (unwatchIsochroneStyle) {
        unwatchIsochroneStyle();
    }

    // Remove listener for annotation changes
    store.maps[props.mapKey].textAnnotationsSource.un("change", storeUpdatedTextAnnotations);
    store.maps[props.mapKey].shapeAnnotationsSource.un("change", storeUpdatedShapeAnnotations);

    // Remove listener for isochrone changes
    store.maps[props.mapKey].isochroneModifyInteraction.un("modifyend", storeModifiedIsochronePolygons);
    store.maps[props.mapKey].getMap().un("isochroneVertexDeleted", storeModifiedIsochronePolygons);

    // Delete map. Whenn map is next loaded, existing object may be used before
    // the new map is set up, so nullify to avoid data races.
    store.maps[props.mapKey] = null;
});
</script>

<style scoped>
:root {
    --sf-map-aspect-ratio: v-bind(store.formData[props.mapKey].styles.aspect_ratio);
}

:deep(.ol-rotate) {
    width: 0;
    height: 0;
    overflow: visible;
    inset: 0;
    margin: 0;
    transition: none;
    background-color: transparent;
    pointer-events: none;
}

:deep(.ol-control button) {
    width: 0;
    height: 0;
    overflow: visible;
    margin: 0;
    background-color: transparent;
    pointer-events: none;
}

:deep(button.ol-rotate-reset:hover) {
    outline: none;
    cursor: default;
    pointer-events: none;
}

:deep(.ol-control button:focus) {
    outline: none;
    pointer-events: none;
}

:deep(.ol-compass img) {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    pointer-events: none;
}
</style>
