<template>
    <div class="flex justify-end text-sf-orange hover:text-gray-700 text-xs items-center">
        <PlusIcon class="size-4" />
        <button type="button" @click="setRedlinePolygon">Use as site boundary</button>
    </div>
    <h2 class="text-base font-bold mb-1">{{ props.overlay_information?.properties?.apn || "Unknown" }}</h2>
    <MapOverlaySourceLink
        href="https://geo.sandag.org/portal/apps/mapviewer/index.html?layers=032a5dcf654c4ccbb18711ad8a0ee754"
    >
        SanGIS Viewer (APN Parcels)
    </MapOverlaySourceLink>
    <MapOverlaySourceLink href="https://geo.sandag.org/server/rest/directories/downloads/Parcels.pdf">
        SanGIS Data Definition (APN Parcels)
    </MapOverlaySourceLink>
</template>

<script setup>
import MapOverlaySourceLink from "@/components/MapOverlays/MapOverlaySourceLink.vue";
import { PlusIcon } from "@heroicons/vue/24/outline";

const props = defineProps({
    overlay_information: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(["setRedlinePolygon"]);

const setRedlinePolygon = () => {
    emit("setRedlinePolygon", props.overlay_information?.properties?.geometry);
};
</script>
