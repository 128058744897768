<template>
    <h2 class="text-base font-bold mb-1">{{ props.overlay_information?.properties?.road_name || "Unknown" }}</h2>
    <table class="w-full border-collapse mb-2">
        <tr v-if="props.overlay_information?.properties?.bike_class">
            <MapOverlayTableCell>Class</MapOverlayTableCell>
            <MapOverlayTableCell>
                {{ props.overlay_information.properties?.bike_class }} -
                {{ props.overlay_information.properties?.class_name }}
            </MapOverlayTableCell>
        </tr>
        <tr v-if="props.overlay_information.properties?.jurisdiction">
            <MapOverlayTableCell>Jurisdiction</MapOverlayTableCell>
            <MapOverlayTableCell>
                {{ props.overlay_information.properties?.jurisdiction }}
            </MapOverlayTableCell>
        </tr>
    </table>
    <MapOverlaySourceLink
        href="https://geo.sandag.org/portal/home/item.html?id=3add7066b8654253af0745dc72bb7b16&sublayer=0#"
    >
        SanGIS Viewer (Bikeways)
    </MapOverlaySourceLink>
    <MapOverlaySourceLink href="https://geo.sandag.org/server/rest/directories/downloads/Bikeways.pdf">
        SanGIS Data Definition (Bikeways)
    </MapOverlaySourceLink>
</template>

<script setup>
import MapOverlayTableCell from "@/components/MapOverlays/MapOverlayTableCell.vue";
import MapOverlaySourceLink from "@/components/MapOverlays/MapOverlaySourceLink.vue";

const props = defineProps({
    overlay_information: {
        type: Object,
        required: true,
    },
});
</script>

<style scoped>
tr:nth-child(odd) {
    background-color: #f2f2f2; /* Light gray for odd rows */
}
tr:nth-child(even) {
    background-color: #ffffff; /* White for even rows */
}
</style>
