/// <reference lib="dom" />

import { defaults } from 'ol/interaction/defaults'
import View from 'ol/View'
import Map from 'ol/Map'
import Control from 'ol/control/Control'
import Attribution from 'ol/control/Attribution'
import ContextMenu from 'ol-contextmenu'
import { Extent, containsCoordinate } from 'ol/extent'
import { Raster, Vector as VectorSource, Google, TileWMS, VectorTile as VectorTileSource } from 'ol/source'
import { Tile as TileLayer, VectorImage as VectorLayer, WebGLTile as WebGLTileLayer, VectorTile as VectorTileLayer } from 'ol/layer'
import {tile as tileStrategy} from 'ol/loadingstrategy';
import {createXYZ} from 'ol/tilegrid';
import {applyStyle} from 'ol-mapbox-style';
import BaseLayer from 'ol/layer/Base.js'
import ImageLayer from 'ol/layer/Image'
import Collection from 'ol/Collection.js'
import { Style, Fill, Stroke } from 'ol/style'
import RegularShape from 'ol/style/RegularShape'
import CircleStyle from 'ol/style/Circle'
import XYZ from 'ol/source/XYZ'
import OSM from 'ol/source/OSM.js'
import { Projection, transform, transformExtent, useGeographic } from 'ol/proj'
import GeoJSON, { GeoJSONFeatureCollection } from 'ol/format/GeoJSON'
import Modify from 'ol/interaction/Modify'
import Select from 'ol/interaction/Select'
import Translate from 'ol/interaction/Translate'
import Rotate from 'ol/control/Rotate.js'
import ScaleLine, { Units } from 'ol/control/ScaleLine.js'
import { toBlob, toPng } from 'html-to-image'
import { bbox } from 'ol/loadingstrategy'
import SchemeflowLegend from '@/maps/sflegend'
import { iconFromConfig } from '@/maps/configIcons'
import Draw from 'ol/interaction/Draw'
import { noModifierKeys, primaryAction, doubleClick, pointerMove } from "ol/events/condition";
import Overlay from "ol/Overlay";
import { nanoid } from "nanoid";
import EA_rivers_SLD_text from '@/assets/map_styles/Statutory_Main_River_Map.sld?raw'
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Event from 'ol/events/Event';
import { ref, Ref } from 'vue';

// Import map styles
import { redlineStyle, drawStyleFunction, OS_LIGHT_MAP_TILES_URL, OS_MAP_ROAD_TILES_URL, OS_OUTDOOR_MAP_TILES_URL, SATELLITE_MAP_TILES_URL, accidentMarker, ESRI_VECTOR_TILES_URL, northArrowColor, SANGIS_ROADS_ALL_LAYER_URL, SANGIS_BIKEWAYS_LAYER_URL, SANGIS_APN_PARCELS_LAYER_URL } from '@/maps/constants'
import LayerGroup from 'ol/layer/Group'
import { Feature } from 'ol'
import { Geometry, Point, GeometryCollection, LineString } from 'ol/geom'
import { difference } from "@turf/difference";
import { featureCollection } from "@turf/helpers";
import { polygonToLine } from "@turf/polygon-to-line";

import { useEventBus } from '@vueuse/core'
import Interaction from 'ol/interaction/Interaction'
import { highlightedPolygonStyle, polygonEditStyleFunction, overlaySelectLayers } from './constants'
import {
  TextAnnotationStyleEditChangeEvent,
  TextAnnotationStyle,
  textAnnotationDefaultStyle,
  getStyleAttributeForStyle,
  getStyleForShapeAnnotationFeature,
  shapeAnnotationDrawLineStyle,
  shapeAnnotationDrawPolygonStyle,
  shapeAnnotationArrowDefaultStyle,
  ShapeAnnotationStyleEditChangeEvent,
  ShapeAnnotationLineStyle,
  ShapeAnnotationPolygonStyle,
  shapeAnnotationSelectedFeatureStyle
} from "@/maps/annotationstyles.ts";
import MVT from 'ol/format/MVT'
import SchemeflowAdditionalDrawingLayer, { generateOpenlayersStylesFromAdditionalLayer } from './SchemeflowAdditionalDrawingLayers'
import { Type } from 'ol/geom/Geometry'
import { EsriJSON } from 'ol/format'

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
const MAPBOX_API_KEY = import.meta.env.VITE_MAPBOX_API_KEY;

// Create the operation function
const colorFilterOperation = (pixels: Array<Array<number>>, data: any) => {
  let pixel = pixels[0]
  if (data.grayscaleFilter) {
    const grayscalePixelValue = 0.2627 * pixels[0][0] + 0.678 * pixels[0][1] + 0.0593 * pixels[0][2]
    pixel[0] = grayscalePixelValue
    pixel[1] = grayscalePixelValue
    pixel[2] = grayscalePixelValue
  }

  // data.alpha is a value between 0 (transparent) and 255 (opaque)
  // If opacity is set and is less than 255
  if (data.alpha && data.alpha < 255) {
    // calculate RGB pixel value from alpha value (assuming white background)
    for (let i = 0; i < 3; i++) {
      pixel[i] = Math.round(pixel[i] * (data.alpha / 255) + 255 * (1 - data.alpha / 255))
    }
  }
  return pixel
}

const osmOverpassBboxLoader = (options: {
  extent: Extent;
  projection: Projection,
  osmQuery: Array<String>,
  vectorSource: VectorSource,
}) => {
  const [minlon, minlat, maxlon, maxlat] = transformExtent(options.extent, options.projection, 'EPSG:4326');
  const url = 'https://overpass-api.de/api/interpreter';

  // Build union queries by combining multiple elements of query array together.
  // Intersection queries can be constructed by giving multiple tags in a single element
  let queryStatements = '('
  options.osmQuery.forEach((queryElement) => {
    queryStatements += `nw${queryElement}(${minlat},${minlon},${maxlat},${maxlon});`
  })
  queryStatements += ');'

  const query = `
    [out:json];
    ${queryStatements}
    out center;
  `;

  fetch(url, {
    method: 'POST',
    body: new URLSearchParams({ data: query }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  .then(response => response.json())
  .then(data => {
    const features = data.elements.map(element => new Feature({
      geometry: new Point([
        element.type == "node" ? element.lon : element.center.lon,
        element.type == "node" ? element.lat : element.center.lat
      ])
    }));
    options.vectorSource.addFeatures(features);
    return features
  })
  .catch(error => console.error('Error fetching data from OpenStreetMap API:', error));
}

const osmOverpassHighwaysLoader = (options: {
  extent: Extent;
  projection: Projection,
  vectorSource: VectorSource,
}) => {
  const [minlon, minlat, maxlon, maxlat] = transformExtent(options.extent, options.projection, 'EPSG:4326');
  const url = 'https://overpass-api.de/api/interpreter';

  const query = `
    [out:json][timeout:25];
    (
      way["highway"]["highway"!~"footway|cycleway|path|service|footway|bridleway|steps|corridor|via_ferrata|elevator|ladder|platform"](${minlat},${minlon},${maxlat},${maxlon});
    );
    out geom qt;
  `;

  fetch(url, {
    method: 'POST',
    body: new URLSearchParams({ data: query }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  .then(response => response.json())
  .then(data => {
    const features = data.elements
      .filter(element => element.type === 'way' && element.geometry)
      .map(element => {
        const coordinates = element.geometry.map(coord =>
          transform([coord.lon, coord.lat], 'EPSG:4326', options.projection)
        );

        return new Feature({
          geometry: new LineString(coordinates),
          properties: {
            ...element.tags, // Spread all OSM tags into properties
            id: element.id,
            type: element.type
          }
        });
      });
    options.vectorSource.addFeatures(features);
    return features
  })
  .catch(error => console.error('Error fetching data from OpenStreetMap API:', error));
}

class GoogleLogoControl extends Control {
  constructor() {
    const element = document.createElement('img');
    element.style.pointerEvents = 'none';
    element.style.position = 'absolute';
    element.style.bottom = '5px';
    element.style.left = '5px';
    element.src =
      'https://developers.google.com/static/maps/documentation/images/google_on_white.png';
    super({
      element: element,
    });
  }
}

class GoogleWhiteLogoControl extends Control {
  constructor() {
    const element = document.createElement('img');
    element.style.pointerEvents = 'none';
    element.style.position = 'absolute';
    element.style.bottom = '5px';
    element.style.left = '5px';
    element.src = 'https://developers.google.com/static/maps/documentation/images/google_on_non_white.png';
    super({
      element: element,
    });
  }
}

class IsochroneVertexDeletedEvent extends Event {
  constructor() {
    super('isochroneVertexDeleted');
  }
}

class AdditionalLayerModifiedEvent extends Event {
  constructor() {
    super('additionalLayerModified');
  }
}

enum shapeAnnotationType {
  POLYGON = 'polygon',
  LINE = 'line',
  ARROW = 'arrow',
}

export default class SchemeflowMap {
  private map: Map
  private baseMapLayers: LayerGroup
  private overlayLayers: LayerGroup
  private isochroneSource: VectorSource
  public isochroneOriginSource: VectorSource
  public isochroneSelectInteraction: Select
  private isochroneSelectInteractionFeatures: Collection<Feature>
  public isochroneModifyInteraction: Modify
  public busStopsSource: VectorSource
  public busStopsLayer: VectorLayer<VectorSource>
  private googleSource: Google
  private googleSatelliteSource: Google
  private railStationsSource: VectorSource
  private supermarketsSource: VectorSource
  private primarySchoolsSource: VectorSource
  private secondarySchoolsSource: VectorSource
  private pharmaciesSource: VectorSource
  private postOfficesSource: VectorSource
  private ferryPortsSource: VectorSource
  private londonUndergroundStationsSource: VectorSource
  private londonDLRStationsSource: VectorSource
  private londonElizabethLineStationsSource: VectorSource
  private londonOvergroundStationsSource: VectorSource
  private nonLondonMetroStationsSource: VectorSource
  private osmBusStopsSource: VectorSource
  private osmRailStationsSource: VectorSource
  private osmMetroStationsSource: VectorSource
  private osmLightRailStationsSource: VectorSource
  private osmTramStopsSource: VectorSource
  private osmChargePointsSource: VectorSource
  private osmFerryPortsSource: VectorSource
  private osmRestaurantsSource: VectorSource
  private osmBarsAndCafesSource: VectorSource
  private osmHospitalsSource: VectorSource
  private osmHealthClinicsSource: VectorSource
  private osmKindergartenSource: VectorSource
  private osmSchoolsSource: VectorSource
  private osmCollegesSource: VectorSource
  private osmUniversitiesSource: VectorSource
  private osmSupermarketsSource: VectorSource
  private osmFireStationsSource: VectorSource
  private osmPlacesOfWorshipSource: VectorSource
  private osmCommunityCentresSource: VectorSource
  private sangisRoadsSource: VectorSource
  private sangisBikewaysSource: VectorSource
  private sangisAPNParcelsSource: VectorSource
  private roadAccidentsSource: VectorSource
  public roadAccidentCentreSource: VectorSource
  private osmRasterSource: Raster
  private osLightRasterSource: Raster
  private osRoadRasterSource: Raster
  private osOutdoorRasterSource: Raster
  private satelliteRasterSource: Raster
  public redlinePolygonSource: VectorSource
  private redlinePolygonLayer: VectorLayer<VectorSource>
  public siteLocationPointSource: VectorSource
  private siteLocationPointLayer: VectorLayer<VectorSource>
  private sfLegend: SchemeflowLegend
  private googleControl: GoogleLogoControl
  private googleWhiteControl: GoogleWhiteLogoControl
  private attribution: Attribution
  private mapKey: string | undefined
  private redlineDrawInteraction: Draw
  private additionalLayerDrawInteraction: Draw | undefined
  private modifyInteraction: Modify
  private moveMapInteractions: Collection<Interaction>
  private drawKeyEventAbortController: AbortController
  private textAnnotationEditEventAbortController: AbortController
  private contextmenu: ContextMenu
  public redlinePolygonEdit: boolean
  private siteLocationModify: Modify
  private textAnnotationDrawInteraction: Draw
  private annotationDrawKeyEventAbortController: AbortController
  public annotationDrawingActive: string | null
  private editingTextAnnotation: string         // id of annotation being edited, or null if not in edit mode
  public textAnnotationsSource: VectorSource    // Used only for syncing change to db, annotations are Overlays, not vector features
  private draggedTextAnnotation: Overlay
  private draggedTextAnnotationOffset: { x: number, y: number }
  private textAnnotationStyleAbortController: AbortController
  private shapeAnnotationDrawInteraction: Draw
  public shapeAnnotationsSource: VectorSource
  private shapeAnnotationStyleEditId: string | null
  private shapeAnnotationSelectInteraction: Select
  private shapeAnnotationTranslateInteraction: Translate
  private shapeAnnotationModifyInteraction: Modify
  private environmentAgencyReservoirFloodExtentsDryDayLayer: TileLayer<TileWMS>
  private environmentAgencyReservoirFloodExtentsWetDayLayer: TileLayer<TileWMS>
  private environmentAgencyFloodRiskZone23Layer: TileLayer<TileWMS>
  private environmentAgencyMainRiversLayer: TileLayer<TileWMS>
  private environmentAgencyRoFSW4BandLayer: TileLayer<TileWMS>
  private nationalCycleNetworkLayer: VectorTileLayer<VectorTileSource>
  private sangisRoadsLayer: VectorLayer<VectorSource>
  private sangisBikewaysLayer: VectorLayer<VectorSource>
  private sangisAPNParcelsLayer: VectorLayer<VectorSource>
  private overlaySelectInteraction: Select
  private overlayHoverSelectInteraction: Select
  private overlay: Overlay
  public overlayInformation: Ref<Record<string, any>>
  private additionalLayers: LayerGroup
  private northArrowControl: Rotate
  private siteBoundaryBufferZoneSource: VectorSource
  private siteBoundaryBufferZoneLayer: VectorLayer<VectorSource>

  constructor(options: { redlinePolygonSource: VectorSource; scaleBarUnits: Units | undefined, mapKey: string | undefined, }) {
    this.overlayInformation = ref({});
    this.drawKeyEventAbortController = new AbortController();
    this.textAnnotationEditEventAbortController = new AbortController();
    this.annotationDrawKeyEventAbortController = new AbortController();
    this.textAnnotationStyleAbortController = new AbortController();
    useGeographic()
    this.redlinePolygonSource = options.redlinePolygonSource
    this.isochroneSource = new VectorSource({ wrapX: false })
    this.mapKey = options.mapKey;
    this.additionalLayers = new LayerGroup();

    const geojsonFormat = new GeoJSON()

    this.siteLocationPointSource = new VectorSource({
      format: geojsonFormat
    })

    this.busStopsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/bus_stops?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.busStopsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading bus stops. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.railStationsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/rail_stations?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.railStationsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading rail stations. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmBusStopsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["highway"="bus_stop"]'],
          vectorSource: this.osmBusStopsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmRailStationsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          // Rail stations which are neither subway stations nor light rail stations
          osmQuery: ['["railway"="station"]["station"!="subway"]["station"!="light_rail"]'],
          vectorSource: this.osmRailStationsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmMetroStationsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["railway"="station"]["station"="subway"]'],
          vectorSource: this.osmMetroStationsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmLightRailStationsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["railway"="station"]["station"="light_rail"]'],
          vectorSource: this.osmLightRailStationsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmTramStopsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["railway"="tram_stop"]'],
          vectorSource: this.osmTramStopsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.supermarketsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/supermarkets?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.supermarketsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading supermarkets. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.pharmaciesSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="pharmacy"]'],
          vectorSource: this.pharmaciesSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.postOfficesSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/post_offices?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.postOfficesSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading post offices. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.primarySchoolsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/primary_schools?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.primarySchoolsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading primary schools. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.secondarySchoolsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/secondary_schools?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.secondarySchoolsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading secondary schools. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.ferryPortsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/ferry_ports?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.ferryPortsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading ferry ports. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.londonUndergroundStationsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/uk_metro_stations/london_underground?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.londonUndergroundStationsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading Underground Stations. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.londonDLRStationsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/uk_metro_stations/london_dlr?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.londonDLRStationsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading DLR Stations. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.londonElizabethLineStationsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/uk_metro_stations/london_elizabeth_line?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.londonElizabethLineStationsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading Elizabeth Line Stations. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.londonOvergroundStationsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/uk_metro_stations/london_overground?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.londonOvergroundStationsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading Overground Stations. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.nonLondonMetroStationsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        const [minlon, minlat, maxlon, maxlat] = extent
        const url = '/api/amenities/uk_metro_stations/non_london_metro?min_longitude=' + minlon + '&min_latitude=' + minlat + '&max_longitude=' + maxlon + '&max_latitude=' + maxlat

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            const features = geojsonFormat.readFeatures(data, {
              featureProjection: projection
            }) as Feature<Geometry>[]
            this.nonLondonMetroStationsSource.addFeatures(features)
          })
          .catch((error) => {
            console.error('An error occurred while loading the vector source:', error)
            this.createErrorEvent('Error loading Metro Stations. Please try again.')
          })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmChargePointsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="charging_station"]'],
          vectorSource: this.osmChargePointsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmFerryPortsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="ferry_terminal"]'],
          vectorSource: this.osmFerryPortsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmRestaurantsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="bar"]', '["amenity"="cafe"]'],
          vectorSource: this.osmRestaurantsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmBarsAndCafesSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="restaurant"]', '["amenity"="fast_food"]'],
          vectorSource: this.osmBarsAndCafesSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmHospitalsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="hospital"]'],
          vectorSource: this.osmHospitalsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmHealthClinicsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="clinic"]', '["amenity"="doctors"]'],
          vectorSource: this.osmHealthClinicsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmKindergartenSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="kindergarten"]'],
          vectorSource: this.osmKindergartenSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmSchoolsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="school"]'],
          vectorSource: this.osmSchoolsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmCollegesSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="college"]'],
          vectorSource: this.osmCollegesSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmUniversitiesSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="university"]'],
          vectorSource: this.osmUniversitiesSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmSupermarketsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["shop"="supermarket"]', '["shop"="convenience"]'],
          vectorSource: this.osmSupermarketsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmFireStationsSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="fire_station"]'],
          vectorSource: this.osmFireStationsSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmPlacesOfWorshipSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="place_of_worship"]'],
          vectorSource: this.osmPlacesOfWorshipSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.osmCommunityCentresSource = new VectorSource({
      format: geojsonFormat,
      loader: (extent, resolution, projection) => {
        osmOverpassBboxLoader({
          extent: extent,
          projection: projection,
          osmQuery: ['["amenity"="community_centre"]'],
          vectorSource: this.osmCommunityCentresSource,
        })
      },
      strategy: bbox,
      wrapX: false
    })

    this.roadAccidentsSource = new VectorSource({
      wrapX: false
    })

    // Road accident centre source is for user drawing interaction to set a
    // new point for road accident location analysis
    this.roadAccidentCentreSource = new VectorSource({
      wrapX: false
    })

    // The centre point can only be a single point, so when a point is added
    // to the source, delete all other points
    this.roadAccidentCentreSource.on('addfeature', (newFeature) => {
      this.roadAccidentCentreSource.forEachFeature((feat) => {
        if (feat != newFeature.feature) {
          this.roadAccidentCentreSource.removeFeature(feat)
        }
      })
    })

    this.isochroneOriginSource = new VectorSource({
      wrapX: false
    })

    this.textAnnotationsSource = new VectorSource({
      format: geojsonFormat,
      wrapX: false,
    });

    // when a new point is added to the isochrone source, all previous
    // points should be removed so only the latest one is displayed
    this.isochroneOriginSource.on('addfeature', (newFeature) => {
      this.isochroneOriginSource.forEachFeature((feat) => {
        if (feat != newFeature.feature) {
          this.isochroneOriginSource.removeFeature(feat)
        }
      })
    })

    this.shapeAnnotationsSource = new VectorSource({
      format: geojsonFormat,
      wrapX: false,
    });

    this.siteBoundaryBufferZoneSource = new VectorSource({ wrapX: false });

    this.osmRasterSource = new Raster({
      sources: [new OSM()],
      operation: colorFilterOperation
    })

    this.osLightRasterSource = new Raster({
      sources: [
        new XYZ({
          url: OS_LIGHT_MAP_TILES_URL,
          crossOrigin: 'anonymous'
        })
      ],
      operation: colorFilterOperation
    })

    this.osOutdoorRasterSource = new Raster({
      sources: [
        new XYZ({
          url: OS_OUTDOOR_MAP_TILES_URL,
          crossOrigin: 'anonymous'
        })
      ],
      operation: colorFilterOperation
    })

    this.osRoadRasterSource = new Raster({
      sources: [
        new XYZ({
          url: OS_MAP_ROAD_TILES_URL,
          crossOrigin: 'anonymous'
        })
      ],
      operation: colorFilterOperation
    })

    this.satelliteRasterSource = new Raster({
      sources: [
        new XYZ({
          url: SATELLITE_MAP_TILES_URL,
          crossOrigin: 'anonymous'
        })
      ],
      operation: colorFilterOperation
    })

    this.googleSource = new Google({
      key: GOOGLE_MAPS_API_KEY,
      scale: 'scaleFactor2x',
      highDpi: true,
      styles: [
        {
          featureType: "all",
          elementType: "geometry",
          stylers: [
            { gamma: 0.5 },
          ]
        }
      ],
    })

    this.googleSatelliteSource = new Google({
      key: GOOGLE_MAPS_API_KEY,
      mapType: 'satellite',
      scale: 'scaleFactor2x',
      highDpi: true,
      layerTypes: ['layerRoadmap'],
    })

    const satelliteVectorOverlayLayer = new VectorTileLayer({
      visible: false,
      properties: {
        name: 'SatelliteWithOverlays'
      },
      minZoom: 3,
      maxZoom: 18
    });
    applyStyle(satelliteVectorOverlayLayer, ESRI_VECTOR_TILES_URL);

    // Define layer group for base layer options
    this.baseMapLayers = new LayerGroup({
      layers: [
        // OSM
        new ImageLayer({
          visible: true, // Default = OSM
          properties: {
            name: 'OSM'
          },
          source: this.osmRasterSource,
          minZoom: 3,
          maxZoom: 20
        }),
        // OS Light
        new ImageLayer({
          visible: false,
          properties: {
            name: 'OSLight'
          },
          source: this.osLightRasterSource,
          minZoom: 7,
          maxZoom: 20
        }),
        // OS Outdoor
        new ImageLayer({
          visible: false,
          properties: {
            name: 'OSOutdoor'
          },
          source: this.osOutdoorRasterSource,
          minZoom: 7,
          maxZoom: 20
        }),
        // OS Road
        new ImageLayer({
          visible: false,
          properties: {
            name: 'OSRoad'
          },
          source: this.osRoadRasterSource,
          minZoom: 7,
          maxZoom: 20
        }),
        // Satellite raster
        new ImageLayer({
          visible: false,
          properties: {
            name: 'Satellite'
          },
          source: this.satelliteRasterSource,
          minZoom: 3,
          maxZoom: 18
        }),
        // Satellite raster
        // (duplicate to use with overlay tiles)
        new ImageLayer({
          visible: false,
          properties: {
            name: 'SatelliteWithOverlays'
          },
          source: this.satelliteRasterSource,
          minZoom: 3,
          maxZoom: 18
        }),
        satelliteVectorOverlayLayer,
        // Google maps
        new WebGLTileLayer({
          visible: false,
          properties: {
            name: 'Google'
          },
          source: this.googleSource,
          minZoom: 3,
          maxZoom: 22
        }),
        // Google satellite maps
        new WebGLTileLayer({
          visible: false,
          properties: {
            name: 'GoogleSatellite',
          },
          source: this.googleSatelliteSource,
          minZoom: 3,
          maxZoom: 22,
        }),
      ]
    })

    this.siteLocationPointLayer = new VectorLayer({
      visible: false,
      source: this.siteLocationPointSource,
      style: iconFromConfig({ item: "site_location", mapKey: this.mapKey }),
      properties: {
        name: 'site_marker'
      }
    })

    this.redlinePolygonLayer = new VectorLayer({
      minZoom: 12,
      visible: false,
      source: this.redlinePolygonSource,
      style: redlineStyle,
      properties: {
        name: 'redline'
      }
    })

    this.busStopsLayer = new VectorLayer({
      minZoom: 12,
      visible: false,
      source: this.busStopsSource,
      style: iconFromConfig({ item: "bus_stop", mapKey: this.mapKey }),
      properties: {
        name: 'bus_stops'
      }
    })


    this.environmentAgencyReservoirFloodExtentsDryDayLayer = new TileLayer({
      source: new TileWMS({
        url: 'https://environment.data.gov.uk/spatialdata/reservoir-flood-extents-dry-day/wms',
        params: {'LAYERS': 'Reservoir_Flood_Extents_Dry_Day', 'TILED': true},
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
      }),
      opacity: 0.5,
      visible: false,
      properties: {
        name: 'ea_reservoir_flood_extents_dry_day'
      }
    })

    this.environmentAgencyReservoirFloodExtentsWetDayLayer = new TileLayer({
      source: new TileWMS({
        url: 'https://environment.data.gov.uk/spatialdata/reservoir-flood-extents-wet-day/wms',
        params: {'LAYERS': 'Reservoir_Flood_Extents_Wet_Day', 'TILED': true},
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
      }),
      opacity: 0.5,
      visible: false,
      properties: {
        name: 'ea_reservoir_flood_extents_wet_day'
      }
    })

    this.environmentAgencyFloodRiskZone23Layer = new TileLayer({
      source: new TileWMS({
        url: 'https://environment.data.gov.uk/spatialdata/flood-map-for-planning-flood-zones/wms',
        params: {'LAYERS': 'Flood_Zones_2_3_Rivers_and_Sea', 'TILED': true},
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
      }),
      opacity: 0.5,
      visible: false,
      properties: {
        name: 'ea_flood_risk_zone_2'
      }
    })

    this.environmentAgencyMainRiversLayer = new TileLayer({
      source: new TileWMS({
        url: 'https://environment.data.gov.uk/spatialdata/statutory-main-river-map/wms',
        // Style is defined in the SLD file - overrides the default style provided by the WMS
        params: {'LAYERS': 'Statutory_Main_River_Map', 'TILED': true, 'SLD_BODY': EA_rivers_SLD_text},
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
      }),
      opacity: 1,
      visible: false,
      properties: {
        name: 'ea_main_rivers'
      }
    })

    this.environmentAgencyRoFSW4BandLayer = new TileLayer({
      source: new TileWMS({
        url: 'https://environment.data.gov.uk/spatialdata/nafra2-risk-of-flooding-from-surface-water/wms',
        params: {'LAYERS': 'rofsw_4band', 'TILED': true},
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
      }),
      opacity: 0.7,
      visible: false,
      properties: {
        name: 'ea_surface_water_rofsw_4band'
      }
    })

    this.nationalCycleNetworkLayer = new VectorTileLayer({
      source: new VectorTileSource({
        url: 'https://{a-d}.tiles.mapbox.com/v4/jonnyschemeflow.0co6f6wj/' +
          '{z}/{x}/{y}.vector.pbf?access_token=' +
          MAPBOX_API_KEY,
        format: new MVT(),
      }),
      style: new Style({
        stroke: new Stroke({
          color: '#253773',
          width: 2
        })
      }),
      visible: false,
      properties: {
        name: 'national_cycle_network'
      }
    })

    this.sangisRoadsSource = new VectorSource({
      format: new EsriJSON(),
      url: function (extent, resolution, projection) {
        // ArcGIS Server only wants the numeric portion of the projection ID.
        const srid = projection
          .getCode()
          .split(/:(?=\d+$)/)
          .pop();

        const url =
          SANGIS_ROADS_ALL_LAYER_URL +
          '0' + // layer id
          '/query/?f=json&' +
          'returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=' +
          encodeURIComponent(
            '{"xmin":' +
              extent[0] +
              ',"ymin":' +
              extent[1] +
              ',"xmax":' +
              extent[2] +
              ',"ymax":' +
              extent[3] +
              ',"spatialReference":{"wkid":' +
              srid +
              '}}',
          ) +
          '&geometryType=esriGeometryEnvelope&inSR=' +
          srid +
          '&outFields=*' +
          '&outSR=' +
          srid;

        return url;
      },
      strategy: tileStrategy(
        createXYZ({
          tileSize: 512,
        }),
      ),
    });

    this.sangisAPNParcelsSource = new VectorSource({
      format: new EsriJSON(),
      url: function (extent, resolution, projection) {
        // ArcGIS Server only wants the numeric portion of the projection ID.
        const srid = projection
          .getCode()
          .split(/:(?=\d+$)/)
          .pop();

        const url =
          SANGIS_APN_PARCELS_LAYER_URL +
          '0' + // layer id
          '/query/?f=json&' +
          'returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=' +
          encodeURIComponent(
            '{"xmin":' +
              extent[0] +
              ',"ymin":' +
              extent[1] +
              ',"xmax":' +
              extent[2] +
              ',"ymax":' +
              extent[3] +
              ',"spatialReference":{"wkid":' +
              srid +
              '}}',
          ) +
          '&geometryType=esriGeometryEnvelope&inSR=' +
          srid +
          '&outFields=*' +
          '&outSR=' +
          srid;

        return url;
      },
      strategy: tileStrategy(
        createXYZ({
          tileSize: 512,
        }),
      ),
      attributions: 'Parcels: San Diego Association of Governments (SANDAG)'
    });

    this.sangisBikewaysSource = new VectorSource({
      format: new EsriJSON(),
      url: function (extent, resolution, projection) {
        // ArcGIS Server only wants the numeric portion of the projection ID.
        const srid = projection
          .getCode()
          .split(/:(?=\d+$)/)
          .pop();

        const url =
          SANGIS_BIKEWAYS_LAYER_URL +
          '0' + // layer id
          '/query/?f=json&' +
          'returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=' +
          encodeURIComponent(
            '{"xmin":' +
              extent[0] +
              ',"ymin":' +
              extent[1] +
              ',"xmax":' +
              extent[2] +
              ',"ymax":' +
              extent[3] +
              ',"spatialReference":{"wkid":' +
              srid +
              '}}',
          ) +
          '&geometryType=esriGeometryEnvelope&inSR=' +
          srid +
          '&outFields=*' +
          '&outSR=' +
          srid;

        return url;
      },
      strategy: tileStrategy(
        createXYZ({
          tileSize: 512,
        }),
      ),
    });

    this.sangisRoadsLayer = new VectorLayer({
      source: this.sangisRoadsSource,
      visible: false,
      style: new Style({
          stroke: new Stroke({
            color: 'rgba(0,0,0,0.4)',
            width: 4
          })
      }),
      properties: {
        name: 'sangis_roads'
      },
      minZoom: 14,
      maxZoom: 20
    })

    this.sangisBikewaysLayer = new VectorLayer({
      source: this.sangisBikewaysSource,
      visible: false,
      style: new Style({
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.4)',
            width: 4
          })
      }),
      properties: {
        name: 'sangis_bikeways'
      },
      minZoom: 14,
      maxZoom: 20
    })

    this.sangisAPNParcelsLayer = new VectorLayer({
      source: this.sangisAPNParcelsSource,
      visible: false,
      style: new Style({
          stroke: new Stroke({
            color: 'rgba(128, 128, 128, 1)',
            width: 2
          })
      }),
      properties: {
        name: 'sangis_apn_parcels'
      },
      minZoom: 14,
    })

    this.overlaySelectInteraction = new Select({
      layers: (layer => overlaySelectLayers.includes(layer.get('name'))),
      style: new Style({
          stroke: new Stroke({
            color: '#FF5500',
            width: 4
          })
      }),
      hitTolerance: 5,
    });

    this.overlayHoverSelectInteraction = new Select({
      layers: (layer => overlaySelectLayers.includes(layer.get('name'))),
      condition: pointerMove,
      style: new Style({
          stroke: new Stroke({
            color: '#FF5500',
            width: 4
          })
      }),
      hitTolerance: 5,
    });

    // Handle overlay select interaction - show popup
    this.overlaySelectInteraction.on('select', (e) => {
      // If no features are selected, hide the overlay
      if (!e.selected.length) {
        this.overlay.setPosition(undefined);
        this.overlayInformation.value = {
          properties: {},
          latitude: undefined,
          longitude: undefined
        };
        return;
      }

      // Get the coordinate of the click
      const coord = e.mapBrowserEvent.coordinate;

      // Set the overlay content to the properties of the selected feature
      this.overlayInformation.value = {
        properties: e.selected[0].getProperties(),
        latitude: coord[1],
        longitude: coord[0]
      };

      // Set the position of the overlay to the coordinate of the click
      this.overlay.setPosition(coord);
    });

    // Create overlay
    this.overlay = new Overlay({
      positioning: 'top-center',
      autoPan: {
        animation: {
          duration: 250
        },
        margin: 150,
      },
    });

    this.siteBoundaryBufferZoneLayer = new VectorLayer({
      visible: false,
      source: this.siteBoundaryBufferZoneSource,
      properties: {
        name: 'siteBoundaryBufferZones',
      },
    })

    this.overlayLayers = new LayerGroup({
      layers: [
        this.environmentAgencyReservoirFloodExtentsDryDayLayer,
        this.environmentAgencyReservoirFloodExtentsWetDayLayer,
        this.environmentAgencyFloodRiskZone23Layer,
        this.environmentAgencyRoFSW4BandLayer,
        this.environmentAgencyMainRiversLayer,

        // Buffer Zones
        this.siteBoundaryBufferZoneLayer,

        // Isochrones
        new VectorLayer({
          visible: false,
          source: this.isochroneSource,
          properties: {
            name: 'isochrones'
          }
        }),

        // National Cycle Network
        this.nationalCycleNetworkLayer,

        // SANGIS Bikeways
        this.sangisBikewaysLayer,

        // SANGIS Roads
        this.sangisRoadsLayer,

        // SANGIS APN Parcels
        this.sangisAPNParcelsLayer,

        // Red line
        this.redlinePolygonLayer,

        this.busStopsLayer,

        new VectorLayer({
          minZoom: 9,
          visible: false,
          source: this.railStationsSource,
          style: iconFromConfig({ item: "rail_station", mapKey: this.mapKey }),
          properties: {
            name: 'rail_stations'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.londonUndergroundStationsSource,
          style: iconFromConfig({ item: "underground_station", mapKey: this.mapKey }),
          properties: {
            name: 'london_underground_stations'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.londonDLRStationsSource,
          style: iconFromConfig({ item: "dlr_station", mapKey: this.mapKey }),
          properties: {
            name: 'london_dlr_stations'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.londonElizabethLineStationsSource,
          style: iconFromConfig({ item: "elizabeth_line_station", mapKey: this.mapKey }),
          properties: {
            name: 'london_elizabeth_line_stations'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.londonOvergroundStationsSource,
          style: iconFromConfig({ item: "overground_station", mapKey: this.mapKey }),
          properties: {
            name: 'london_overground_stations'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.nonLondonMetroStationsSource,
          style: iconFromConfig({ item: "metro_station", mapKey: this.mapKey }),
          properties: {
            name: 'non_london_metro_stations'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmBusStopsSource,
          style: iconFromConfig({ item: "bus_stop", mapKey: this.mapKey }),
          properties: {
            name: 'osm_bus_stops'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmRailStationsSource,
          style: iconFromConfig({ item: "rail_station", mapKey: this.mapKey }),
          properties: {
            name: 'osm_rail_stations'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmMetroStationsSource,
          style: iconFromConfig({ item: "metro_station", mapKey: this.mapKey }),
          properties: {
            name: 'osm_metro_stations'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmLightRailStationsSource,
          style: iconFromConfig({ item: "light_rail_station", mapKey: this.mapKey }),
          properties: {
            name: 'osm_light_rail_stations'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmTramStopsSource,
          style: iconFromConfig({ item: "tram_stop", mapKey: this.mapKey }),
          properties: {
            name: 'osm_tram_stops'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.supermarketsSource,
          style: iconFromConfig({ item: "supermarket", mapKey: this.mapKey }),
          properties: {
            name: 'supermarkets'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.pharmaciesSource,
          style: iconFromConfig({ item: "pharmacy", mapKey: this.mapKey }),
          properties: {
            name: 'pharmacies'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.postOfficesSource,
          style: iconFromConfig({ item: "post_office", mapKey: this.mapKey }),
          properties: {
            name: 'post_offices'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.primarySchoolsSource,
          style: iconFromConfig({ item: "primary_school", mapKey: this.mapKey }),
          properties: {
            name: 'primary_schools'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.secondarySchoolsSource,
          style: iconFromConfig({ item: "secondary_school", mapKey: this.mapKey }),
          properties: {
            name: 'secondary_schools'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.ferryPortsSource,
          style: iconFromConfig({ item: "ferry_port", mapKey: this.mapKey }),
          properties: {
            name: 'ferry_ports'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmChargePointsSource,
          style: iconFromConfig({ item: "charge_point", mapKey: this.mapKey }),
          properties: {
            name: 'osm_charge_points'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmFerryPortsSource,
          style: iconFromConfig({ item: "ferry_port", mapKey: this.mapKey }),
          properties: {
            name: 'osm_ferry_ports'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmRestaurantsSource,
          style: iconFromConfig({ item: "restaurant", mapKey: this.mapKey }),
          properties: {
            name: 'osm_restaurants'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmBarsAndCafesSource,
          style: iconFromConfig({ item: "cafe", mapKey: this.mapKey }),
          properties: {
            name: 'osm_bars_cafes'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmHospitalsSource,
          style: iconFromConfig({ item: "hospital", mapKey: this.mapKey }),
          properties: {
            name: 'osm_hospitals'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmHealthClinicsSource,
          style: iconFromConfig({ item: "clinic", mapKey: this.mapKey }),
          properties: {
            name: 'osm_clinics'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmKindergartenSource,
          style: iconFromConfig({ item: "kindergarten", mapKey: this.mapKey }),
          properties: {
            name: 'osm_kindergarten'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmSchoolsSource,
          style: iconFromConfig({ item: "school", mapKey: this.mapKey }),
          properties: {
            name: 'osm_schools'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmCollegesSource,
          style: iconFromConfig({ item: "college", mapKey: this.mapKey }),
          properties: {
            name: 'osm_colleges'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmUniversitiesSource,
          style: iconFromConfig({ item: "university", mapKey: this.mapKey }),
          properties: {
            name: 'osm_universities'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmSupermarketsSource,
          style: iconFromConfig({ item: "shop", mapKey: this.mapKey }),
          properties: {
            name: 'osm_supermarkets'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmFireStationsSource,
          style: iconFromConfig({ item: "fire_station", mapKey: this.mapKey }),
          properties: {
            name: 'osm_fire_stations'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmPlacesOfWorshipSource,
          style: iconFromConfig({ item: "place_of_worship", mapKey: this.mapKey }),
          properties: {
            name: 'osm_places_of_worship'
          }
        }),

        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.osmCommunityCentresSource,
          style: iconFromConfig({ item: "community_centre", mapKey: this.mapKey }),
          properties: {
            name: 'osm_community_centres'
          }
        }),

        // road accident location markers
        new VectorLayer({
          minZoom: 12,
          visible: false,
          source: this.roadAccidentsSource,
          style: function (feature) {
            if (feature.get('visible')) {
              return accidentMarker[feature.get('severity')]
            } else {
              return null
            }
          },
          properties: {
            name: 'road_accidents'
          }
        }),

        // road accident centre point location marker
        new VectorLayer({
          visible: false,
          source: this.roadAccidentCentreSource,
          // Style: red cross-hairs
          style: new Style({
            image: new RegularShape({
              stroke: new Stroke({ color: 'red', width: 2 }),
              points: 4,
              radius: 10,
              radius2: 0,
              angle: 0
            })
          }),
          properties: {
            name: 'collision_centre'
          }
        }),

        // isochrone origin marker
        new VectorLayer({
          visible: false,
          source: this.isochroneOriginSource,
          // Style: red crosshairs
          style: new Style({
            image: new RegularShape({
              stroke: new Stroke({ color: 'red', width: 2 }),
              points: 4,
              radius: 10,
              radius2: 0,
              angle: 0
            })
          }),
          properties: {
            name: 'isochrone_origin'
          }
        }),

        // Shape annotations
        new VectorLayer({
          visible: true,
          source: this.shapeAnnotationsSource,
          style: (feature) => getStyleForShapeAnnotationFeature(feature, this.map),
          properties: {
            name: 'shape_annotations',
          }
        }),

       // Site location (point) - on top!
        this.siteLocationPointLayer
      ]
    })

    this.sfLegend = new SchemeflowLegend()

    let layers: Array<BaseLayer> | Collection<BaseLayer> | LayerGroup | undefined = [this.baseMapLayers, this.overlayLayers, this.additionalLayers]

    // Set up north arrow
    let compassImg = document.createElement('div')
    this.northArrowControl = new Rotate({
      autoHide: false,
      resetNorth: () => {}, // Do nothing when clicked
      label: compassImg,
      tipLabel: 'Compass'
    })

    const scaleLine = new ScaleLine({
      bar: true,
      minWidth: 100,
      units: options.scaleBarUnits,
    })

    this.googleControl = new GoogleLogoControl();
    this.googleWhiteControl = new GoogleWhiteLogoControl();
    this.attribution = new Attribution({
      collapsible: false,
    });

    this.siteLocationModify = new Modify({
      pixelTolerance: 20,
      source: this.siteLocationPointSource,
      style: function (feature) {
        return null
      }
    })

    const removeFeature = function (obj) {
      this.removeFeature(obj.data.feature);
    };
    const removePolygonItem = {
        text: 'Delete polygon',
        classname: 'hover:bg-gray-200 hover:text-black', // add some CSS rules
        icon: '/images/icons/trash.svg',
        callback: removeFeature.bind(this.redlinePolygonSource),
    };

    const handleEditTextAnnotationItem = (obj) => {
      this.editTextAnnotation(obj.data.id);
    };

    const textAnnotationEditItem = {
        text: 'Edit label',
        classname: 'hover:bg-gray-200 hover:text-black', // CSS rules for item text
        icon: '/images/icons/edit.svg',
        callback: handleEditTextAnnotationItem,
    };

    const handleStyleTextAnnotationItem = (obj) => {
      this.styleTextAnnotation(obj.data.id);
    };

    const textAnnotationStyleItem = {
      text: 'Label style',
      classname: 'hover:bg-gray-200 hover:text-black', // CSS rules for item text
      icon: '/images/icons/paintbrush.svg',
      callback: handleStyleTextAnnotationItem,
    };

    const handleDeleteTextAnnotationItem = (obj) => {
      this.deleteTextAnnotation(obj.data.id);
    }

    const textAnnotationDeleteItem = {
      text: 'Delete label',
      classname: 'hover:bg-gray-200 hover:text-black', // CSS rules for item text
      icon: '/images/icons/trash.svg',
      callback: handleDeleteTextAnnotationItem,
    };

    const handleEditShapeAnnotationItem = (obj) => {
      // Ensure feature isn't being style edited when shape editing
      this.finishStyleShapeAnnotation();
      const selectedFeatures = this.shapeAnnotationSelectInteraction.getFeatures();
      selectedFeatures.extend([obj.data.feature]);
    };

    const shapeAnnotationEditItem = {
      text: 'Edit annotation points',
      classname: 'hover:bg-gray-200 hover:text-black', // CSS rules for item text
      icon: '/images/icons/arrows-pointing-out.svg',
      callback: handleEditShapeAnnotationItem,
    };

    const handleStyleShapeAnnotationItem = (obj) => {
      this.styleShapeAnnotation(obj.data.feature);
    };

    const shapeAnnotationStyleItem = {
      text: 'Annotation style',
      classname: 'hover:bg-gray-200 hover:text-black', // CSS rules for item text
      icon: '/images/icons/paintbrush.svg',
      callback: handleStyleShapeAnnotationItem,
    };

    const shapeAnnotationDeleteItem = {
      text: 'Delete annotation',
      classname: 'hover:bg-gray-200 hover:text-black', // CSS rules for item text
      icon: '/images/icons/trash.svg',
      callback: removeFeature.bind(this.shapeAnnotationsSource),
    };

    const handleEditIsochroneItem = (obj) => {
      this.setSelectedIsochronePolygon(obj.data.feature);
    };

    const isochroneEditItem = {
      text: 'Edit isochrone',
      classname: 'hover:bg-gray-200 hover:text-black',
      icon: '/images/icons/arrows-pointing-out.svg',
      callback: handleEditIsochroneItem,
    };

    const handleDeleteIsochroneVertexItem = (obj) => {
      this.deleteSelectedIsochroneVertex(obj.data.coordinate);
    };

    const isochroneDeleteVertexItem = {
      text: 'Delete isochrone vertex',
      classname: 'hover:bg-gray-200 hover:text-black',
      icon: '/images/icons/trash.svg',
      callback: handleDeleteIsochroneVertexItem,
    };

    const handleFinishIsochroneEditItem = () => {
      this.unselectIsochronePolygon();
    };

    const finishIsochroneEditItem = {
      text: 'Finish editing isochrone',
      classname: 'hover:text-black hover:bg-gray-200',
      icon: '/images/icons/check-circle.svg',
      callback: handleFinishIsochroneEditItem,
    };

    this.contextmenu = new ContextMenu({
      width: 185,
      defaultItems: false,
      items: [],
    });
    this.contextmenu.set("active", true);
    this.redlinePolygonEdit = false;

    // Set up move map interactions but don't add them to the map yet
    this.moveMapInteractions = new Collection();
    defaults({
      altShiftDragRotate: false,
      doubleClickZoom: false,
      keyboard: false,
      mouseWheelZoom: true,
    }).forEach((interaction) => this.moveMapInteractions.push(interaction))

    // Create map
    this.map = new Map({
      layers: layers,
      controls: [this.northArrowControl, scaleLine, this.sfLegend, this.contextmenu],
      interactions: [],
      overlays: [this.overlay],
      pixelRatio: 2,
      view: new View(),
    })

    // Add move map interactions
    this.moveMapInteractions.forEach((interaction) => {
      this.map.addInteraction(interaction);
    });

    // Set up overlay select interaction
    this.map.addInteraction(this.overlaySelectInteraction);
    this.map.addInteraction(this.overlayHoverSelectInteraction);

    // Set up annotation interactions
    this.setupTextAnnotationMouseEvents();
    this.setupShapeAnnotationInteractions();

    // Set up isochrone editing interactions
    this.setupIsochroneEditInteractions();

    const redlineEditable = () => this.redlinePolygonEdit;
    const menuHandleGetTextAnnotationAtCursor = (pixel) => this.getTextAnnotationAtCursor(pixel);

    this.contextmenu.on('beforeopen', function (event) {
      const layerFilter = (layer) => {
        if (redlineEditable() && layer.get('name') === 'redline') {
          return true;
        }
        if (layer.get('name') === 'shape_annotations') {
          return true;
        }
        if (layer.get('name') === 'isochrones') {
          return true;
        }
        if (menuHandleGetTextAnnotationAtCursor(event.coordinate)) {
          return true;
        }
        return false;
      }
      const feature = this.map.forEachFeatureAtPixel(event.pixel, function (ft, l) {
        return ft;
      }, { layerFilter: layerFilter });
      // Check that the context menu is active and that a feature was found
      if (feature || menuHandleGetTextAnnotationAtCursor(event.pixel)) {
        this.enable();
      } else {
        this.disable();
      }
      // Don't allow normal browser right click context menu to open
      event.preventDefault();
    })

    // Bring selected isochrone feature collection into scope for context menu open callback
    const isochroneSelectInteractionFeatures = this.isochroneSelectInteractionFeatures;

    this.contextmenu.on('open', function (event) {
      this.clear()
      const redlineLayerFilter = (layer) => {
        return layer.get('name') === 'redline';
      };
      const redlinePolygonFeature = this.map.forEachFeatureAtPixel(event.pixel, function (ft, l) {
        return ft;
      }, { layerFilter: redlineLayerFilter });

      const shapeAnnotationsLayerFilter = (layer) => {
        return layer.get('name') === 'shape_annotations';
      };
      const shapeAnnotationFeature = this.map.forEachFeatureAtPixel(event.pixel, function (ft, l) {
        return ft;
      }, { layerFilter: shapeAnnotationsLayerFilter });

      const isochroneLayerFilter = (layer) => {
        return layer.get('name') === 'isochrones';
      };
      const isochroneFeature = this.map.forEachFeatureAtPixel(event.pixel, function (ft, l) {
        return ft;
      }, { layerFilter: isochroneLayerFilter });

      // For isochrone vertex deletion, check if there is an isochrone point
      // within the vicinity of the click event for the context menu:
      // Get a 9x9 pixel extent around click. N.B. sign of y coordinate is
      // reversed between pixels (increasing down the screen) and map (latitude
      // increases northwards)
      const minCorner = [event.pixel[0] - 4, event.pixel[1] + 4];
      const maxCorner = [event.pixel[0] + 4, event.pixel[1] - 4];
      const minCoord = this.map.getCoordinateFromPixel(minCorner);
      const maxCoord = this.map.getCoordinateFromPixel(maxCorner);
      const clickExtent = [minCoord[0], minCoord[1], maxCoord[0], maxCoord[1]];

      let selectedIsochroneVertex;
      const selectedIsochronesArray = isochroneSelectInteractionFeatures.getArray();
      if (selectedIsochronesArray.length) {
        const selectedIsochrone = selectedIsochronesArray[0];
        switch (selectedIsochrone.getGeometry().getType()) {
          case 'Polygon': {
            // For polygon isochrone, only need to delete points from outer boundary
            const boundary = selectedIsochrone.getGeometry().getLinearRing(0);
            const boundaryCoords = boundary.getCoordinates();
            selectedIsochroneVertex = boundaryCoords.find((coord) => containsCoordinate(clickExtent, coord));
            break;
          }
          case 'MultiPolygon': {
            // For multipolygon isochrone, check for points to delete on all outer boundaries
            const polygons = selectedIsochrone.getGeometry().getPolygons();
            const boundaries = polygons.map((polygon) => polygon.getLinearRing(0));
            const flattenedCoords = boundaries.flatMap((boundary) => boundary.getCoordinates());
            selectedIsochroneVertex = flattenedCoords.find((coord) => containsCoordinate(clickExtent, coord));
            break;
          }
          default: {
            console.error(`Unexpected geometry of selected isochrone feature: ${selectedIsochrone.getGeometry().getType()}`)
          }
        }
      }

      const overlayIdOrNull = menuHandleGetTextAnnotationAtCursor(event.pixel);

      // Flag tracks whether menu already has items, and hence a section marker
      // is required when adding further items
      let menuItemsAdded = false;

      if (redlinePolygonFeature) {
        redlinePolygonFeature.setStyle(highlightedPolygonStyle);
        removePolygonItem.data = { feature: redlinePolygonFeature };
        this.push(removePolygonItem);
        menuItemsAdded = true;
      }

      if (shapeAnnotationFeature) {
        shapeAnnotationEditItem.data = { feature: shapeAnnotationFeature };
        shapeAnnotationStyleItem.data = { feature: shapeAnnotationFeature };
        shapeAnnotationDeleteItem.data = { feature: shapeAnnotationFeature };
        this.extend([
          ...(menuItemsAdded ? ['-'] : []),
          shapeAnnotationEditItem,
          shapeAnnotationStyleItem,
          shapeAnnotationDeleteItem,
        ]);
        menuItemsAdded = true;
      }

      if (isochroneFeature && isochroneSelectInteractionFeatures.getArray().length === 0) {
        isochroneEditItem.data = { feature: isochroneFeature };
        if (menuItemsAdded) {
          this.push('-');
        }
        this.push(isochroneEditItem);
        menuItemsAdded = true;
      }

      if (isochroneFeature && isochroneSelectInteractionFeatures.getLength() !== 0) {
        if (menuItemsAdded) {
          this.push('-');
        }
        this.push(finishIsochroneEditItem);
        menuItemsAdded = true;
      }

     if (selectedIsochroneVertex) {
        isochroneDeleteVertexItem.data = { coordinate: selectedIsochroneVertex };
        if (menuItemsAdded) {
          this.push('-');
        }
        this.push(isochroneDeleteVertexItem);
        menuItemsAdded = true;
      }

      if (overlayIdOrNull) {
        textAnnotationEditItem.data = { id: overlayIdOrNull };
        textAnnotationStyleItem.data = { id: overlayIdOrNull };
        textAnnotationDeleteItem.data = { id: overlayIdOrNull };

        this.extend([
          ...(menuItemsAdded ? ['-'] : []),
          textAnnotationEditItem,
          textAnnotationStyleItem,
          textAnnotationDeleteItem,
        ]);
        menuItemsAdded = true;

        // If context menu is opened on an overlay, close click events are only
        // enabled for the overlay's element (so trigger on neither the map
        // itself, nor any other overlays). Add an additional click handler
        // which will close menu when clicking elsewhere on map
        const mapContainerCollection = document.getElementsByClassName('map');
        if (mapContainerCollection.length) {
          const contextMenuOverlayClickOffAbortController = new AbortController();
          const mapElement = mapContainerCollection[0];
          mapElement.addEventListener('mousedown', (e) => {
            if (this.isOpen) {
              this.closeMenu();
            }
            // If menu was open, now closed and event listener no longer needed.
            // If menu wasn't open, it was shut by some other means, and event listener no longer needed.
            contextMenuOverlayClickOffAbortController.abort();
          }, { signal: contextMenuOverlayClickOffAbortController.signal });
        }
      }
    });

    // Reset polygon style when context menu is closed
    const resetPolygonStyle = function (event) {
      this.getFeatures().forEach((feature) => {
        feature.setStyle(null);
      });
    }

    this.contextmenu.on('close', resetPolygonStyle.bind(this.redlinePolygonSource));
  }

  createErrorEvent(detail: string): void {
    let errorBus = useEventBus('error')
    errorBus.emit(detail)
  }

  setBaseLayer(layerName: string): void {
    this.baseMapLayers.getLayers().forEach((layer) => {
      if (layer.get('name') === layerName) {
        layer.setVisible(true)
        // Set min and max zoom of the map to equal the base layer
        let currentZoom = this.getZoom()
        let currentCenter = this.getCenter()
        let minZoom = layer.getMinZoom()
        let maxZoom = layer.getMaxZoom()
        this.map.setView(
          new View({
            zoom: currentZoom,
            center: currentCenter,
            // Had to ±0.25 to account for rounding errors
            minZoom: minZoom + 0.25,
            maxZoom: maxZoom - 0.25
          })
        )
      } else {
        layer.setVisible(false)
      }
    })

    // Show Google logo if Google map
    if (layerName == "Google" || layerName == "GoogleSatellite") {
      if (layerName == "Google") {
        this.googleControl.setMap(this.map)
        this.googleWhiteControl.setMap(null)
      } else {
        this.googleControl.setMap(null)
        this.googleWhiteControl.setMap(this.map)
      }
      this.attribution.setMap(this.map)
    } else {
      this.googleControl.setMap(null)
      this.googleWhiteControl.setMap(null)
      this.attribution.setMap(null)
    }
  }

  toggleOverlayLayer(overlayLayerName: string, visible: boolean): void {
    this.overlayLayers.getLayers().forEach((layer) => {
      if (layer.get('name') === overlayLayerName) {
        layer.setVisible(visible)
      }
    })
  }

  getMap(): Map {
    return this.map
  }

  setCenter(longitude: number, latitude: number): void {
    this.map.getView().setCenter([longitude, latitude])
  }

  getCenter(): number[] {
    return this.map.getView().getCenter()
  }

  setZoom(zoom: number): void {
    this.map.getView().setZoom(zoom)
  }

  getZoom(): number {
    return this.map.getView().getZoom()
  }

  adjustZoom(zoomDelta: number): void {
    this.map.getView().adjustZoom(zoomDelta)
  }

  adjustZoomAnimated(zoomDelta: number, duration: number): void {
    const initialZoom = this.getZoom()
    this.map.getView().animate({
      zoom: initialZoom + zoomDelta,
      duration: duration,
    })
  }

  // Fit map view to boundingBox
  fitMapView(boundingBox: Extent): void {
    this.map.getView().fit(boundingBox)
  }

  setPinLocation(longitude: number, latitude: number): void {
    this.siteLocationPointSource.clear()
    this.siteLocationPointSource.addFeature(
      new Feature({
        geometry: new Point([longitude, latitude])
      })
    )
  }

  setTarget(target?: string | HTMLElement | undefined): void {
    this.map.setTarget(target)
  }

  clearIsochrone() {
    this.isochroneSource.clear()
  }

  setIsochrone(isochroneGeoJSON: any) {
    this.clearIsochrone()
    const features = new GeoJSON().readFeatures(isochroneGeoJSON, {
      dataProjection: this.map.getView().getProjection(),
      featureProjection: this.map.getView().getProjection()
    })
    this.isochroneSource.addFeatures(features)
  }

  private setupIsochroneEditInteractions(): void {
    this.isochroneSelectInteractionFeatures = new Collection();

    this.isochroneSelectInteraction = new Select({
      filter: (feature, layer) => layer.get('name') === 'isochrones',
      condition: doubleClick,
      toggleCondition: doubleClick,
      style: (feature) => shapeAnnotationSelectedFeatureStyle(feature, this.map),
      // Use the specified Collection for storing selected features. This allows
      // changing the selected features programmatically by modifying the
      // Collection
      features: this.isochroneSelectInteractionFeatures,
    });
    this.map.addInteraction(this.isochroneSelectInteraction);
    this.isochroneSelectInteraction.on("select", this.enforceSingleIsochroneSelection.bind(this));

    // Click on the map outside of isochrone polygon to deselect selected element
    this.map.on('singleclick', this.unselectIsochronePolygon.bind(this));

    this.isochroneModifyInteraction = new Modify({
      features: this.isochroneSelectInteraction.getFeatures(),
      style: () => null,
    });
    this.map.addInteraction(this.isochroneModifyInteraction);
  }

  // Function for use by context menu to select a polygon isochrone for editing
  private setSelectedIsochronePolygon(feature: Feature): void {
    // Unselect any existing selected isochrone
    this.unselectIsochronePolygon();

    // Add feature to be selected to the selection interaction's feature collection
    this.isochroneSelectInteractionFeatures.push(feature);
  }

  private unselectIsochronePolygon(): void {
      this.isochroneSelectInteractionFeatures.clear();
  }

  private enforceSingleIsochroneSelection(): void {
    for (let i = 0; i < this.isochroneSelectInteractionFeatures.getLength() - 1; i++) {
      this.isochroneSelectInteractionFeatures.removeAt(0);
    }
  }

  // Function to remove a vertex from the outer boundary of a polygon. Returns
  // the coordinates of a new polygon with vertex at the specified coordinate
  // removed. Returns coordinates array instead of a Polygon geometry object so
  // the function can be used for either polygon geometries directly, or
  // MultiPolygons by mapping over the constituent polygons.
  private removeCoordinateFromPolygonGeometry(geometry: Polygon, coordinate: Coordinate): Array<Array<Coordinate>> {
    const coords = geometry.getCoordinates();

    // Check if the coord to be deleted is the first one -- if so, need to
    // handle as a special case, removing first and last values from linear
    // ring.
    if (coords[0][0] == coordinate) {
      // Remove first and last values
      const trimmedArray = coords[0].slice(1, -1);
      // Add new first value to end of array to form a linear ring
      trimmedArray.push(trimmedArray[0]);
      return [
        trimmedArray,
        ...(coords.length > 1 ? [coords.slice(1)] : []),
      ];
    }

    // Coord to be removed is not first element, just remove it
    return [
      coords[0].filter((coord) => coord.length !== coordinate.length || coord.some((val, idx) => val !== coordinate[idx])),
      ...(coords.length > 1 ? [coords.slice(1)] : []),
    ];
  }

  // Delete vertex at coordinate from the currently selected isochrone
  private deleteSelectedIsochroneVertex(coordinate: Coordinate): void {
    if (this.isochroneSelectInteractionFeatures.getLength() === 0) {
      return;
    }
    const selectedIsochroneFeature = this.isochroneSelectInteractionFeatures.item(0);
    const selectedIsochroneGeom = selectedIsochroneFeature.getGeometry();

    let newGeom;
    switch (selectedIsochroneGeom.getType()) {
      case 'Polygon': {
        newGeom = new Polygon(this.removeCoordinateFromPolygonGeometry(selectedIsochroneGeom, coordinate));
        break;
      }
      case 'MultiPolygon': {
        // For multi-polygon, need to deal with each polygon to ensure vertex is
        // removed from whichever polygon it is in
        newGeom = new MultiPolygon(selectedIsochroneGeom.getPolygons().map((polygon) => this.removeCoordinateFromPolygonGeometry(polygon, coordinate)));
        break;
      }
      default: {
        console.error(`Unexpected geometry of selected isochrone feature: ${selectedIsochroneGeom.getType()}`);
      }
    }

    this.isochroneSelectInteractionFeatures.item(0).setGeometry(newGeom);

    // Dispatch isochrone vertex deleted event to trigger save of new geometry
    this.map.dispatchEvent(new IsochroneVertexDeletedEvent());
  }

  async exportPNG(filename: string): void {
    this.getPNGImageB64().then((dataURL) => {
      const link = document.createElement('a')
      link.href = dataURL
      link.download = filename
      link.click()
    })
  }

  async getPNGImageB64(): any {
    const exportCanvasScale = 1.0
    const [origMapWidth, origMapHeight] = this.map.getSize()

    const exportOptions = {
      skipFonts: true,
      canvasWidth: origMapWidth * exportCanvasScale,
      canvasHeight: origMapHeight * exportCanvasScale
    }

    // A strange bug in the html-to-image library
    // (https://github.com/bubkoo/html-to-image/issues/432) means maps are
    // exported without the background (only showing controls on a blank
    // image) on the first export, but export correctly on 2nd or 3rd
    // attempt in Safari (and potentially Firefox) browsers (not an issue in
    // Chrome). Exporting 3 times seems to solve the issue here, we can
    // throw away the first two and return the third.
    await toPng(this.map.getTargetElement(), exportOptions)
    await toPng(this.map.getTargetElement(), exportOptions)

    return toPng(this.map.getTargetElement(), exportOptions)
  }

  async exportBlob(): Promise<Blob | null> {
    const exportCanvasScale = 1.0
    const [origMapWidth, origMapHeight] = this.map.getSize()

    const exportOptions = {
      skipFonts: true,
      canvasWidth: origMapWidth * exportCanvasScale,
      canvasHeight: origMapHeight * exportCanvasScale
    }

    // A strange bug in the html-to-image library
    // (https://github.com/bubkoo/html-to-image/issues/432) means maps are
    // exported without the background (only showing controls on a blank
    // image) on the first export, but export correctly on 2nd or 3rd
    // attempt in Safari (and potentially Firefox) browsers (not an issue in
    // Chrome). Exporting 3 times seems to solve the issue here, we can
    // throw away the first two and return the third.
    await toBlob(this.map.getTargetElement(), exportOptions)
    await toBlob(this.map.getTargetElement(), exportOptions)

    return toBlob(this.map.getTargetElement(), exportOptions)
  }

  setLegendItems(items: Array<olLegendItemOptions>): void {
    this.sfLegend.clearLegend()

    items.forEach((item) => {
      this.sfLegend.addItem(item)
    })
  }

  setLegendTitle(title: string): void {
    this.sfLegend.setTitle(title);
  }

  setIsochroneStyles(styleList: Array<Style>): void {
    // Apply list of styles to isochrones
    this.overlayLayers.getLayers().forEach((layer) => {
      if (layer.get('name') === 'isochrones') {
        layer.setStyle((feature) => {
          const isochroneId = feature.getId()

          // For all but the inmost isochrone, cut out the next-inner isochrone, so that colours remain distinct rather than overlaid.
          const geoJson = new GeoJSON({
              dataProjection: this.map.getView().getProjection(),
              featureProjection: this.map.getView().getProjection(),
            });
          let isochroneGeometry = feature.getGeometry();
          const outerGeoJson = geoJson.writeFeatureObject(feature);

          if (isochroneId > 0) {
            const innerIsochroneFeature = this.isochroneSource.getFeatureById(isochroneId - 1);
            const innerGeoJson = geoJson.writeFeatureObject(innerIsochroneFeature);

            const isochroneRingGeoJson = difference(featureCollection([outerGeoJson, innerGeoJson]));
            const isochroneRingFeature = geoJson.readFeature(isochroneRingGeoJson);
            isochroneGeometry = isochroneRingFeature.getGeometry();
          }

          // For each isochrone (multi)polygon, need to get only the outer
          // boundary for stroke style, to avoid stroke applying to both outer
          // and inner edges of each isochrone level.
          const isochroneOuterEdgeGeoJson = polygonToLine(outerGeoJson);
          let isochroneOuterEdgeGeometry
          if (isochroneOuterEdgeGeoJson.type === "Feature") {
            // Single feature
            const isochroneOuterEdgeFeature = geoJson.readFeature(isochroneOuterEdgeGeoJson);
            isochroneOuterEdgeGeometry = isochroneOuterEdgeFeature.getGeometry();
          } else {
            // Feature collection
            const isochroneOuterEdgeFeatureCollection = geoJson.readFeatures(isochroneOuterEdgeGeoJson);
            isochroneOuterEdgeGeometry =  new GeometryCollection(isochroneOuterEdgeFeatureCollection.map(feat => feat.getGeometry()));
          }

          const isochroneLevelStyle = [
            new Style({
              fill: styleList[isochroneId].getFill(),
              geometry: isochroneGeometry,
              zIndex: 0,
            }),
            new Style({
              stroke: styleList[isochroneId].getStroke(),
              zIndex: 10,
            }),
          ];

          return isochroneLevelStyle
        })
      }
    })
  }

  setColorFilters(grayscaleFilter: boolean, alpha: number): void {
    // Set each raster source operation to the new function
    ;[this.osmRasterSource, this.osLightRasterSource, this.osOutdoorRasterSource, this.osRoadRasterSource, this.satelliteRasterSource].forEach((source) => {
      source.on('beforeoperations', function (event) {
        event.data.alpha = alpha
        event.data.grayscaleFilter = grayscaleFilter
      })
      source.changed()
    })
  }

  setIsochroneOrigin(longitude: number, latitude: number): void {
    this.isochroneOriginSource.clear()
    this.isochroneOriginSource.addFeature(
      new Feature({
        geometry: new Point([longitude, latitude])
      })
    )
  }

  getIsochroneOrigin(): [number | undefined, number | undefined] {
    const features = this.isochroneOriginSource.getFeatures()

    if (features.length == 0) {
      return [undefined, undefined]
    } else {
      // features should have a length of one, so the first element will
      // always be the one we want
      return features[0].getGeometry().getCoordinates()
    }
  }

  public disableMovingSiteLocationPoint(): void {
    this.map.removeInteraction(this.siteLocationModify);
  }

  public enableMovingSiteLocationPoint(): void {
    this.map.addInteraction(this.siteLocationModify);
  }

  public clearRoadAccidents(): void {
    this.roadAccidentsSource.clear()
  }

  public setRoadAccidents(roadAccidentGeoJSON: any): void {
    this.clearRoadAccidents()

    // Ensure GeoJSON features have the right projection for drawing on map view
    const features = new GeoJSON().readFeatures(roadAccidentGeoJSON, {
      dataProjection: this.map.getView().getProjection(),
      featureProjection: this.map.getView().getProjection()
    })

    // Add accident features to map
    this.roadAccidentsSource.addFeatures(features)
  }

  // Set the centre point of collision analysis, from which the radius is measured
  public setCollisionCentrePoint(longitude: number, latitude: number): void {
    this.roadAccidentCentreSource.clear()
    this.roadAccidentCentreSource.addFeature(
      new Feature({
        geometry: new Point([longitude, latitude])
      })
    )
  }

  // Get the centre point for collision analysis, drawn by map interaction
  // onto the raodAccidentCentreSource
  public getCollisionCentrePoint(): [number | undefined, number | undefined] {
    const features = this.roadAccidentCentreSource.getFeatures()

    if (features.length == 0) {
      return [undefined, undefined]
    } else {
      // features should have a length of one, so the first element will
      // always be the one we want
      return features[0].getGeometry().getCoordinates()
    }
  }

  private enterHandlerFn(e: KeyboardEvent): void {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.redlineDrawInteraction.finishDrawing();
    }
  }

  private deleteHandlerFn(e: KeyboardEvent): void {
    if (e.key === 'Delete') {
      e.preventDefault();
      this.redlineDrawInteraction.abortDrawing();
      this.finishEditingRedLine();
    }
  }

  private backspaceHandlerFn(e: KeyboardEvent): void {
    if (e.key === 'Backspace') {
      e.preventDefault();
      this.redlineDrawInteraction.removeLastPoint();
    }
  }

  public enableEditingRedLine(): void {
    // Create draw interaction
    this.redlineDrawInteraction = new Draw({
      source: this.redlinePolygonSource,
      type: "Polygon",
      style: drawStyleFunction,
      stopClick: true,
      snapTolerance: 6,
      condition: (e) => noModifierKeys(e) && primaryAction(e),
    });
    // Add draw interaction to map
    this.map.addInteraction(this.redlineDrawInteraction);

    // Create modify interaction
    this.modifyInteraction = new Modify({
      source: this.redlinePolygonSource,
      style: function () {
        return null;
      },
    });

    // Add modify interaction to map
    this.map.addInteraction(this.modifyInteraction);

    // Set style for redline polygon layer to match draw style
    this.redlinePolygonLayer.setStyle(polygonEditStyleFunction)

    // Set flag for context menu availability
    this.redlinePolygonEdit = true;

    // Add keystroke listener for enter/backspace/delete
    document.addEventListener('keydown', this.enterHandlerFn.bind(this), { signal: this.drawKeyEventAbortController.signal });
    document.addEventListener('keydown', this.deleteHandlerFn.bind(this), { signal: this.drawKeyEventAbortController.signal });
    document.addEventListener('keydown', this.backspaceHandlerFn.bind(this), { signal: this.drawKeyEventAbortController.signal });
    // Add listener for when drawing has finished
    this.redlineDrawInteraction.once('drawend', (event) => {
      this.finishEditingRedLine();
    });

    // Add listener for when drawing has been aborted
    this.redlineDrawInteraction.once('drawabort', (event) => {
      this.finishEditingRedLine();
    });

  }

  public finishEditingRedLine(): void {

    // Remove key listeners
    this.drawKeyEventAbortController.abort();
    this.drawKeyEventAbortController = new AbortController();

    // Finish drawings
    this.redlineDrawInteraction.finishDrawing();

    // Disable draw interaction
    this.map.removeInteraction(this.redlineDrawInteraction);

    // Disable modify interaction
    this.map.removeInteraction(this.modifyInteraction);

    // Set redline polygon layer style to default
    this.redlinePolygonLayer.setStyle(redlineStyle)

    // Set flag to disable context menu for polygon outside edit mode
    this.redlinePolygonEdit = false;

    // Dispatch event to notify listeners that editing has ended
    this.map.dispatchEvent("redline_editend");
  }

  private overlayIsTextAnnotation(overlay: Overlay): boolean {
    const overlayId = overlay.getId();
    return (typeof overlayId === 'string' && overlayId.startsWith('mat_'))
  }

  public clearTextAnnotations(): void {
    // Don't delete annotations in a forEach callback, mutations aren't handled
    // well. Instead, get a list of the overlays to be removed, then remove each one
    const textAnnotationOverlays = this.map.getOverlays().getArray().filter(this.overlayIsTextAnnotation);
    const len = textAnnotationOverlays.length;
    for (let i = 0; i < len; i++) {
      this.map.removeOverlay(textAnnotationOverlays[i]);
    }
  }

  private createOverlayElement(text: string, style: TextAnnotationStyle): HTMLDivElement {
    const element = document.createElement('div');
    element.innerText = text;
    const elementStyle = getStyleAttributeForStyle(style);
    Object.assign(element.style, elementStyle);
    return element;
  }

  public setTextAnnotations(annotations: any): void {
    this.clearTextAnnotations();

    annotations.forEach((annotation) => {
      const annotationStyleProperty = annotation.properties?.style || {};
      const style = { ...textAnnotationDefaultStyle, ...annotationStyleProperty };
      const overlay = new Overlay({
        id: annotation.id,
        element: this.createOverlayElement(annotation.properties.text, style),
        position: annotation.geometry.coordinates,
        positioning: 'center-center',
      })
      this.map.addOverlay(overlay);

      // Add annotation to vector source for syncing changes back to database
      const point = new Point(annotation.geometry.coordinates);
      const feature = new Feature(point);
      feature.setId(annotation.id);
      feature.set('text', annotation.properties.text);
      // If the annotation has a custom style, set that on the feature
      if (annotation.properties?.style) {
        feature.set('style', annotation.properties.style);
      }
      this.textAnnotationsSource.addFeature(feature);
    });
  }

  private editTextAnnotation(id: string): void {
    // If an annotation is already in edit mode, finish its edit, then mark this
    // one as being in edit mode
    if (this.editingTextAnnotation) {
      this.finishTextAnnotationEdit(this.editingTextAnnotation);
    }
    this.editingTextAnnotation = id;

    // Set content editable
    const element = this.map.getOverlayById(id).getElement();
    element.contentEditable = "true";
    element.classList.add('border', 'border-sf-orange', 'focus:outline', 'focus:outline-sf-orange', 'focus:border', 'focus:border-sf-orange');
    element.classList.remove('select-none');

    // Escape to complete the edit
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        this.finishTextAnnotationEdit(id);
      }
    }, { signal: this.textAnnotationEditEventAbortController.signal });

    // Click outside of overlay to complete the edit
    const mapContainerCollection = document.getElementsByClassName('map');
    if (mapContainerCollection.length) {
      const mapElement = mapContainerCollection[0];
      document.addEventListener('mousedown', (e) => {
        // If click was in the current overlay, do nothing. Otherwise, finish edit
        const mapViewportPosition = mapElement.getBoundingClientRect();
        const clickPositionInMap = [
          e.clientX - mapViewportPosition.x,
          e.clientY - mapViewportPosition.y
        ];
        const overlayIdOrNull = this.getTextAnnotationAtCursor(clickPositionInMap);
        if (!(overlayIdOrNull==id)) {
          e.preventDefault();
          this.finishTextAnnotationEdit(id);
        }
      }, { signal: this.textAnnotationEditEventAbortController.signal })
    }

    // Put focus in the new input element
    element.focus();
  }

  private finishTextAnnotationEdit(id: string): void {
    // Remove keypress event handlers
    this.textAnnotationEditEventAbortController.abort();
    this.textAnnotationEditEventAbortController = new AbortController();

    const overlay = this.map.getOverlayById(id);
    const element = overlay.getElement();
    element.contentEditable = "false";
    element.classList.remove('border', 'border-sf-orange', 'focus:outline', 'focus:outline-sf-orange', 'focus:border', 'focus:border-sf-orange');
    element.classList.add('select-none');
    const labelText = element.innerText;
    // Update VectorSource feature, which will trigger update for database
    const feature = this.textAnnotationsSource.getFeatureById(id);
    feature.set('text', labelText);

    // Reset annotation edit mode flag
    this.editingTextAnnotation = null;
  };

  public createTextAnnotation(coordinates: [number, number]): void {
    // Create a unique id for feature, using prefix mat (for "map annotation, text")
    const id = "mat_" + nanoid(16);
    const initialText = "";

    // Add feature to vector source to trigger update in database
    const point = new Point(coordinates);
    const feature = new Feature(point);
    feature.setId(id);
    feature.set('text', initialText);
    this.textAnnotationsSource.addFeature(feature);

    // Create the overlay on the map
    const element = this.createOverlayElement(initialText, textAnnotationDefaultStyle);
    const overlay = new Overlay({
        id: id,
        element: element,
        position: coordinates,
        positioning: "center-center",
    });
    this.map.addOverlay(overlay);

    // Now edit the new annotation
    this.editTextAnnotation(id);
  }

  private deleteTextAnnotation(id: string): void {
    // Remove overlay from map
    const overlay = this.map.getOverlayById(id);
    this.map.removeOverlay(overlay);

    // Remove annotation feature from vector source to trigger deletion from database
    const feature = this.textAnnotationsSource.getFeatureById(id);
    this.textAnnotationsSource.removeFeature(feature);
  }

  private setupTextAnnotationMouseEvents(): void {
    const mapContainerCollection = document.getElementsByClassName('map');
    // New Scheme map container has class "site-map" instead of "map", but
    // annotation drag events aren't needed, so don't set up for New Scheme map
    if (!mapContainerCollection.length) {
      return;
    }
    const mapElement = mapContainerCollection[0];

    // Mouse down event to start dragging if on an overlay
    mapElement.addEventListener('mousedown', (e) => {
      // Get click position relative to map element
      const mapViewportPosition = mapElement.getBoundingClientRect();
      const clickPositionInMap = [
        e.clientX - mapViewportPosition.x,
        e.clientY - mapViewportPosition.y,
      ];

      const overlayIdOrNull = this.getTextAnnotationAtCursor(clickPositionInMap);
      if (!overlayIdOrNull) {
        return;
      }

      this.draggedTextAnnotation = this.map.getOverlayById(overlayIdOrNull);

      // Calculate offset of click from anchor point, so new position is relative to mouse position
      const overlayPosition = this.map.getPixelFromCoordinate(this.draggedTextAnnotation.getPosition());
      this.draggedTextAnnotationOffset = {
        x: e.clientX - overlayPosition[0],
        y: e.clientY - overlayPosition[1],
      };

      // Reduce opacity of overlay element during drag effect
      const element = this.draggedTextAnnotation.getElement();
      element.classList.add('!opacity-60');
    });

    // Mouse move event, across document, not contained to element
    mapElement.addEventListener('mousemove', (e) => {
      if (!this.draggedTextAnnotation) {
        return;
      }
      const currentPixel = [
        e.clientX - this.draggedTextAnnotationOffset.x,
        e.clientY - this.draggedTextAnnotationOffset.y,
      ];
      const newPosition = this.map.getCoordinateFromPixel(currentPixel);
      this.draggedTextAnnotation.setPosition(newPosition);
    });

    // Stop movement when mouse released
    mapElement.addEventListener('mouseup', () => {
      if (!this.draggedTextAnnotation) {
        return;
      }

      // update feature in textAnnotationsSource to trigger db update
      const id = this.draggedTextAnnotation.getId();
      const feature = this.textAnnotationsSource.getFeatureById(id);
      const point = new Point(this.draggedTextAnnotation.getPosition());
      feature.setGeometry(point);

      // Unset drag values
      const element = this.draggedTextAnnotation.getElement();
      element.classList.remove('!opacity-60');
      this.draggedTextAnnotation = null;
      this.draggedTextAnnotationOffset = { x: 0, y: 0};
    });

    // Double click an annotation starts enables editing the text
    mapElement.addEventListener('dblclick', (e) => {
      // Get click position relative to map element
      const mapViewportPosition = mapElement.getBoundingClientRect();
      const clickPositionInMap = [
        e.clientX - mapViewportPosition.x,
        e.clientY - mapViewportPosition.y,
      ];

      const overlayIdOrNull = this.getTextAnnotationAtCursor(clickPositionInMap);
      if (overlayIdOrNull) {
        this.editTextAnnotation(overlayIdOrNull);
      }
    });
  }

  private getTextAnnotationAtCursor(pixel: [number, number]): string | null {
    // For each Overlay, if it has an id beginning with `mat_` (map annotation,
    // text), check if the position given by pixel lies within the overlay.
    //
    // If pixel is within an overlay, return the id for that overlay, else return null.
    const overlaysCollection = this.map.getOverlays();
    const len = overlaysCollection.getLength();

    for (let i = 0; i < len; i++) {
      const overlay = overlaysCollection.item(i);

      if (!this.overlayIsTextAnnotation(overlay)) {
        continue;
      }

      const overlayPositionPixel = this.map.getPixelFromCoordinate(overlay.getPosition())
      const overlayElement = overlay.getElement();
      const width = overlayElement.offsetWidth;
      const height = overlayElement.offsetHeight;

      // Assume overlay positioning is 'center-center'. This may be changed later.
      const pixelExtent = {
        xMin: overlayPositionPixel[0] - (width / 2),
        xMax: overlayPositionPixel[0] + (width / 2),
        yMin: overlayPositionPixel[1] - (height / 2),
        yMax: overlayPositionPixel[1] + (height / 2),
      };

      if (pixel[0] >= pixelExtent.xMin && pixel[0] <= pixelExtent.xMax && pixel[1] >= pixelExtent.yMin && pixel[1] <= pixelExtent.yMax) {
        return overlay.getId();
      }
    }

    return null;
  }

  public newTextAnnotationChoosePoint(): void {
    if (!this.textAnnotationDrawInteraction) {
      // Set up the draw interaction (only required on first use)
      this.textAnnotationDrawInteraction = new Draw({
        type: "Point",
        style: new Style({
          image: new CircleStyle({
            fill: new Fill({ color: "red" }),
            radius: 5,
          }),
        }),
        stopClick: true,
        maxPoints: 1,
      });

      this.textAnnotationDrawInteraction.on("drawend", (e) => {
        this.endTextAnnotationChoosePoint();

        // Create the annotation at the selected point
        this.createTextAnnotation(e.feature.getGeometry().getCoordinates());
      })

      // Add interaction to the map
      this.map.addInteraction(this.textAnnotationDrawInteraction);
    }

    // Set up key handlers - use backspace to cancel point selection
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Backspace') {
        e.preventDefault();
        this.endTextAnnotationChoosePoint();
      }
    }, { signal: this.annotationDrawKeyEventAbortController.signal });

    this.textAnnotationDrawInteraction.setActive(true);
    this.annotationDrawingActive = 'text';
  }

  public endTextAnnotationChoosePoint(): void {
    this.textAnnotationDrawInteraction.finishDrawing();
    this.textAnnotationDrawInteraction.setActive(false);

    // Remove key handler
    this.annotationDrawKeyEventAbortController.abort()
    this.annotationDrawKeyEventAbortController = new AbortController();

    // Flag for UI changes
    this.annotationDrawingActive = null;
  }

  private styleTextAnnotation(id: string): void {
    // Ensure shape annotation style is not happening simultaneously
    this.finishStyleShapeAnnotation();

    this.triggerTextAnnotationStyleEditChangeEvent(id);

    // Clicking on map will end style edit on the current annotation
    const mapContainerCollection = document.getElementsByClassName('map');
    if (mapContainerCollection.length) {
      const mapElement = mapContainerCollection[0];
      mapElement.addEventListener('mousedown', (e) => {
        const mapViewportPosition = mapElement.getBoundingClientRect();
        const clickPositionInMap = [
          e.clientX - mapViewportPosition.x,
          e.clientY - mapViewportPosition.y
        ];
        const overlayIdOrNull = this.getTextAnnotationAtCursor(clickPositionInMap);
        // If click is not on the annotation being edited, end style editing
        if (!(overlayIdOrNull===id)) {
          e.preventDefault();
          this.finishStylingTextAnnotation();
        }
      }, { signal: this.textAnnotationStyleAbortController.signal });
    }
  }

  public finishStylingTextAnnotation(): void {
    // Remove edit click handler, if set
    this.textAnnotationStyleAbortController.abort();
    this.textAnnotationStyleAbortController = new AbortController();

    this.triggerTextAnnotationStyleEditChangeEvent(null);
  }

  private triggerTextAnnotationStyleEditChangeEvent(id: string | null): void {
    const event = new TextAnnotationStyleEditChangeEvent(id);
    this.map.dispatchEvent(event);
  }

  public setTextAnnotationStyle(id: string, newStyle: TextAnnotationStyle): void {
    // Set the style for the text annotation with id.

    // Update the Overlay displaying the annotation
    const overlay = this.map.getOverlayById(id);
    const element = overlay.getElement()
    const elementStyle = getStyleAttributeForStyle(newStyle)
    Object.assign(element.style, elementStyle);

    // Update the vector layer feature to trigger update to database
    const feature = this.textAnnotationsSource.getFeatureById(id);
    feature.set('style', newStyle);
  }

  public unsetTextAnnotationStyle(id: string): void {
    // Set overlay style to default
    const overlay = this.map.getOverlayById(id);
    const element = overlay.getElement();
    const defaultStyle = getStyleAttributeForStyle(textAnnotationDefaultStyle);
    Object.assign(element.style, defaultStyle);

    // Unset style in vector source feature, so that default values are used in future
    const feature = this.textAnnotationsSource.getFeatureById(id);
    feature.unset('style');
  }

  public clearShapeAnnotations(): void {
    this.shapeAnnotationsSource.clear();
  }

  public setShapeAnnotations(annotationCollection: any): void {
    this.clearShapeAnnotations();

    const geojson = new GeoJSON();
    const features = geojson.readFeatures(annotationCollection, {
      dataProjection: this.map.getView().getProjection(),
      featureProjection: this.map.getView().getProjection(),
    });

    this.shapeAnnotationsSource.addFeatures(features);
  }

  public createShapeAnnotation(type: shapeAnnotationType) {
    const geometryType: Record<shapeAnnotationType, string> = {
      [shapeAnnotationType.POLYGON]: 'Polygon',
      [shapeAnnotationType.LINE]: 'LineString',
      [shapeAnnotationType.ARROW]: 'LineString',
    };

    const drawingStyle: Record<shapeAnnotationType, (feature: Feature) => Style[]> = {
      [shapeAnnotationType.POLYGON]: shapeAnnotationDrawPolygonStyle,
      [shapeAnnotationType.LINE]: shapeAnnotationDrawLineStyle,
      [shapeAnnotationType.ARROW]: shapeAnnotationDrawLineStyle,
    };

    this.shapeAnnotationDrawInteraction = new Draw({
      type: geometryType[type],
      source: this.shapeAnnotationsSource,
      snapTolerance: 6,
      stopClick: true,
      style: drawingStyle[type],
    });

    // Key handler - Enter to complete drawing, Backspace to delete last point without ending drawing
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        this.finishCreatingShapeAnnotation();
      }
      if (e.key === 'Backspace') {
        e.preventDefault();
        this.shapeAnnotationDrawInteraction.removeLastPoint();
      }
    }, { signal: this.annotationDrawKeyEventAbortController.signal });

    // When draw completes, create an ID for the new annotation
    this.shapeAnnotationDrawInteraction.once("drawend", (e) => {
      // Create a unique id for the new annotation, with prefix 'mas' for "map annotation, shape"
      const id = "mas_" + nanoid(16);
      e.feature.setId(id);

      // If drawing an arrow, set a style with arrow head
      if (type == shapeAnnotationType.ARROW) {
        e.feature.set('style', shapeAnnotationArrowDefaultStyle);
      }

      this.finishCreatingShapeAnnotation();
    });

    this.map.addInteraction(this.shapeAnnotationDrawInteraction);
    this.shapeAnnotationDrawInteraction.setActive(true);

    this.annotationDrawingActive = type;
  }

  public finishCreatingShapeAnnotation() {
    this.shapeAnnotationDrawInteraction.finishDrawing();
    this.shapeAnnotationDrawInteraction.setActive(false);
    this.map.removeInteraction(this.shapeAnnotationDrawInteraction);

    // Remove key handler
    this.annotationDrawKeyEventAbortController.abort()
    this.annotationDrawKeyEventAbortController = new AbortController();

    this.annotationDrawingActive = null;
  }

  private triggerShapeAnnotationStyleEditChangeEvent(id: string | null): void {
    const event = new ShapeAnnotationStyleEditChangeEvent(id);
    this.map.dispatchEvent(event);
  }

  private handleClickDuringShapeAnnotationEdit(event): void {
    const clickPixel = event.pixel;
    let clickIsOnEditedAnnotation = false;
    this.map.forEachFeatureAtPixel(clickPixel,
      (feat) => {
        if (feat.getId() === this.shapeAnnotationStyleEditId) {
          clickIsOnEditedAnnotation = true;
          // Return true from callback to avoid testing remaining features
          return true;
        }
      },
      { layerFilter: (layer) => layer.get('name') === 'shape_annotations' }
    );

    // If user clicked on the annotation being edited, do nothing
    if (clickIsOnEditedAnnotation) {
      return;
    }

    // Click was not on edited annotation, so end editing and remove event handler
    this.finishStyleShapeAnnotation();
    this.map.un('singleclick', this.handleClickDuringShapeAnnotationEdit);
  }

  private styleShapeAnnotation(feature: Feature): void {
    // Ensure text annotation edit not happening at the same time
    this.finishStylingTextAnnotation();
    // If an annotation is currently selected for modification, deselect it
    // before opening styles. This ensures that the selected style is not
    // overriding the feature's normal style.
    this.unselectShapeAnnotations();

    const featId = feature.getId();

    // Click in map outside of current annotation to finish style editing
    this.shapeAnnotationStyleEditId = featId;
    this.map.on('singleclick', this.handleClickDuringShapeAnnotationEdit.bind(this));

    this.triggerShapeAnnotationStyleEditChangeEvent(featId);
  }

  public finishStyleShapeAnnotation(): void {
    this.triggerShapeAnnotationStyleEditChangeEvent(null);
  }

  public setShapeAnnotationStyle(id: string, style: ShapeAnnotationLineStyle | ShapeAnnotationPolygonStyle): void {
    const feature = this.shapeAnnotationsSource.getFeatureById(id);
    feature.set('style', style);
    feature.changed();
  }

  public unsetShapeAnnotationStyle(id: string): void {
    const feature = this.shapeAnnotationsSource.getFeatureById(id);
    feature.unset('style');
    feature.changed();
  }

  private setupShapeAnnotationInteractions(): void {
    this.shapeAnnotationTranslateInteraction = new Translate({
      layers: (layer) => layer.get('name') === 'shape_annotations',
    });
    this.map.addInteraction(this.shapeAnnotationTranslateInteraction);

    // Double click to select a feature, enabling modify interaction for that feature
    this.shapeAnnotationSelectInteraction = new Select({
      layers: (layer) => layer.get('name') === 'shape_annotations',
      condition: doubleClick,
      toggleCondition: doubleClick,
      style: (feature) => shapeAnnotationSelectedFeatureStyle(feature, this.map),
    })
    this.map.addInteraction(this.shapeAnnotationSelectInteraction);

    // Ensure shape isn't being style edited when selected
    this.shapeAnnotationSelectInteraction.on('select', this.finishStyleShapeAnnotation.bind(this));

    // Click on the map, off the feature, to deselect any selected elements
    this.map.on('singleclick', this.unselectShapeAnnotations.bind(this));

    this.shapeAnnotationModifyInteraction = new Modify({
      features: this.shapeAnnotationSelectInteraction.getFeatures(),
      style: () => null,
    });
    this.map.addInteraction(this.shapeAnnotationModifyInteraction);
  }

  private unselectShapeAnnotations(): void {
    const selectedFeatures = this.shapeAnnotationSelectInteraction.getFeatures();
    if (selectedFeatures.getLength()) {
      selectedFeatures.clear();

      // Reset modify interaction (occasionally persists on previously selected features
      this.shapeAnnotationModifyInteraction.setActive(false);
      this.map.removeInteraction(this.shapeAnnotationModifyInteraction);
      this.shapeAnnotationModifyInteraction = new Modify({
        features: this.shapeAnnotationSelectInteraction.getFeatures(),
        style: () => null,
      });
      this.map.addInteraction(this.shapeAnnotationModifyInteraction);
    }
  }

  public updateRedlinePolygon(geojson: string): void {
    this.redlinePolygonSource.clear();
    if (geojson) {
        const features = new GeoJSON({
            featureProjection: "EPSG:3857",
            dataProjection: "EPSG:3857",
        }).readFeatures(geojson);
        this.redlinePolygonSource.addFeatures(features);
    }
  }

  public setOverlayElement(element: HTMLElement): void {
    this.overlay.setElement(element);
  }

  private getDrawingLayerByLayerId(layerId: string): VectorLayer | undefined {
    const layers = this.additionalLayers.getLayers().getArray();
    for (const layer of layers) {
      if (layer.get('id') === layerId && layer instanceof VectorLayer) {
        return layer;
      }
    }
    return undefined;
  }

  public disableAllAdditionalLayerDrawing(): void {
    if (this.additionalLayerDrawInteraction) {
      this.additionalLayerDrawInteraction.setActive(false);
      this.map.removeInteraction(this.additionalLayerDrawInteraction);
      this.additionalLayerDrawInteraction = undefined;
    }
  }

  public enableDrawingLayer(layerId: string, options: { type: Type }): void {
    const layerSource = this.getDrawingLayerByLayerId(layerId)?.getSource() as VectorSource;
    const layerStyle = this.getDrawingLayerByLayerId(layerId)?.getStyle();
    if (layerSource) {
      this.additionalLayerDrawInteraction = new Draw({
        type: options.type,
        style: layerStyle,
        stopClick: true,
      });

      // When feature is drawn, add it to the layer source and dispatch an event
      // to notify the component that the layer has been modified
      this.additionalLayerDrawInteraction.on('drawend', (e) => {
        layerSource.addFeature(e.feature);
        const event = new AdditionalLayerModifiedEvent();
        this.map.dispatchEvent(event);
      });

      this.map.addInteraction(this.additionalLayerDrawInteraction);
      this.additionalLayerDrawInteraction.setActive(true);
    }
  }

  public setupAdditionalDrawingLayers(layers: SchemeflowAdditionalDrawingLayer[] | undefined | null): void {
    if (!layers) {
      return;
    }
    const vectorLayers = layers.map(layer => {
      const source = new VectorSource();
      if (layer.features) {
        const features = new GeoJSON().readFeatures(layer.features);
        source.addFeatures(features);
      }
      return new VectorLayer({
        source: source,
        style: generateOpenlayersStylesFromAdditionalLayer(layer),
        properties: {
          id: layer.id,
          name: layer.name,
          type: layer.type,
        },
        visible: true,
      });
    });
    const layerCollection = new Collection<BaseLayer>(vectorLayers);
    this.additionalLayers.setLayers(layerCollection);
  }

  public updateAdditionalLayersStyles(layers: SchemeflowAdditionalDrawingLayer[] | undefined | null): void {
    if (!layers) {
      return;
    }
    this.additionalLayers.getLayers().getArray().forEach((layer: VectorLayer) => {
      const layerId = layer.get('id');
      const schemeflowLayer = layers.find((l) => l.id === layerId);
      if (schemeflowLayer) {
        layer.setStyle(generateOpenlayersStylesFromAdditionalLayer(schemeflowLayer));
      }
    });
  }

  public clearAdditionalLayer(layerId: string): void {
    const layer = this.getDrawingLayerByLayerId(layerId);
    if (layer) {
      layer.getSource().clear();
      const event = new AdditionalLayerModifiedEvent();
      this.map.dispatchEvent(event);
    }
  }

  // Dump all the data into a record of GeoJSONFeatureCollections, keyed by layer UUID
  public getAdditionalLayerGeoJSONs(): Record<string, GeoJSONFeatureCollection> {
    const geoJSONs: Record<string, GeoJSONFeatureCollection> = {};
    this.additionalLayers.getLayers().getArray().forEach((layer: VectorLayer) => {
      const layerSource = layer.getSource() as VectorSource;
      if (layerSource) {
        geoJSONs[layer.get('id')] = new GeoJSON().writeFeaturesObject(layerSource.getFeatures());
      }
    });
    return geoJSONs;
  }

  public deleteDrawingLayer(layerId: string): void {
    const layer = this.getDrawingLayerByLayerId(layerId);
    if (layer) {
      this.additionalLayers.getLayers().remove(layer);
    }
  }

  // TODO use proper types/interfaces for args
  public setNorthArrow(arrowElement: HTMLElement): void {
    // Remove existing north arrow
    this.map.removeControl(this.northArrowControl);

    if (!arrowElement) {
      return;
    }

    this.northArrowControl = new Rotate({
      autoHide: false,
      resetNorth: () => {}, // Do nothing when clicked
      label: arrowElement,
      tipLabel: 'Compass',
    });

    this.map.addControl(this.northArrowControl);
  }

  public closeOverlay(): void {
    this.overlay.setPosition(undefined);
  }

  public clearSiteBoundaryBufferZones(): void {
    this.siteBoundaryBufferZoneSource.clear();
  }

  public setSiteBoundaryBufferZones(bufferZonesGeoJSON: any): void {
    this.clearSiteBoundaryBufferZones();

    const features = new GeoJSON().readFeatures(bufferZonesGeoJSON, {
      dataProjection: this.map.getView().getProjection(),
      featureProjection: this.map.getView().getProjection(),
    })
    this.siteBoundaryBufferZoneSource.addFeatures(features);
  }

  public setSiteBoundaryBufferZoneStyles(bufferZoneStyles: Record<number, Style>): void {
    // Set a style function which will style each buffer zone feature according to its radius.
    this.siteBoundaryBufferZoneLayer.setStyle((feature) => {
      const bufferZoneRadius = feature.get('radius');
      return bufferZoneStyles[bufferZoneRadius];
    })
  }

}
