/* global Office */
import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";
import { useStorage } from "@vueuse/core";

import { amenitiesMaps } from "@/config/amenitiesMaps";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            name: "home",
            path: "",
            meta: {
                auth: "any",
            },
            beforeEnter: async (to, from, next) => {
                // If running in Office, redirect to the sidebar homepage
                // Otherwise, redirect to the project portal
                const host = Office.context.host;
                const platform = Office.context.platform;
                if (host && platform) {
                    next({ name: "word_home" });
                } else {
                    next({ name: "portal" });
                }
            },
        },
        {
            path: "/word_home",
            name: "word_home",
            component: () => import("../views/Word/WordPortalSidebar.vue"),
            meta: {
                auth: "authenticated",
            },
        },
        {
            path: "/sidebar_new",
            name: "sidebar_new",
            component: () => import("../views/Word/SidepaneNewDocument.vue"),
            meta: {
                auth: "authenticated",
            },
        },
        {
            path: "/project/:id",
            name: "project",
            component: () => import("../views/SchemeflowProject.vue"),
            meta: {
                auth: "authenticated",
                checkTier: false,
            },
        },
        {
            path: "/toolbox/site_map/:id",
            name: "site_map",
            component: () => import("../views/Toolbox/SiteMap.vue"),
            props: {},
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/regional_site_map/:id",
            name: "regional_site_map",
            component: () => import("../views/Toolbox/RegionalSiteMap.vue"),
            props: {},
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/satellite_map/:id",
            name: "satellite_map",
            component: () => import("../views/Toolbox/SatelliteMap.vue"),
            props: {},
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/bus_services/:id",
            name: "bus_services",
            component: () => import("../views/Toolbox/BusServicesMap.vue"),
            props: {},
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/highways_map/:id",
            name: "highways_map",
            component: () => import("../views/Toolbox/HighwaysMap.vue"),
            props: {},
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/rail_services/:id",
            name: "rail_services",
            component: () => import("../views/Toolbox/RailServices.vue"),
            props: {},
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/pedestrian_map/:id",
            name: "pedestrian_map",
            component: () => import("../views/Toolbox/PedestrianIsochroneMap.vue"),
            props: {},
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/driving_map/:id",
            name: "driving_map",
            component: () => import("../views/Toolbox/DrivingIsochroneMap.vue"),
            props: {},
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/collision_map/:id",
            name: "collision_map",
            component: () => import("../views/Toolbox/CollisionMap.vue"),
            meta: {
                auth: "authenticated",
            },
        },
        {
            path: "/toolbox/street_segments_map/:id",
            name: "street_segments_map",
            component: () => import("../views/Toolbox/StreetSegments.vue"),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/intersections_map/:id",
            name: "intersections_map",
            component: () => import("../views/Toolbox/IntersectionsMap.vue"),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/policy_explorer/:id",
            name: "policy_explorer",
            component: () => import("../views/Toolbox/PolicyExplorer.vue"),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/toolbox/public_transport_isochrone_map/:id",
            name: "public_transport_isochrone_map",
            component: () => import("../views/Toolbox/PublicTransportIsochroneMap.vue"),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/ite_trip_gen/:id",
            name: "ite_trip_gen",
            component: () => import("../views/Word/SidepaneITETripGen.vue"),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/ite_oauth_callback",
            name: "ite_callback",
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
            beforeEnter: async (to, from, next) => {
                const code = to.query.code;
                const state = to.query.state;
                // TODO error handling
                if (code && state) {
                    // You can handle the code and state here if needed
                    const response = await axios.get("/api/ite/callback", {
                        params: {
                            code: code,
                            state: state,
                        },
                    });
                    // If running in Office, send the ITE JWT to the parent (sidebar)
                    // Office JS - determine if app is being run in Office add-in or not
                    if (Office.context.host && Office.context.platform) {
                        Office.context.ui.messageParent(true.toString());
                    }
                    // Store the ITE JWT in local storage
                    useStorage("ite_jwt", response.data);
                }
                // Should never get here - Word dialogbox should be closed
                next({
                    name: "ite_trip_gen",
                    params: {
                        id: 1,
                    },
                });
            },
        },
        {
            path: "/login",
            name: "login",
            component: () => import("../views/SchemeflowLogin.vue"),
            meta: {
                auth: "unauthenticated",
            },
        },
        {
            path: "/new",
            name: "new",
            component: () => import("../views/NewScheme.vue"),
            meta: {
                auth: "authenticated",
                checkTier: false,
            },
        },
        {
            path: "/portal",
            name: "portal",
            component: () => import("../views/SchemeflowPortal.vue"),
            meta: {
                auth: "authenticated",
                checkTier: false,
            },
        },
        {
            path: "/onboarding",
            name: "onboarding",
            component: () => import("../views/SchemeflowOnboarding.vue"),
            meta: {
                auth: "any",
            },
        },
        {
            path: "/sidepane/toolbox/site_map/:id/:contentControlId",
            name: "cc_sidebar_site_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Site Location Map",
                toolbox: "site_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/regional_site_map/:id/:contentControlId",
            name: "cc_sidebar_regional_site_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Regional Map",
                toolbox: "regional_site_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/amenities_map/:id/:contentControlId",
            name: "cc_sidebar_amenities_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Amenities Map",
                toolbox: "amenities_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/bus_services/:id/:contentControlId",
            name: "cc_sidebar_bus_services",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Bus Services",
                toolbox: "bus_services",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/rail_services/:id/:contentControlId",
            name: "cc_sidebar_rail_services",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Rail Services",
                toolbox: "rail_services",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/pedestrian_map/:id/:contentControlId",
            name: "cc_sidebar_pedestrian_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Walking Isochrone",
                toolbox: "pedestrian_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/osm_bus_map/:id/:contentControlId",
            name: "cc_sidebar_osm_bus_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Bus Stops Map",
                toolbox: "osm_bus_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/local_transport_map/:id/:contentControlId",
            name: "cc_sidebar_local_transport_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Local Transport Facilities Map",
                toolbox: "local_transport_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/us_amenities_map/:id/:contentControlId",
            name: "cc_sidebar_us_amenities_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Amenities Map",
                toolbox: "us_amenities_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/ev_charger_map/:id/:contentControlId",
            name: "cc_sidebar_ev_charger_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Electric Vehicle Charger Map",
                toolbox: "ev_charger_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/community_facilities_map/:id/:contentControlId",
            name: "cc_sidebar_community_facilities_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Community Facilities Map",
                toolbox: "community_facilities_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/satellite_map/:id/:contentControlId",
            name: "cc_sidebar_satellite_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Satellite Map",
                toolbox: "satellite_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/cycling_map/:id/:contentControlId",
            name: "cc_sidebar_cycling_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Cycling Isochrone",
                toolbox: "cycling_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/highways_map/:id/:contentControlId",
            name: "cc_sidebar_highways_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Local Highways Map",
                toolbox: "highways_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/driving_map/:id/:contentControlId",
            name: "cc_sidebar_driving_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Driving Isochrone",
                toolbox: "driving_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/toolbox/accident_map/:id/:contentControlId",
            name: "cc_sidebar_accident_map",
            component: () => import("../views/Word/SidepaneContentControlToolbox.vue"),
            props: (route) => ({
                toolboxName: "Collision Map",
                toolbox: "collision_map",
                schemeId: route.params.id,
                contentControlId: route.params.contentControlId,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/sidepane/template_tools",
            name: "word_sidebar_template_tools",
            component: () => import("../views/Word/SidepaneTemplateTools.vue"),
            props: true,
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/user_account/request_password_reset",
            name: "request_password_reset",
            component: () => import("../views/SchemeflowRequestPasswordReset.vue"),
            meta: {
                auth: "any",
            },
        },
        {
            path: "/user_account/reset_password",
            name: "reset_password",
            component: () => import("../views/SchemeflowResetPassword.vue"),
            meta: {
                auth: "any",
            },
        },
        {
            path: "/user_account/password_reset_required",
            name: "password_reset_required",
            component: () => import("../views/SchemeflowPasswordResetRequired.vue"),
            meta: {
                auth: "any",
            },
        },
        {
            path: "/no_word_access",
            name: "no_word_access",
            component: () => import("../views/SchemeflowNoWordAccess.vue"),
            meta: {
                auth: "noWordAccess",
            },
        },
        {
            path: "/checkout/:price",
            name: "checkout",
            component: () => import("../views/SchemeflowCheckout.vue"),
            meta: {
                auth: "any",
            },
        },
        {
            path: "/verify_payment",
            name: "verify_payment",
            component: () => import("../views/SchemeflowVerifyPayment.vue"),
            meta: {
                auth: "any",
            },
        },
        {
            path: "/user_account/create_account",
            name: "create_account",
            component: () => import("../views/SchemeflowCreateAccount.vue"),
            meta: {
                auth: "any",
            },
        },
        {
            path: "/tasks/:id/:task_id",
            name: "task",
            component: () => import("../views/ProjectTask.vue"),
            meta: {
                auth: "any",
            },
        },
        {
            path: "/snippet_library/:project_id?",
            name: "snippet_library",
            component: () => import("../views/SnippetLibrary.vue"),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
            props: (route) => ({ project_id: route.params.project_id ?? "" }),
        },
        {
            path: "/snippet_library/new_snippet/:snippet_type/:project_id?",
            name: "new_snippet",
            component: () => import("../views/NewSnippet.vue"),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
            props: (route) => ({
                snippetType: route.params.snippet_type,
                projectId: route.params.project_id ?? "",
            }),
        },
        {
            path: "/snippet_library/edit_snippet/:snippet_id/:project_id?",
            name: "edit_snippet",
            component: () => import("../views/EditSnippet.vue"),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
            props: (route) => ({
                snippetId: route.params.snippet_id,
                projectId: route.params.project_id,
            }),
        },
        {
            path: "/checklist_template_portal",
            name: "checklist_template_portal",
            component: () => import("../views/SchemeflowChecklistTemplatePortal.vue"),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/checklist_template_editor/:checklist",
            name: "checklist_template_editor",
            component: () => import("../views/SchemeflowChecklistTemplateEditor.vue"),
            props: (route) => ({
                newChecklist: route.query.new,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/task_template_editor/:task",
            name: "task_template_editor",
            component: () => import("../views/SchemeflowTaskTemplateEditor.vue"),
            props: (route) => ({
                newTask: route.query.new,
            }),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/admin/tiers",
            name: "tier_editor",
            component: () => import("../views/Admin/ListTiers.vue"),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        {
            path: "/templates",
            name: "templates",
            component: () => import("../views/MyTemplates.vue"),
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        },
        ...Object.entries(amenitiesMaps).map(([key, config]) => ({
            path: `/toolbox/${key}/:id`,
            name: key,
            component: () => import("../views/Toolbox/AmenitiesMap.vue"),
            props: {
                toolName: config.name,
                mapKey: key,
                amenities: config.amenities,
                amenitiesListTitle: config.amenities_list_title,
                isochroneTravelModeSelector: config.isochrone_travel_mode_selector,
            },
            meta: {
                auth: "authenticated",
                checkTier: true,
            },
        })),
    ],
});

export default router;
