export const contentControlPrefixes = {
    TOOLBOX: "sftb_",
    DOCUMENT_PROPERTY: "sfpp_",
    MAP: "sfmp_",
};

// takes a tag like "sfmp_site_map" and returns an array, [prefix, tagName],
// such as ["sfmp_", "site_map"]. The tagName can be used as the key to
// contentControlConfig to get other cc properties.
export function getContentControlPrefixName(tag: string): [string, string] {
    return [tag.slice(0, 5), tag.slice(5)];
}

export const SF_XML_NAMESPACE = "http://report.schemeflow.com/v1";

export interface ContentControl {
    title: string;
    tag: string;
    text: string;
    route?: string;
    module_name?: string;
    dataBinding?: string;
}

export const contentControlConfig: Record<string, ContentControl> = {
    ite_trip_gen: {
        title: "ITE Trip Gen",
        tag: contentControlPrefixes.TOOLBOX + "ite_trip_gen",
        text: "Click to insert ITE Trip Gen",
        route: "ite_trip_gen",
    },
    site_map: {
        title: "Site Location Map",
        tag: contentControlPrefixes.MAP + "site_map",
        text: "Click to insert site map",
        module_name: "site_map",
        route: "cc_sidebar_site_map",
    },
    regional_site_map: {
        title: "Regional Map",
        tag: contentControlPrefixes.MAP + "regional_site_map",
        text: "Click to insert regional map",
        module_name: "regional_site_map",
        route: "cc_sidebar_regional_site_map",
    },
    satellite_map: {
        title: "Satellite Map",
        tag: contentControlPrefixes.MAP + "satellite_map",
        text: "Click to insert satellite map",
        module_name: "satellite_map",
        route: "cc_sidebar_satellite_map",
    },
    amenities_map: {
        title: "Amenities Map",
        tag: contentControlPrefixes.MAP + "amenities_map",
        text: "Click to insert amenities map",
        module_name: "amenities_map",
        route: "cc_sidebar_amenities_map",
    },
    us_amenities_map: {
        title: "Amenities Map",
        tag: contentControlPrefixes.MAP + "us_amenities_map",
        text: "Click to insert amenities map",
        module_name: "us_amenities_map",
        route: "cc_sidebar_us_amenities_map",
    },
    highways_map: {
        title: "Local Highways Map",
        tag: contentControlPrefixes.MAP + "highways_map",
        text: "Click here to insert local highways map",
        module_name: "highways_map",
        route: "cc_sidebar_highways_map",
    },
    pedestrian_map: {
        title: "Walking Isochrone",
        tag: contentControlPrefixes.MAP + "pedestrian_map",
        text: "Click to insert walking isochrone map",
        module_name: "pedestrian_map",
        route: "cc_sidebar_pedestrian_map",
    },
    cycling_map: {
        title: "Cycling Isochrone",
        tag: contentControlPrefixes.MAP + "cycling_map",
        text: "Click to insert cycling isochrone map",
        module_name: "cycling_map",
        route: "cc_sidebar_cycling_map",
    },
    driving_map: {
        title: "Driving Isochrone",
        tag: contentControlPrefixes.MAP + "driving_map",
        text: "Click to insert driving isochrone map",
        module_name: "driving_map",
        route: "cc_sidebar_driving_map",
    },
    bus_stops_map: {
        title: "Bus Stops Map",
        tag: contentControlPrefixes.MAP + "bus_stops_map",
        text: "Click to insert bus stops map",
        module_name: "bus_services",
        route: "cc_sidebar_bus_services",
    },
    bus_services_table: {
        title: "Bus Services Table",
        tag: contentControlPrefixes.TOOLBOX + "bus_services_table",
        text: "Click to insert bus services table",
        module_name: "bus_services",
        route: "cc_sidebar_bus_services",
    },
    rail_stations_map: {
        title: "Rail Stations Map",
        tag: contentControlPrefixes.MAP + "rail_stations_map",
        text: "Click to insert rail stations map",
        module_name: "rail_services",
        route: "cc_sidebar_rail_services",
    },
    rail_stations_table: {
        title: "Rail Stations Table",
        tag: contentControlPrefixes.TOOLBOX + "rail_stations_table",
        text: "Click to insert rail stations table",
        module_name: "rail_services",
        route: "cc_sidebar_rail_services",
    },
    rail_services_table: {
        title: "Rail Services Table",
        tag: contentControlPrefixes.TOOLBOX + "rail_services_table",
        text: "Click to insert rail services table",
        module_name: "rail_services",
        route: "cc_sidebar_rail_services",
    },
    accident_map: {
        title: "Collision Map",
        tag: contentControlPrefixes.MAP + "accident_map",
        text: "Click to insert collision map",
        module_name: "collision_map",
        route: "cc_sidebar_accident_map",
    },
    osm_bus_map: {
        title: "Bus Map",
        tag: contentControlPrefixes.MAP + "osm_bus_map",
        text: "Click to insert bus map",
        module_name: "osm_bus_map",
        route: "cc_sidebar_osm_bus_map",
    },
    local_transport_map: {
        title: "Local Transport Facilities map",
        tag: contentControlPrefixes.MAP + "local_transport_map",
        text: "Click to insert local transport facilities map",
        module_name: "local_transport_map",
        route: "cc_sidebar_local_transport_map",
    },
    ev_charger_map: {
        title: "Electric Vehicle Charger Map",
        tag: contentControlPrefixes.MAP + "ev_charger_map",
        text: "Click to insert electric vehicle charger map",
        module_name: "ev_charger_map",
        route: "cc_sidebar_ev_charger_map",
    },
    community_facilities_map: {
        title: "Community Facilities Map",
        tag: contentControlPrefixes.MAP + "community_facilities_map",
        text: "Click to insert community facilities map",
        module_name: "community_facilities_map",
        route: "cc_sidebar_community_facilities_map",
    },
    ea_reservoir_flood_extents_map: {
        title: "EA Reservoir Flood Extents Map",
        tag: contentControlPrefixes.MAP + "ea_reservoir_flood_extents_map",
        text: "Click to insert EA Reservoir Flood Extents Map",
        module_name: "ea_reservoir_flood_extents_map",
    },
    ea_flood_risk_zones_map: {
        title: "EA Flood Risk Zones Map",
        tag: contentControlPrefixes.MAP + "ea_flood_risk_zones_map",
        text: "Click to insert EA Flood Risk Zones Map",
        module_name: "ea_flood_risk_zones_map",
    },
    ea_main_rivers_map: {
        title: "EA Main Rivers Map",
        tag: contentControlPrefixes.MAP + "ea_main_rivers_map",
        text: "Click to insert EA Main Rivers Map",
        module_name: "ea_main_rivers_map",
    },
    ea_surface_water_flood_risk_extents_map: {
        title: "EA Surface Water Flood Risk Extents Map",
        tag: contentControlPrefixes.MAP + "ea_surface_water_flood_risk_extents_map",
        text: "Click to insert EA Surface Water Flood Risk Extents Map",
        module_name: "ea_surface_water_flood_risk_extents_map",
    },
    national_cycle_network_map: {
        title: "National Cycle Network Map",
        tag: contentControlPrefixes.MAP + "national_cycle_network_map",
        text: "Click to insert National Cycle Network Map",
        module_name: "national_cycle_network_map",
    },
    aq_surrounding_land_uses_map: {
        title: "Surrounding Land Uses Map",
        tag: contentControlPrefixes.MAP + "aq_surrounding_land_uses_map",
        text: "Click to insert Surrounding Land Uses Map",
        module_name: "aq_surrounding_land_uses_map",
    },
    aq_monitoring_sites_map: {
        title: "Monitoring Sites Map",
        tag: contentControlPrefixes.MAP + "aq_monitoring_sites_map",
        text: "Click to insert Monitoring Sites Map",
        module_name: "aq_monitoring_sites_map",
    },
    aq_construction_impact_map: {
        title: "Construction Impact Map",
        tag: contentControlPrefixes.MAP + "aq_construction_impact_map",
        text: "Click to insert Construction Impact Map",
        module_name: "aq_construction_impact_map",
    },
    aq_receptor_map: {
        title: "Receptor Map",
        tag: contentControlPrefixes.MAP + "aq_receptor_map",
        text: "Click to insert Receptor Map",
        module_name: "aq_receptor_map",
    },
    public_transport_isochrone_map: {
        title: "Public Transport Isochrone Map",
        tag: contentControlPrefixes.MAP + "public_transport_isochrone_map",
        text: "Click to insert Public Transport Isochrone Map",
        module_name: "public_transport_isochrone_map",
    },
    name_of_development: {
        title: "Name of Development",
        tag: contentControlPrefixes.DOCUMENT_PROPERTY + "name_of_development",
        text: "[Name of Development]",
    },
    site_location_description: {
        title: "Site Location Description",
        tag: contentControlPrefixes.DOCUMENT_PROPERTY + "site_location_description",
        text: "[Site Location Description]",
    },
    client_name: {
        title: "Client Name",
        tag: contentControlPrefixes.DOCUMENT_PROPERTY + "client_name",
        text: "[Client name]",
    },
    document_type: {
        title: "Document Type",
        tag: contentControlPrefixes.DOCUMENT_PROPERTY + "document_type",
        text: "[Document Type]",
    },
    planning_applicant: {
        title: "Planning Applicant",
        tag: contentControlPrefixes.DOCUMENT_PROPERTY + "planning_applicant",
        text: "[Planning Applicant]",
    },
    project_number: {
        title: "Project Number",
        tag: contentControlPrefixes.DOCUMENT_PROPERTY + "project_number",
        text: "[Project Number]",
    },
    author: {
        title: "Author",
        tag: contentControlPrefixes.DOCUMENT_PROPERTY + "author",
        text: "[Author Name]",
        dataBinding: `<w:dataBinding w:prefixMappings="xmlns:ns0='http://purl.org/dc/elements/1.1/' xmlns:ns1='http://schemas.openxmlformats.org/package/2006/metadata/core-properties' " w:xpath="/ns1:coreProperties[1]/ns0:creator[1]"/>`,
    },
    company: {
        title: "Company",
        tag: contentControlPrefixes.DOCUMENT_PROPERTY + "company",
        text: "[Company]",
        dataBinding: `<w:dataBinding w:prefixMappings="xmlns:ns0='http://schemas.openxmlformats.org/officeDocument/2006/extended-properties' " w:xpath="/ns0:Properties[1]/ns0:Company[1]"/>`,
    },
    planning_type: {
        title: "Planning Type",
        tag: contentControlPrefixes.DOCUMENT_PROPERTY + "planning_type",
        text: "[Planning Type]",
    },
    local_planning_authority: {
        title: "Local Planning Authority",
        tag: contentControlPrefixes.DOCUMENT_PROPERTY + "local_planning_authority",
        text: "[Local Planning Authority]",
    },
    current_use: {
        title: "Current Use",
        tag: contentControlPrefixes.DOCUMENT_PROPERTY + "current_use",
        text: "[Current Use]",
    },
    proposed_use: {
        title: "Proposed Use",
        tag: contentControlPrefixes.DOCUMENT_PROPERTY + "proposed_use",
        text: "[Proposed Use]",
    },
    slight_collisions: {
        title: "Slight Collisions",
        tag: contentControlPrefixes.DOCUMENT_PROPERTY + "slight_collisions",
        text: "[# of Slight Collisions]",
    },
    serious_collisions: {
        title: "Serious Collisions",
        tag: contentControlPrefixes.DOCUMENT_PROPERTY + "serious_collisions",
        text: "[# of Serious Collisions]",
    },
    fatal_collisions: {
        title: "Fatal Collisions",
        tag: contentControlPrefixes.DOCUMENT_PROPERTY + "fatal_collisions",
        text: "[# of Fatal Collisions]",
    },
    total_collisions: {
        title: "Total Collisions",
        tag: contentControlPrefixes.DOCUMENT_PROPERTY + "total_collisions",
        text: "[Total # Collisions]",
    },
    city: {
        title: "City",
        tag: contentControlPrefixes.DOCUMENT_PROPERTY + "city",
        text: "[City]",
    },
    state: {
        title: "State",
        tag: contentControlPrefixes.DOCUMENT_PROPERTY + "state",
        text: "[State]",
    },
};

export const schemeflowMapModules = Object.values(contentControlConfig).filter((config) =>
    config.tag.startsWith(contentControlPrefixes.MAP)
);
