<template>
    <div class="w-full h-full" id="streetViewPano"></div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, watch } from "vue";
import { Loader } from "@googlemaps/js-api-loader";

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

const loader = new Loader({
    apiKey: GOOGLE_MAPS_API_KEY,
    version: "weekly",
    libraries: [],
});

const props = defineProps({
    latitude: {
        type: Number,
        required: true,
    },
    longitude: {
        type: Number,
        required: true,
    },
});

let panorama;
const initialize = async () => {
    const { StreetViewPanorama } = await loader.importLibrary("streetView");
    panorama = new StreetViewPanorama(document.getElementById("streetViewPano"), {
        position: { lat: props.latitude, lng: props.longitude },
        addressControl: false,
        linksControl: true,
        motionTrackingControl: false,
        panControl: false,
        enableCloseButton: false,
        zoomControl: false,
        fullscreenControl: true,
        zoom: 0,
    });
};
let unwatch;

onMounted(() => {
    initialize();
    unwatch = watch([() => props.latitude, () => props.longitude], () => {
        panorama.setPosition({ lat: props.latitude, lng: props.longitude });
        panorama.setZoom(0);
    });
});

onBeforeUnmount(() => {
    if (unwatch) {
        unwatch();
    }
    if (panorama) {
        panorama = null;
    }
});
</script>

<style>
#streetViewPano
    > div
    > div:nth-child(13)
    > div.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom
    > div.gmnoprint {
    display: none;
}
</style>
