<template>
    <SchemeflowMap
        mapKey="highways_map"
        isochrone-profile="select"
        :legendConfigMasterList="userStore.thisRegionConfig.highwaysMapConfig.legend"
        :markReadyForExportMilliseconds="2500"
    ></SchemeflowMap>
</template>

<script setup>
import SchemeflowMap from "@/components/SchemeflowMap.vue";
import { useUserStore } from "@/stores/UserStore";

const userStore = useUserStore();
</script>
