import { ref, Ref } from "vue";
import { defineStore } from "pinia";
import { StreetSegment } from "../analyses/streetSegments";

export const useStreetSegmentsStore = defineStore("StreetSegments", () => {
    const streetSegments: Ref<StreetSegment[]> = ref([]);

    const addStreetSegment = (streetSegment: StreetSegment): void => {
        // If the last street segment has no name, replace it with the new street segment
        // if (streetSegments.value.length > 0 && !streetSegments.value[streetSegments.value.length - 1].name) {
            // streetSegments.value[streetSegments.value.length - 1] = streetSegment;
        // Otherwise, add the new street segment to the end of the array
        // } else {
            streetSegments.value.push(streetSegment);
        // }
    };

    return { streetSegments, addStreetSegment };
});
