// Import map components
import sitemapComponent from "@/components/Maps/SiteMap.vue";
import regionalSiteMapComponent from "@/components/Maps/RegionalSiteMap.vue";
import satelliteMapComponent from "@/components/Maps/SatelliteMap.vue";
import amenitiesMapComponent from "@/components/Maps/AmenitiesMap.vue";
import busServicesMapComponent from "@/components/Maps/BusServicesMap.vue";
import railStationsMapComponent from "@/components/Maps/RailStationsMap.vue";
import pedestrianMapComponent from "@/components/Maps/PedestrianIsochroneMap.vue";
import drivingMapComponent from "@/components/Maps/DrivingIsochroneMap.vue";
import highwaysMapComponent from "@/components/Maps/HighwaysMap.vue";
import collisionMapComponent from "@/components/Maps/CollisionMap.vue";
import publicTransportIsochroneMapComponent from "@/components/Maps/PublicTransportIsochroneMap.vue";

import { amenitiesMaps } from "@/config/amenitiesMaps";

// Import icons
import SiteLocationMapIcon from "@/assets/icons/site_location_map.svg?url";
import RoadsIcon from "@/assets/icons/roads.svg?url";
import IntersectionsIcon from "@/assets/icons/intersection.svg?url";
import SatelliteMapIcon from "@/assets/icons/satellite_map.svg?url";
import BusServicesIcon from "@/assets/icons/bus_services.svg?url";
import WalkingIsochroneIcon from "@/assets/icons/walking_isochrone.svg?url";
import DrivingIsochroneIcon from "@/assets/icons/driving_isochrone.svg?url";
import HighwaysMapIcon from "@/assets/icons/highways_map.svg?url";
import RailServicesIcon from "@/assets/icons/rail_services.svg?url";
import PolicyIcon from "@/assets/icons/policy.svg?url";
import CollisionMapIcon from "@/assets/icons/collisions_map.svg?url";
import ExternalLinkIcon from "@/assets/icons/externalTools/external_link.svg?url";
import PublicTransportIsochroneMapIcon from "@/assets/icons/public_transport_isochrone.svg?url";

// External module icons
import TflWebcatPTALIcon from "@/assets/icons/externalTools/tfl_webcat_ptal.svg?url";
import NationalHighwaysCountsIcon from "@/assets/icons/externalTools/national_highways_counts.svg?url";
import NationalCycleNetworkIcon from "@/assets/icons/externalTools/national_cycle_network.svg?url";
import BikeShareSchemesIcon from "@/assets/icons/externalTools/bike_share_schemes.svg?url";
import EVChargingPointsIcon from "@/assets/icons/externalTools/ev_charging_points.svg?url";
import RailStationUsageIcon from "@/assets/icons/externalTools/rail_station_usage.svg?url";
import StreetviewIcon from "@/assets/icons/externalTools/streetview.svg?url";
import GoogleMapsIcon from "@/assets/icons/externalTools/googlemaps.svg?url";
import TrafficIcon from "@/assets/icons/externalTools/traffic.svg?url";
import TravelToWorkFlowsIcon from "@/assets/icons/externalTools/travel_to_work_flows.svg?url";
import TravelToWorkMethodsIcon from "@/assets/icons/externalTools/travel_to_work_mode_shares.svg?url";
import DistanceTravelledToWorkIcon from "@/assets/icons/externalTools/travel_to_work_distances.svg?url";
import CarOwnershipIcon from "@/assets/icons/externalTools/car_ownership.svg?url";
import CarClubIcon from "@/assets/icons/externalTools/car_club.svg?url";
import OffsiteParkingIcon from "@/assets/icons/externalTools/offsite_parking.svg?url";
import LightRailIcon from "@/assets/icons/externalTools/light_rail_usage.svg?url";
import TRICsIcon from "@/assets/icons/externalTools/trics.svg?url";

export const today = new Date();
export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const usCityOptions = {
    California: {
        "Alameda County": ["Berkeley", "Dublin", "Fremont", "Hayward", "Oakland", "San Leandro"],
        "Contra Costa County": ["Antioch", "San Ramon", "Walnut Creek"],
        "Marin County": ["Novato", "San Rafael"],
        "Napa County": ["Napa"],
        "San Francisco County": ["San Francisco"],
        "San Mateo County": ["Redwood City", "San Mateo", "South San Francisco"],
        "Santa Clara County": [
            "Cupertino",
            "Milpitas",
            "Mountain View",
            "Palo Alto",
            "San Jose",
            "Santa Clara",
            "Sunnyvale",
        ],
        "Solano County": ["Fairfield", "Vallejo"],
        "Sonoma County": ["Petaluma", "Santa Rosa"],
        "San Diego County": [
            "City of San Diego",
            "City of Carlsbad",
            "City of Chula Vista",
            "City of Encinitas",
            "City of Escondido",
            "City of Oceanside",
            "City of San Marcos",
            "City of Santee",
            "City of Vista",
        ],
    },
};

export const localPlanningAuthorityOptions = [
    { label: "Aberdeen City Council", value: "Aberdeen City Council" },
    { label: "Aberdeenshire Council", value: "Aberdeenshire Council" },
    { label: "Adur District Council", value: "Adur District Council" },
    { label: "Allerdale Borough Council", value: "Allerdale Borough Council" },
    { label: "Amber Valley Borough Council", value: "Amber Valley Borough Council" },
    { label: "Angus Council", value: "Angus Council" },
    { label: "Antrim and Newtownabbey Borough Council", value: "Antrim and Newtownabbey Borough Council" },
    { label: "Ards and North Down Borough Council", value: "Ards and North Down Borough Council" },
    { label: "Argyll and Bute Council", value: "Argyll and Bute Council" },
    {
        label: "Armagh City, Banbridge and Craigavon Borough Council",
        value: "Armagh City, Banbridge and Craigavon Borough Council",
    },
    { label: "Arun District Council", value: "Arun District Council" },
    { label: "Ashfield District Council", value: "Ashfield District Council" },
    { label: "Ashford Borough Council", value: "Ashford Borough Council" },
    { label: "Babergh District Council", value: "Babergh District Council" },
    { label: "Barnsley Metropolitan Borough Council", value: "Barnsley Metropolitan Borough Council" },
    { label: "Barrow-in-Furness Borough Council", value: "Barrow-in-Furness Borough Council" },
    { label: "Basildon Borough Council", value: "Basildon Borough Council" },
    { label: "Basingstoke and Deane Borough Council", value: "Basingstoke and Deane Borough Council" },
    { label: "Bassetlaw District Council", value: "Bassetlaw District Council" },
    { label: "Bath and North East Somerset Council", value: "Bath and North East Somerset Council" },
    { label: "Bedford Borough Council", value: "Bedford Borough Council" },
    { label: "Belfast City Council", value: "Belfast City Council" },
    { label: "Birmingham City Council", value: "Birmingham City Council" },
    { label: "Blaby District Council", value: "Blaby District Council" },
    { label: "Blackburn with Darwen Borough Council", value: "Blackburn with Darwen Borough Council" },
    { label: "Blackpool Borough Council", value: "Blackpool Borough Council" },
    { label: "Blaenau Gwent County Borough Council", value: "Blaenau Gwent County Borough Council" },
    { label: "Bolsover District Council", value: "Bolsover District Council" },
    { label: "Bolton Metropolitan Borough Council", value: "Bolton Metropolitan Borough Council" },
    { label: "Borough of Broxbourne", value: "Borough of Broxbourne" },
    { label: "Boston Borough Council", value: "Boston Borough Council" },
    { label: "Bournemouth, Christchurch and Poole Council", value: "Bournemouth, Christchurch and Poole Council" },
    { label: "Bracknell Forest Council", value: "Bracknell Forest Council" },
    { label: "Bradford Metropolitan District Council", value: "Bradford Metropolitan District Council" },
    { label: "Braintree District Council", value: "Braintree District Council" },
    { label: "Breckland District Council", value: "Breckland District Council" },
    { label: "Brentwood Borough Council", value: "Brentwood Borough Council" },
    { label: "Bridgend County Borough Council", value: "Bridgend County Borough Council" },
    { label: "Brighton and Hove City Council", value: "Brighton and Hove City Council" },
    { label: "Bristol City Council", value: "Bristol City Council" },
    { label: "Broadland District Council", value: "Broadland District Council" },
    { label: "Broads Authority", value: "Broads Authority" },
    { label: "Bromsgrove District Council", value: "Bromsgrove District Council" },
    { label: "Broxtowe Borough Council", value: "Broxtowe Borough Council" },
    { label: "Buckinghamshire Council", value: "Buckinghamshire Council" },
    { label: "Burnley Borough Council", value: "Burnley Borough Council" },
    { label: "Bury Metropolitan Borough Council", value: "Bury Metropolitan Borough Council" },
    { label: "Caerphilly County Borough Council", value: "Caerphilly County Borough Council" },
    { label: "Calderdale Metropolitan Borough Council", value: "Calderdale Metropolitan Borough Council" },
    { label: "Cambridge City Council", value: "Cambridge City Council" },
    { label: "Cannock Chase District Council", value: "Cannock Chase District Council" },
    { label: "Canterbury City Council", value: "Canterbury City Council" },
    { label: "Cardiff Council", value: "Cardiff Council" },
    { label: "Carlisle City Council", value: "Carlisle City Council" },
    { label: "Carmarthenshire County Council", value: "Carmarthenshire County Council" },
    { label: "Castle Point Borough Council", value: "Castle Point Borough Council" },
    { label: "Causeway Coast and Glens Borough Council", value: "Causeway Coast and Glens Borough Council" },
    { label: "Central Bedfordshire Council", value: "Central Bedfordshire Council" },
    { label: "Ceredigion County Council", value: "Ceredigion County Council" },
    { label: "Charnwood Borough Council", value: "Charnwood Borough Council" },
    { label: "Chelmsford City Council", value: "Chelmsford City Council" },
    { label: "Cheltenham Borough Council", value: "Cheltenham Borough Council" },
    { label: "Cherwell District Council", value: "Cherwell District Council" },
    { label: "Cheshire East Council", value: "Cheshire East Council" },
    { label: "Cheshire West and Chester Council", value: "Cheshire West and Chester Council" },
    { label: "Chesterfield Borough Council", value: "Chesterfield Borough Council" },
    { label: "Chichester District Council", value: "Chichester District Council" },
    { label: "Chorley Council", value: "Chorley Council" },
    { label: "City and County of Swansea Council", value: "The City and County of Swansea Council" },
    { label: "City of Edinburgh Council", value: "The City of Edinburgh Council" },
    { label: "City of Lincoln Council", value: "The City of Lincoln Council" },
    { label: "City of London", value: "The City of London" },
    { label: "City of York Council", value: "The City of York Council" },
    { label: "Clackmannanshire Council", value: "Clackmannanshire Council" },
    { label: "Colchester Borough Council", value: "Colchester Borough Council" },
    { label: "Comhairle nan Eilean Siar", value: "Comhairle nan Eilean Siar" },
    { label: "Conwy County Borough Council", value: "Conwy County Borough Council" },
    { label: "Copeland Borough Council", value: "Copeland Borough Council" },
    { label: "Cornwall Council", value: "Cornwall Council" },
    { label: "Cotswold District Council", value: "Cotswold District Council" },
    { label: "Coventry City Council", value: "Coventry City Council" },
    { label: "Craven District Council", value: "Craven District Council" },
    { label: "Crawley Borough Council", value: "Crawley Borough Council" },
    { label: "Dacorum Borough Council", value: "Dacorum Borough Council" },
    { label: "Darlington Borough Council", value: "Darlington Borough Council" },
    { label: "Dartford Borough Council", value: "Dartford Borough Council" },
    { label: "Dartmoor National Park", value: "Dartmoor National Park" },
    { label: "Denbighshire County Council", value: "Denbighshire County Council" },
    { label: "Derby City Council", value: "Derby City Council" },
    { label: "Derbyshire Dales District Council", value: "Derbyshire Dales District Council" },
    { label: "Derry City and Strabane District Council", value: "Derry City and Strabane District Council" },
    { label: "Doncaster Metropolitan Borough Council", value: "Doncaster Metropolitan Borough Council" },
    { label: "Dorset Council", value: "Dorset Council" },
    { label: "Dover District Council", value: "Dover District Council" },
    { label: "Dudley Metropolitan Borough Council", value: "Dudley Metropolitan Borough Council" },
    { label: "Dumfries and Galloway Council", value: "Dumfries and Galloway Council" },
    { label: "Dundee City Council", value: "Dundee City Council" },
    { label: "Durham County Council", value: "Durham County Council" },
    { label: "East Ayrshire Council", value: "East Ayrshire Council" },
    { label: "East Cambridgeshire District Council", value: "East Cambridgeshire District Council" },
    { label: "East Devon District Council", value: "East Devon District Council" },
    { label: "East Dunbartonshire Council", value: "East Dunbartonshire Council" },
    { label: "East Hampshire District Council", value: "East Hampshire District Council" },
    { label: "East Hertfordshire District Council", value: "East Hertfordshire District Council" },
    { label: "East Lindsey District Council", value: "East Lindsey District Council" },
    { label: "East Lothian Council", value: "East Lothian Council" },
    { label: "East Renfrewshire Council", value: "East Renfrewshire Council" },
    { label: "East Riding of Yorkshire Council", value: "East Riding of Yorkshire Council" },
    { label: "East Staffordshire Borough Council", value: "East Staffordshire Borough Council" },
    { label: "East Suffolk Council", value: "East Suffolk Council" },
    { label: "Eastbourne Borough Council", value: "Eastbourne Borough Council" },
    { label: "Eastleigh Borough Council", value: "Eastleigh Borough Council" },
    { label: "Ebbsfleet Development Corporation", value: "Ebbsfleet Development Corporation" },
    { label: "Eden District Council", value: "Eden District Council" },
    { label: "Elmbridge Borough Council", value: "Elmbridge Borough Council" },
    { label: "Epping Forest District Council", value: "Epping Forest District Council" },
    { label: "Epsom and Ewell Borough Council", value: "Epsom and Ewell Borough Council" },
    { label: "Erewash Borough Council", value: "Erewash Borough Council" },
    { label: "Exeter City Council", value: "Exeter City Council" },
    { label: "Exmoor National Park", value: "Exmoor National Park" },
    { label: "Falkirk Council", value: "Falkirk Council" },
    { label: "Fareham Borough Council", value: "Fareham Borough Council" },
    { label: "Fenland District Council", value: "Fenland District Council" },
    { label: "Fermanagh and Omagh District Council", value: "Fermanagh and Omagh District Council" },
    { label: "Fife Council", value: "Fife Council" },
    { label: "Flintshire County Council", value: "Flintshire County Council" },
    { label: "Folkestone & Hythe District Council", value: "Folkestone & Hythe District Council" },
    { label: "Forest of Dean District Council", value: "Forest of Dean District Council" },
    { label: "Fylde Borough Council", value: "Fylde Borough Council" },
    { label: "Gateshead Metropolitan Borough Council", value: "Gateshead Metropolitan Borough Council" },
    { label: "Gedling Borough Council", value: "Gedling Borough Council" },
    { label: "Glasgow City Council", value: "Glasgow City Council" },
    { label: "Gloucester City Council", value: "Gloucester City Council" },
    { label: "Gosport Borough Council", value: "Gosport Borough Council" },
    { label: "Gravesham Borough Council", value: "Gravesham Borough Council" },
    { label: "Great Yarmouth Borough Council", value: "Great Yarmouth Borough Council" },
    { label: "Guildford Borough Council", value: "Guildford Borough Council" },
    { label: "Gwynedd Council", value: "Gwynedd Council" },
    { label: "Halton Borough Council", value: "Halton Borough Council" },
    { label: "Hambleton District Council", value: "Hambleton District Council" },
    { label: "Harborough District Council", value: "Harborough District Council" },
    { label: "Harlow Council", value: "Harlow Council" },
    { label: "Harrogate Borough Council", value: "Harrogate Borough Council" },
    { label: "Hart District Council", value: "Hart District Council" },
    { label: "Hartlepool Borough Council", value: "Hartlepool Borough Council" },
    { label: "Hastings Borough Council", value: "Hastings Borough Council" },
    { label: "Havant Borough Council", value: "Havant Borough Council" },
    { label: "Herefordshire Council", value: "Herefordshire Council" },
    { label: "Hertsmere Borough Council", value: "Hertsmere Borough Council" },
    { label: "High Peak Borough Council", value: "High Peak Borough Council" },
    { label: "Hinckley and Bosworth Borough Council", value: "Hinckley and Bosworth Borough Council" },
    { label: "Horsham District Council", value: "Horsham District Council" },
    { label: "Huntingdonshire District Council", value: "Huntingdonshire District Council" },
    { label: "Hyndburn Borough Council", value: "Hyndburn Borough Council" },
    { label: "Inverclyde Council", value: "Inverclyde Council" },
    { label: "Ipswich Borough Council", value: "Ipswich Borough Council" },
    { label: "Isle of Anglesey County Council", value: "The Isle of Anglesey County Council" },
    { label: "Isle of Wight Council", value: "The Isle of Wight Council" },
    { label: "Isles of Scilly", value: "The Isles of Scilly" },
    { label: "King's Lynn and West Norfolk Borough Council", value: "King's Lynn and West Norfolk Borough Council" },
    { label: "Kingston-upon-Hull City Council", value: "Kingston-upon-Hull City Council" },
    { label: "Kirklees Council", value: "Kirklees Council" },
    { label: "Knowsley Metropolitan Borough Council", value: "Knowsley Metropolitan Borough Council" },
    { label: "Lake District National Park", value: "The Lake District National Park" },
    { label: "Lancaster City Council", value: "Lancaster City Council" },
    { label: "Leeds City Council", value: "Leeds City Council" },
    { label: "Leicester City Council", value: "Leicester City Council" },
    { label: "Lewes District Council", value: "Lewes District Council" },
    { label: "Lichfield District Council", value: "Lichfield District Council" },
    { label: "Lisburn and Castlereagh City Council", value: "Lisburn and Castlereagh City Council" },
    { label: "Liverpool City Council", value: "Liverpool City Council" },
    { label: "London Borough of Barking and Dagenham", value: "The London Borough of Barking and Dagenham" },
    { label: "London Borough of Barnet", value: "The London Borough of Barnet" },
    { label: "London Borough of Bexley", value: "The London Borough of Bexley" },
    { label: "London Borough of Brent", value: "The London Borough of Brent" },
    { label: "London Borough of Bromley", value: "The London Borough of Bromley" },
    { label: "London Borough of Camden", value: "The London Borough of Camden" },
    { label: "London Borough of Croydon", value: "The London Borough of Croydon" },
    { label: "London Borough of Ealing", value: "The London Borough of Ealing" },
    { label: "London Borough of Enfield", value: "The London Borough of Enfield" },
    { label: "London Borough of Hackney", value: "The London Borough of Hackney" },
    { label: "London Borough of Hammersmith & Fulham", value: "The London Borough of Hammersmith & Fulham" },
    { label: "London Borough of Haringey", value: "The London Borough of Haringey" },
    { label: "London Borough of Harrow", value: "The London Borough of Harrow" },
    { label: "London Borough of Havering", value: "The London Borough of Havering" },
    { label: "London Borough of Hillingdon", value: "The London Borough of Hillingdon" },
    { label: "London Borough of Hounslow", value: "The London Borough of Hounslow" },
    { label: "London Borough of Islington", value: "The London Borough of Islington" },
    { label: "London Borough of Lambeth", value: "The London Borough of Lambeth" },
    { label: "London Borough of Lewisham", value: "The London Borough of Lewisham" },
    { label: "London Borough of Merton", value: "The London Borough of Merton" },
    { label: "London Borough of Newham", value: "The London Borough of Newham" },
    { label: "London Borough of Redbridge", value: "The London Borough of Redbridge" },
    { label: "London Borough of Richmond upon Thames", value: "The London Borough of Richmond upon Thames" },
    { label: "London Borough of Southwark", value: "The London Borough of Southwark" },
    { label: "London Borough of Sutton", value: "The London Borough of Sutton" },
    { label: "London Borough of Tower Hamlets", value: "The London Borough of Tower Hamlets" },
    { label: "London Borough of Waltham Forest", value: "The London Borough of Waltham Forest" },
    { label: "London Borough of Wandsworth", value: "The London Borough of Wandsworth" },
    { label: "London Legacy Development Corporation", value: "The London Legacy Development Corporation" },
    { label: "Luton Borough Council", value: "Luton Borough Council" },
    { label: "Maidstone Borough Council", value: "Maidstone Borough Council" },
    { label: "Maldon District Council", value: "Maldon District Council" },
    { label: "Malvern Hills District Council", value: "Malvern Hills District Council" },
    { label: "Manchester City Council", value: "Manchester City Council" },
    { label: "Mansfield District Council", value: "Mansfield District Council" },
    { label: "Medway Council", value: "Medway Council" },
    { label: "Melton Borough Council", value: "Melton Borough Council" },
    { label: "Merthyr Tydfil County Borough Council", value: "Merthyr Tydfil County Borough Council" },
    { label: "Mid and East Antrim Borough Council", value: "Mid and East Antrim Borough Council" },
    { label: "Mid Devon District Council", value: "Mid Devon District Council" },
    { label: "Mid Suffolk District Council", value: "Mid Suffolk District Council" },
    { label: "Mid Sussex District Council", value: "Mid Sussex District Council" },
    { label: "Mid Ulster District Council", value: "Mid Ulster District Council" },
    { label: "Middlesbrough Borough Council", value: "Middlesbrough Borough Council" },
    { label: "Midlothian Council", value: "Midlothian Council" },
    { label: "Milton Keynes City Council", value: "Milton Keynes City Council" },
    { label: "Mole Valley District Council", value: "Mole Valley District Council" },
    { label: "Monmouthshire County Council", value: "Monmouthshire County Council" },
    { label: "Neath Port Talbot County Borough Council", value: "Neath Port Talbot County Borough Council" },
    { label: "New Forest District Council", value: "New Forest District Council" },
    { label: "New Forest National Park", value: "The New Forest National Park" },
    { label: "Newark and Sherwood District Council", value: "Newark and Sherwood District Council" },
    { label: "Newcastle-Under-Lyme District Council", value: "Newcastle-Under-Lyme District Council" },
    { label: "Newcastle-upon-Tyne City Council", value: "Newcastle-upon-Tyne City Council" },
    { label: "Newport City Council", value: "Newport City Council" },
    { label: "Newry, Mourne and Down District Council", value: "Newry, Mourne and Down District Council" },
    { label: "North Ayrshire Council", value: "North Ayrshire Council" },
    { label: "North Devon Council", value: "North Devon Council" },
    { label: "North East Derbyshire District Council", value: "North East Derbyshire District Council" },
    { label: "North East Lincolnshire Council", value: "North East Lincolnshire Council" },
    { label: "North Hertfordshire District Council", value: "North Hertfordshire District Council" },
    { label: "North Kesteven District Council", value: "North Kesteven District Council" },
    { label: "North Lanarkshire Council", value: "North Lanarkshire Council" },
    { label: "North Lincolnshire Council", value: "North Lincolnshire Council" },
    { label: "North Norfolk District Council", value: "North Norfolk District Council" },
    { label: "North Northamptonshire Council", value: "North Northamptonshire Council" },
    { label: "North Somerset Council", value: "North Somerset Council" },
    { label: "North Tyneside Metropolitan Borough Council", value: "North Tyneside Metropolitan Borough Council" },
    { label: "North Warwickshire Borough Council", value: "North Warwickshire Borough Council" },
    { label: "North West Leicestershire District Council", value: "North West Leicestershire District Council" },
    { label: "North York Moors National Park", value: "North York Moors National Park" },
    { label: "North Yorkshire Council", value: "North Yorkshire Council" },
    { label: "Northumberland Council", value: "Northumberland Council" },
    { label: "Northumberland National Park", value: "Northumberland National Park" },
    { label: "Norwich City Council", value: "Norwich City Council" },
    { label: "Nottingham City Council", value: "Nottingham City Council" },
    { label: "Nuneaton and Bedworth Borough Council", value: "Nuneaton and Bedworth Borough Council" },
    { label: "Oadby and Wigston District Council", value: "Oadby and Wigston District Council" },
    {
        label: "Old Oak and Park Royal Development Corporation",
        value: "Old Oak and Park Royal Development Corporation",
    },
    { label: "Oldham Metropolitan Borough Council", value: "Oldham Metropolitan Borough Council" },
    { label: "Orkney Islands Council", value: "Orkney Islands Council" },
    { label: "Oxford City Council", value: "Oxford City Council" },
    { label: "Peak District National Park", value: "The Peak District National Park" },
    { label: "Pembrokeshire County Council", value: "Pembrokeshire County Council" },
    { label: "Pendle Borough Council", value: "Pendle Borough Council" },
    { label: "Perth and Kinross Council", value: "Perth and Kinross Council" },
    { label: "Peterborough City Council", value: "Peterborough City Council" },
    { label: "Plymouth City Council", value: "Plymouth City Council" },
    { label: "Portsmouth City Council", value: "Portsmouth City Council" },
    { label: "Powys County Council", value: "Powys County Council" },
    { label: "Preston City Council", value: "Preston City Council" },
    { label: "Reading Borough Council", value: "Reading Borough Council" },
    { label: "Redcar and Cleveland Council", value: "Redcar and Cleveland Council" },
    { label: "Redditch Borough Council", value: "Redditch Borough Council" },
    { label: "Reigate & Banstead Borough Council", value: "Reigate & Banstead Borough Council" },
    { label: "Renfrewshire Council", value: "Renfrewshire Council" },
    { label: "Rhondda Cynon Taf County Borough Council", value: "Rhondda Cynon Taf County Borough Council" },
    { label: "Ribble Valley Borough Council", value: "Ribble Valley Borough Council" },
    { label: "Richmondshire District Council", value: "Richmondshire District Council" },
    { label: "Rochdale Metropolitan Borough Council", value: "Rochdale Metropolitan Borough Council" },
    { label: "Rochford District Council", value: "Rochford District Council" },
    { label: "Rossendale Borough Council", value: "Rossendale Borough Council" },
    { label: "Rother District Council", value: "Rother District Council" },
    { label: "Rotherham Metropolitan Borough Council", value: "Rotherham Metropolitan Borough Council" },
    { label: "Royal Borough of Greenwich", value: "The Royal Borough of Greenwich" },
    { label: "Royal Borough of Kensington and Chelsea", value: "The Royal Borough of Kensington and Chelsea" },
    { label: "Royal Borough of Kingston upon Thames", value: "The Royal Borough of Kingston upon Thames" },
    { label: "Royal Borough of Windsor and Maidenhead", value: "The Royal Borough of Windsor and Maidenhead" },
    { label: "Rugby Borough Council", value: "Rugby Borough Council" },
    { label: "Runnymede Borough Council", value: "Runnymede Borough Council" },
    { label: "Rushcliffe Borough Council", value: "Rushcliffe Borough Council" },
    { label: "Rushmoor Borough Council", value: "Rushmoor Borough Council" },
    { label: "Rutland County Council", value: "Rutland County Council" },
    { label: "Ryedale District Council", value: "Ryedale District Council" },
    { label: "Salford City Council", value: "Salford City Council" },
    { label: "Sandwell Metropolitan Borough Council", value: "Sandwell Metropolitan Borough Council" },
    { label: "Scarborough Borough Council", value: "Scarborough Borough Council" },
    { label: "Scottish Borders Council", value: "Scottish Borders Council" },
    { label: "Sefton Metropolitan Borough Council", value: "Sefton Metropolitan Borough Council" },
    { label: "Selby District Council", value: "Selby District Council" },
    { label: "Sevenoaks District Council", value: "Sevenoaks District Council" },
    { label: "Sheffield City Council", value: "Sheffield City Council" },
    { label: "Shetland Islands Council", value: "Shetland Islands Council" },
    { label: "Shropshire Council", value: "Shropshire Council" },
    { label: "Slough Borough Council", value: "Slough Borough Council" },
    { label: "Solihull Metropolitan Borough Council", value: "Solihull Metropolitan Borough Council" },
    { label: "Somerset Council", value: "Somerset Council" },
    { label: "Somerset West and Taunton Council", value: "Somerset West and Taunton Council" },
    { label: "South Ayrshire Council", value: "South Ayrshire Council" },
    { label: "South Cambridgeshire District Council", value: "South Cambridgeshire District Council" },
    { label: "South Derbyshire District Council", value: "South Derbyshire District Council" },
    { label: "South Downs National Park", value: "South Downs National Park" },
    { label: "South Gloucestershire Council", value: "South Gloucestershire Council" },
    { label: "South Hams District Council", value: "South Hams District Council" },
    { label: "South Holland District Council", value: "South Holland District Council" },
    { label: "South Kesteven District Council", value: "South Kesteven District Council" },
    { label: "South Lakeland District Council", value: "South Lakeland District Council" },
    { label: "South Lanarkshire Council", value: "South Lanarkshire Council" },
    { label: "South Norfolk District Council", value: "South Norfolk District Council" },
    { label: "South Oxfordshire District Council", value: "South Oxfordshire District Council" },
    { label: "South Ribble Borough Council", value: "South Ribble Borough Council" },
    { label: "South Staffordshire Council", value: "South Staffordshire Council" },
    { label: "South Tyneside Council", value: "South Tyneside Council" },
    { label: "Southampton City Council", value: "Southampton City Council" },
    { label: "Southend-on-Sea Borough Council", value: "Southend-on-Sea Borough Council" },
    { label: "Spelthorne Borough Council", value: "Spelthorne Borough Council" },
    { label: "St Albans City and District Council", value: "St Albans City and District Council" },
    { label: "St Helens Metropolitan Borough Council", value: "St Helens Metropolitan Borough Council" },
    { label: "Stafford Borough Council", value: "Stafford Borough Council" },
    { label: "Staffordshire Moorlands District Council", value: "Staffordshire Moorlands District Council" },
    { label: "Stevenage Borough Council", value: "Stevenage Borough Council" },
    { label: "Stirling Council", value: "Stirling Council" },
    { label: "Stockport Metropolitan Borough Council", value: "Stockport Metropolitan Borough Council" },
    { label: "Stockton-on-Tees Borough Council", value: "Stockton-on-Tees Borough Council" },
    { label: "Stoke-on-Trent City Council", value: "Stoke-on-Trent City Council" },
    { label: "Stratford-on-Avon District Council", value: "Stratford-on-Avon District Council" },
    { label: "Stroud District Council", value: "Stroud District Council" },
    { label: "Sunderland City Council", value: "Sunderland City Council" },
    { label: "Surrey Heath Borough Council", value: "Surrey Heath Borough Council" },
    { label: "Swale Borough Council", value: "Swale Borough Council" },
    { label: "Swindon Borough Council", value: "Swindon Borough Council" },
    { label: "Tameside Metropolitan Borough Council", value: "Tameside Metropolitan Borough Council" },
    { label: "Tamworth Borough Council", value: "Tamworth Borough Council" },
    { label: "Tandridge District Council", value: "Tandridge District Council" },
    { label: "Teignbridge District Council", value: "Teignbridge District Council" },
    { label: "Telford & Wrekin Council", value: "Telford & Wrekin Council" },
    { label: "Tendring District Council", value: "Tendring District Council" },
    { label: "Test Valley Borough Council", value: "Test Valley Borough Council" },
    { label: "Tewkesbury Borough Council", value: "Tewkesbury Borough Council" },
    { label: "Thanet District Council", value: "Thanet District Council" },
    { label: "The Highland Council", value: "The Highland Council" },
    { label: "The Moray Council", value: "The Moray Council" },
    { label: "The Vale of Glamorgan County Borough Council", value: "The Vale of Glamorgan County Borough Council" },
    { label: "Three Rivers District Council", value: "Three Rivers District Council" },
    { label: "Thurrock Council", value: "Thurrock Council" },
    { label: "Tonbridge and Malling Borough Council", value: "Tonbridge and Malling Borough Council" },
    { label: "Torbay Council", value: "Torbay Council" },
    { label: "Torfaen County Borough Council", value: "Torfaen County Borough Council" },
    { label: "Torridge District Council", value: "Torridge District Council" },
    { label: "Trafford Metropolitan Borough Council", value: "Trafford Metropolitan Borough Council" },
    { label: "Tunbridge Wells Borough Council", value: "Tunbridge Wells Borough Council" },
    { label: "Uttlesford District Council", value: "Uttlesford District Council" },
    { label: "Vale of White Horse District Council", value: "The Vale of White Horse District Council" },
    { label: "Wakefield Metropolitan District Council", value: "Wakefield Metropolitan District Council" },
    { label: "Walsall Metropolitan Borough Council", value: "Walsall Metropolitan Borough Council" },
    { label: "Warrington Borough Council", value: "Warrington Borough Council" },
    { label: "Warwick District Council", value: "Warwick District Council" },
    { label: "Watford Borough Council", value: "Watford Borough Council" },
    { label: "Waverley Borough Council", value: "Waverley Borough Council" },
    { label: "Wealden District Council", value: "Wealden District Council" },
    { label: "Welwyn Hatfield Council", value: "Welwyn Hatfield Council" },
    { label: "West Berkshire Council", value: "West Berkshire Council" },
    { label: "West Devon Borough Council", value: "West Devon Borough Council" },
    { label: "West Dunbartonshire Council", value: "West Dunbartonshire Council" },
    { label: "West Lancashire Borough Council", value: "West Lancashire Borough Council" },
    { label: "West Lindsey District Council", value: "West Lindsey District Council" },
    { label: "West Lothian Council", value: "West Lothian Council" },
    { label: "West Northamptonshire Council", value: "West Northamptonshire Council" },
    { label: "West Oxfordshire District Council", value: "West Oxfordshire District Council" },
    { label: "West Suffolk Council", value: "West Suffolk Council" },
    { label: "Westminster City Council", value: "Westminster City Council" },
    { label: "Wigan Metropolitan Borough Council", value: "Wigan Metropolitan Borough Council" },
    { label: "Wiltshire Council", value: "Wiltshire Council" },
    { label: "Winchester City Council", value: "Winchester City Council" },
    { label: "Wirral Council", value: "Wirral Council" },
    { label: "Woking Borough Council", value: "Woking Borough Council" },
    { label: "Wokingham Borough Council", value: "Wokingham Borough Council" },
    { label: "Wolverhampton City Council", value: "Wolverhampton City Council" },
    { label: "Worcester City Council", value: "Worcester City Council" },
    { label: "Worthing Borough Council", value: "Worthing Borough Council" },
    { label: "Wrexham County Borough Council", value: "Wrexham County Borough Council" },
    { label: "Wychavon District Council", value: "Wychavon District Council" },
    { label: "Wyre Council", value: "Wyre Council" },
    { label: "Wyre Forest District Council", value: "Wyre Forest District Council" },
    { label: "Yorkshire Dales National Park", value: "The Yorkshire Dales National Park" },
];

export const typeOfPlanningApplicationOptions = [
    { label: "Full planning consent", value: "a full planning application" },
    { label: "Outline planning consent", value: "an outline planning application" },
    { label: "Hybrid planning consent", value: "a hybrid planning application" },
    { label: "Reserved matters", value: "a reserved matters application" },
    { label: "Removal/variation of conditions", value: "a removal / variation of conditions" },
    { label: "Approval (Discharge) of conditions", value: "an approval (discharge) of conditions" },
    {
        label: "Non-material amendment of an existing planning permission",
        value: "a non-material amendment of an existing planning application",
    },
];

export const cyclingWalkingMeasuresOptions = [
    "Cycling facilities e.g. changing facilities, showers",
    "On site safe cycling routes",
    "Financial contribution to connecting off site safe cycling routes",
    "On site safe pedestrian access routes",
    "Financial contribution to connecting off site safe pedestrian routes",
    "Additional cycling and walking signage / wayfinding",
    "Other",
];

export const publicTransportMeasuresOptions = [
    "Provision of bus stop",
    "Provision of lay-by",
    "Financial contribution to other public transport schemes",
    "Other",
];

export const carMeasuresOptions = [
    "None",
    "Limited hours of operation",
    "Parking management measures (e.g. priority parking with children, or multiple occupants)",
    "Dedicated disabled parking space(s)",
    "Additional road signage",
    "Other",
];

export const deliveryAccessMeasuresOptions = [
    "Hours of operation",
    "On site safe cycling routes",
    "Dedicated delivery area",
    "Lorry / van parking",
    "Turning spaces",
    "Other",
];

export const highwayImprovementsOptions = [
    "Visibility splays",
    "Junction widening",
    "New pedestrian crossing",
    "New cycling lanes",
    "Dropped kerbs",
    "Traffic signal upgrades",
    "Highway resurfacing",
    "New roundabout",
    "Bus stop improvements",
    "Pavement widening",
    "Traffic calming (e.g. speed bumps)",
    "Other",
];

export const parkingIsFreeOptions = ["Free", "Paid", "Both, options for different users", "No car parking"];

// Mapping of schemeflow modules (as defined in checklist tasks) to the map components that should be used for pregeneration
// Usually the same as mapKey, but not always (e.g. rail_services and bus_services)
export const pregenerationMapComponents = {
    site_map: {
        mapKey: "site_map",
        component: {
            component: sitemapComponent,
            props: {
                suppressErrors: true,
            },
        },
        loadingText: "Generating site map...",
    },
    regional_site_map: {
        mapKey: "regional_site_map",
        component: {
            component: regionalSiteMapComponent,
            props: {
                suppressErrors: true,
            },
        },
        loadingText: "Generating regional map...",
    },
    satellite_map: {
        mapKey: "satellite_map",
        component: {
            component: satelliteMapComponent,
            props: {
                suppressErrors: true,
            },
        },
        loadingText: "Generating satellite map...",
    },
    bus_services: {
        mapKey: "bus_stops_map",
        component: {
            component: busServicesMapComponent,
            props: {
                suppressErrors: true,
            },
        },
        loadingText: "Generating bus stops map...",
    },
    rail_services: {
        mapKey: "rail_stations_map",
        component: {
            component: railStationsMapComponent,
            props: {
                suppressErrors: true,
            },
        },
        loadingText: "Generating rail stations map...",
    },
    highways_map: {
        mapKey: "highways_map",
        component: {
            component: highwaysMapComponent,
            props: {
                suppressErrors: true,
            },
        },
        loadingText: "Generating highways map...",
    },
    pedestrian_map: {
        mapKey: "pedestrian_map",
        component: {
            component: pedestrianMapComponent,
            props: {
                suppressErrors: true,
            },
        },
        loadingText: "Generating pedestrian access map...",
    },
    driving_map: {
        mapKey: "driving_map",
        component: {
            component: drivingMapComponent,
            props: {
                suppressErrors: true,
            },
        },
        loadingText: "Generating driving access map...",
    },
    collision_map: {
        mapKey: "collision_map",
        component: {
            component: collisionMapComponent,
            props: {
                suppressErrors: true,
            },
        },
        loadingText: "Generating collision map...",
    },
    public_transport_isochrone_map: {
        mapKey: "public_transport_isochrone_map",
        component: {
            component: publicTransportIsochroneMapComponent,
            props: {
                suppressErrors: true,
            },
        },
        loadingText: "Generating Public Transport Isochrone Map...",
    },
    ...Object.entries(amenitiesMaps).reduce((acc, [mapKey, mapConfig]) => {
        acc[mapKey] = {
            mapKey: mapKey,
            component: {
                component: amenitiesMapComponent,
                props: {
                    mapKey: mapKey,
                    amenities: mapConfig.amenities,
                    suppressErrors: true,
                },
            },
            loadingText: `Generating ${mapConfig.name}...`,
        };
        return acc;
    }, {}),
};

export const stateOptions = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
    "District of Columbia",
];

export const ukSiteUseOptions = [
    {
        label: "Boat Building - B2",
        value: "boat building",
    },
    {
        label: "Car Repair Shop - B2",
        value: "car repair shop",
    },
    {
        label: "Catering Premises & Production - B2",
        value: "catering premises & production",
    },
    {
        label: "Civic Amenity Site - B2",
        value: "civic amenity site",
    },
    {
        label: "Factory - B2",
        value: "factory",
    },
    {
        label: "Film Studio - B2",
        value: "film studio",
    },
    {
        label: "General Industrial - B2",
        value: "general industrial",
    },
    {
        label: "Industrial Process - B2",
        value: "industrial process",
    },
    {
        label: "Motorcycle Repair Shop - B2",
        value: "motorcycle repair shop",
    },
    {
        label: "Movie Studio - B2",
        value: "movie studio",
    },
    {
        label: "Quarry - B2",
        value: "quarry",
    },
    {
        label: "Yacht Building - B2",
        value: "yacht building",
    },
    {
        label: "Catering Storage & Distribution - B8",
        value: "catering storage & distribution",
    },
    {
        label: "Distribution Centres - B8",
        value: "distribution centres",
    },
    {
        label: "Open Air Storage - B8",
        value: "open air storage",
    },
    {
        label: "Parcel Distribution Centre - B8",
        value: "parcel distribution centre",
    },
    {
        label: "Storage Building - B8",
        value: "storage building",
    },
    {
        label: "Warehouse - B8",
        value: "warehouse",
    },
    {
        label: "Warehousing (Commercial) - B8",
        value: "warehousing (commercial)",
    },
    {
        label: "Warehousing (Self Storage) - B8",
        value: "warehousing (self storage)",
    },
    {
        label: "Aparthotel - C1",
        value: "aparthotel",
    },
    {
        label: "Bed & Breakfast - C1",
        value: "bed & breakfast",
    },
    {
        label: "Boarding House - C1",
        value: "boarding house",
    },
    {
        label: "Guest House - C1",
        value: "guest house",
    },
    {
        label: "Hotel - C1",
        value: "hotel",
    },
    {
        label: "Serviced Accommodation - C1 / C3 / Sui Generis",
        value: "serviced accommodation",
    },
    {
        label: "Short Term Holiday Let - C1 / C3 / Sui Generis",
        value: "short term holiday let",
    },
    {
        label: "Boarding School - C2",
        value: "boarding school",
    },
    {
        label: "Care Home (Elderly Residential) - C2",
        value: "care home (elderly residential)",
    },
    {
        label: "Care Home (Specific Condition) - C2",
        value: "care home (specific condition)",
    },
    {
        label: "Care Homes - C2",
        value: "care homes",
    },
    {
        label: "College (Residential) - C2",
        value: "college (residential)",
    },
    {
        label: "Extra Care Housing - C2",
        value: "extra care housing",
    },
    {
        label: "General Hospital (with A&E) - C2",
        value: "general hospital (with A&E)",
    },
    {
        label: "General Hospital (without A&E) - C2",
        value: "general hospital (without A&E)",
    },
    {
        label: "Hospice - C2",
        value: "hospice",
    },
    {
        label: "Hospital - C2",
        value: "hospital",
    },
    {
        label: "Nursing Home - C2",
        value: "nursing home",
    },
    {
        label: "Private Hospital - C2",
        value: "private hospital",
    },
    {
        label: "Residential Care Home - C2",
        value: "residential care home",
    },
    {
        label: "Residential School - C2",
        value: "residential school",
    },
    {
        label: "Special Hospital - C2",
        value: "special hospital",
    },
    {
        label: "Teaching Hospital - C2",
        value: "teaching hospital",
    },
    {
        label: "Training Centre - C2",
        value: "training centre",
    },
    {
        label: "Custody Centre - C2A",
        value: "custody centre",
    },
    {
        label: "Detention Centre - C2A",
        value: "detention centre",
    },
    {
        label: "Military Barracks - C2A",
        value: "military barracks",
    },
    {
        label: "Naval Barracks - C2A",
        value: "naval barracks",
    },
    {
        label: "Prison - C2A",
        value: "prison",
    },
    {
        label: "Secure Hospital - C2A",
        value: "secure hospital",
    },
    {
        label: "Secure Local Authority Accommodation - C2A",
        value: "secure local authority accommodation",
    },
    {
        label: "Secure Training Centre - C2A",
        value: "secure training centre",
    },
    {
        label: "Short Term Holding Centre - C2A",
        value: "short term holding centre",
    },
    {
        label: "Young Offenders’ Institution - C2A",
        value: "young offenders’ institution",
    },
    {
        label: "Dwellinghouses - C3",
        value: "dwellinghouses",
    },
    {
        label: "Residential - C3",
        value: "residential",
    },
    {
        label: "Affordable Flats - C3(a)",
        value: "affordable flats",
    },
    {
        label: "Affordable Houses - C3(a)",
        value: "affordable houses",
    },
    {
        label: "Apartments - C3(a)",
        value: "apartments",
    },
    {
        label: "Bungalow - C3(a)",
        value: "bungalow",
    },
    {
        label: "Flats - C3(a)",
        value: "flats",
    },
    {
        label: "Houses - C3(a)",
        value: "houses",
    },
    {
        label: "Local Authority Flats - C3(a)",
        value: "local authority flats",
    },
    {
        label: "Local Authority Houses - C3(a)",
        value: "local authority houses",
    },
    {
        label: "Maisonettes - C3(a)",
        value: "maisonettes",
    },
    {
        label: "Mixed Affordable Housing - C3(a)",
        value: "mixed affordable housing",
    },
    {
        label: "Mixed Private / Affordable Housing - C3(a)",
        value: "mixed private / affordable housing",
    },
    {
        label: "Mixed Private Housing - C3(a)",
        value: "mixed private housing",
    },
    {
        label: "Retirement Flats - C3(a)",
        value: "retirement flats",
    },
    {
        label: "Assisted Living - C3(b)",
        value: "assisted living",
    },
    {
        label: "Care Houses - C3(b)",
        value: "care houses",
    },
    {
        label: "Sheltered Accommodation - C3(b)",
        value: "sheltered accommodation",
    },
    {
        label: "Sheltered Housing - C3(b)",
        value: "sheltered housing",
    },
    {
        label: "Supported Housing with Care - C3(b)",
        value: "supported housing with care",
    },
    {
        label: "Houses (up to 6 people) - C3(c)",
        value: "houses (up to 6 people)",
    },
    {
        label: "Houses (with lodger) - C3(c)",
        value: "houses (with lodger)",
    },
    {
        label: "Bedsit - C4",
        value: "bedsit",
    },
    {
        label: "HMO - C4",
        value: "HMO",
    },
    {
        label: "Barber Shop - E(a)",
        value: "barber shop",
    },
    {
        label: "Beauty Salon - E(a)",
        value: "beauty salon",
    },
    {
        label: "Bookshop - E(a)",
        value: "bookshop",
    },
    {
        label: "Builders Merchants - E(a)",
        value: "builders merchants",
    },
    {
        label: "Butcher - E(a)",
        value: "butcher",
    },
    {
        label: "Car Service Shop - E(a)",
        value: "car service shop",
    },
    {
        label: "Chemist - E(a)",
        value: "chemist",
    },
    {
        label: "Clothes Shop - E(a)",
        value: "clothes shop",
    },
    {
        label: "Cold Food Shop - E(a)",
        value: "cold food shop",
    },
    {
        label: "Convenience Store - E(a)",
        value: "convenience store",
    },
    {
        label: "Discount Food Store - E(a)",
        value: "discount food store",
    },
    {
        label: "DIY Superstore (with Garden Centre) - E(a)",
        value: "DIY superstore (with garden centre)",
    },
    {
        label: "DIY Superstore (without Garden Centre) - E(a)",
        value: "DIY superstore (without garden centre)",
    },
    {
        label: "Dog Grooming Parlour - E(a)",
        value: "dog grooming parlour",
    },
    {
        label: "Dry Cleaners - E(a)",
        value: "dry cleaners",
    },
    {
        label: "Factory Outlet Centre - E(a)",
        value: "factory outlet centre",
    },
    {
        label: "Factory Shop - E(a)",
        value: "factory shop",
    },
    {
        label: "Food Superstore - E(a)",
        value: "food superstore",
    },
    {
        label: "Funeral Director - E(a)",
        value: "funeral director",
    },
    {
        label: "Garden Centre - E(a)",
        value: "garden centre",
    },
    {
        label: "Greengrocer - E(a)",
        value: "greengrocer",
    },
    {
        label: "Hairdresser - E(a)",
        value: "hairdresser",
    },
    {
        label: "Hire Shop - E(a)",
        value: "hire shop",
    },
    {
        label: "Massage Parlour - E(a)",
        value: "massage parlour",
    },
    {
        label: "Mixed Bargain Retail Unit - E(a)",
        value: "mixed bargain retail unit",
    },
    {
        label: "MOT Centre - E(a)",
        value: "MOT centre",
    },
    {
        label: "Motorist DIY - E(a)",
        value: "motorist DIY",
    },
    {
        label: "Nail Bar - E(a)",
        value: "nail bar",
    },
    {
        label: "Non-Food Superstore - E(a)",
        value: "non-food superstore",
    },
    {
        label: "Pet Shops - E(a)",
        value: "pet shops",
    },
    {
        label: "Pharmacy - E(a)",
        value: "pharmacy",
    },
    {
        label: "Phone Shop - E(a)",
        value: "phone shop",
    },
    {
        label: "Post Office - E(a)",
        value: "post office",
    },
    {
        label: "Printers - E(a)",
        value: "printers",
    },
    {
        label: "Retail - E(a)",
        value: "retail",
    },
    {
        label: "Retail Park (excluding food) - E(a)",
        value: "retail park (excluding food)",
    },
    {
        label: "Retail Park (including food) - E(a)",
        value: "retail park (including food)",
    },
    {
        label: "Retail Warehouse - E(a)",
        value: "retail warehouse",
    },
    {
        label: "Saddlery - E(a)",
        value: "saddlery",
    },
    {
        label: "Sandwich Shop - E(a)",
        value: "sandwich shop",
    },
    {
        label: "Shoe Shop - E(a)",
        value: "shoe shop",
    },
    {
        label: "Shop - E(a)",
        value: "shop",
    },
    {
        label: "Shopping Centre - E(a)",
        value: "shopping centre",
    },
    {
        label: "Sunbed Salon - E(a)",
        value: "sunbed salon",
    },
    {
        label: "Tanning Salon - E(a)",
        value: "tanning salon",
    },
    {
        label: "Tattoo Parlour - E(a)",
        value: "tattoo parlour",
    },
    {
        label: "Ticket Agency - E(a)",
        value: "ticket agency",
    },
    {
        label: "Tourist Information - E(a)",
        value: "tourist information",
    },
    {
        label: "Trade Shop - E(a)",
        value: "trade shop",
    },
    {
        label: "Travel Agents - E(a)",
        value: "travel agents",
    },
    {
        label: "Café - E(b)",
        value: "café",
    },
    {
        label: "Internet Cafe - E(b)",
        value: "internet cafe",
    },
    {
        label: "Restaurant - E(b)",
        value: "restaurant",
    },
    {
        label: "Road-Side Food - E(b)",
        value: "road-side food",
    },
    {
        label: "Bank - E(c)(i)",
        value: "bank",
    },
    {
        label: "Building Society - E(c)(i)",
        value: "building society",
    },
    {
        label: "Employment Agency - E(c)(i)",
        value: "employment agency",
    },
    {
        label: "Estate Agency - E(c)(i)",
        value: "estate agency",
    },
    {
        label: "Bowling Alley - E(c)(ii)",
        value: "bowling alley",
    },
    {
        label: "Professional Services - E(c)(ii)",
        value: "professional services",
    },
    {
        label: "Fitness Club - E(d)",
        value: "fitness club",
    },
    {
        label: "Gym - E(d)",
        value: "gym",
    },
    {
        label: "Indoor Sport & Recreation - E(d)",
        value: "indoor sport & recreation",
    },
    {
        label: "Leisure Centre - E(d)",
        value: "leisure centre",
    },
    {
        label: "Leisure Park - E(d)",
        value: "leisure park",
    },
    {
        label: "Play Centre - E(d)",
        value: "play centre",
    },
    {
        label: "Snooker Hall - E(d)",
        value: "snooker hall",
    },
    {
        label: "Tennis Club (Indoor) - E(d)",
        value: "tennis club (indoor)",
    },
    {
        label: "Trampoline Park - E(d)",
        value: "trampoline park",
    },
    {
        label: "Clinic - E(e)",
        value: "clinic",
    },
    {
        label: "Dental Surgery - E(e)",
        value: "dental surgery",
    },
    {
        label: "Dentist - E(e)",
        value: "dentist",
    },
    {
        label: "Doctor - E(e)",
        value: "doctor",
    },
    {
        label: "GP - E(e)",
        value: "GP",
    },
    {
        label: "GP Surgery - E(e)",
        value: "GP surgery",
    },
    {
        label: "Health Centre - E(e)",
        value: "health centre",
    },
    {
        label: "NHS Walk-In Centre - E(e)",
        value: "NHS walk-in centre",
    },
    {
        label: "Osteopath - E(e)",
        value: "osteopath",
    },
    {
        label: "Veterinary Practice (Vet) - E(e)",
        value: "veterinary practice (vet)",
    },
    {
        label: "Veterinary Surgery - E(e)",
        value: "veterinary surgery",
    },
    {
        label: "Crèche - E(f)",
        value: "crèche",
    },
    {
        label: "Day Centre - E(f)",
        value: "day centre",
    },
    {
        label: "Day Nursery - E(f)",
        value: "day nursery",
    },
    {
        label: "Nursery - E(f)",
        value: "nursery",
    },
    {
        label: "Artist Studio - E(g)(i)",
        value: "artist studio",
    },
    {
        label: "Job Centre - E(g)(i)",
        value: "job centre",
    },
    {
        label: "Music Studio - E(g)(i)",
        value: "music studio",
    },
    {
        label: "Non-Retail Photographic Studio - E(g)(i)",
        value: "non-retail photographic studio",
    },
    {
        label: "Offices - E(g)(i)",
        value: "offices",
    },
    {
        label: "Photographic Studio - E(g)(i)",
        value: "photographic studio",
    },
    {
        label: "Police Office (not a station) - E(g)(i)",
        value: "police office (not a station)",
    },
    {
        label: "Recording Studio - E(g)(i)",
        value: "recording studio",
    },
    {
        label: "Solicitors - E(g)(i)",
        value: "solicitors",
    },
    {
        label: "Research & Development - E(g)(ii)",
        value: "research & development",
    },
    {
        label: "DIY Shed - E(g)(iii)",
        value: "DIY shed",
    },
    {
        label: "Industrial Process (in residential area) - E(g)(iii)",
        value: "industrial process (in residential area)",
    },
    {
        label: "Light Industrial - E(g)(iii)",
        value: "light industrial",
    },
    {
        label: "College - F1(a)",
        value: "college",
    },
    {
        label: "Community Education - F1(a)",
        value: "community education",
    },
    {
        label: "Education Facility - F1(a)",
        value: "education facility",
    },
    {
        label: "Non-Residential Education and Training Centre - F1(a)",
        value: "non-residential education and training centre",
    },
    {
        label: "Primary School - F1(a)",
        value: "primary school",
    },
    {
        label: "School - F1(a)",
        value: "school",
    },
    {
        label: "Secondary School - F1(a)",
        value: "secondary school",
    },
    {
        label: "Training Company - F1(a)",
        value: "training company",
    },
    {
        label: "University - F1(a)",
        value: "university",
    },
    {
        label: "Art Gallery - F1(b)",
        value: "art gallery",
    },
    {
        label: "Museum - F1(c)",
        value: "museum",
    },
    {
        label: "Library - F1(d)",
        value: "library",
    },
    {
        label: "Public Library - F1(d)",
        value: "public library",
    },
    {
        label: "Public Reading Room - F1(d)",
        value: "public reading room",
    },
    {
        label: "Exhibition Centre - F1(e)",
        value: "exhibition centre",
    },
    {
        label: "Exhibition Hall - F1(e)",
        value: "exhibition hall",
    },
    {
        label: "Public Hall - F1(e)",
        value: "public hall",
    },
    {
        label: "Church - F1(f)",
        value: "church",
    },
    {
        label: "Church Hall - F1(f)",
        value: "church hall",
    },
    {
        label: "Monastery - F1(f)",
        value: "monastery",
    },
    {
        label: "Mosque - F1(f)",
        value: "mosque",
    },
    {
        label: "Place of Worship - F1(f)",
        value: "place of worship",
    },
    {
        label: "Religious Instruction - F1(f)",
        value: "religious instruction",
    },
    {
        label: "Synagogue - F1(f)",
        value: "synagogue",
    },
    {
        label: "Law Court - F1(g)",
        value: "law court",
    },
    {
        label: "Community Centre - F2(a)",
        value: "community centre",
    },
    {
        label: "Local Shop - F2(a)",
        value: "local shop",
    },
    {
        label: "Local Hall - F2(b)",
        value: "local hall",
    },
    {
        label: "Meeting Hall - F2(b)",
        value: "meeting hall",
    },
    {
        label: "Scout Hut - F2(b)",
        value: "scout hut",
    },
    {
        label: "3 Par Golf Course - F2(c)",
        value: "3 par golf course",
    },
    {
        label: "Country Park - F2(c)",
        value: "country park",
    },
    {
        label: "Cricket Pitch - F2(c)",
        value: "cricket pitch",
    },
    {
        label: "Driving Range - F2(c)",
        value: "driving range",
    },
    {
        label: "Equestrian Centre  - F2(c)",
        value: "equestrian centre",
    },
    {
        label: "Football Pitch - F2(c)",
        value: "football pitch",
    },
    {
        label: "Football Pitch (5-a-Side) - F2(c)",
        value: "football pitch (5-a-side)",
    },
    {
        label: "Golf Course - F2(c)",
        value: "golf course",
    },
    {
        label: "Municipal >18 Hole Golf Course - F2(c)",
        value: "municipal >18 hole golf course",
    },
    {
        label: "Municipal 18 Hole Golf Course - F2(c)",
        value: "municipal 18 hole golf course",
    },
    {
        label: "Outdoor Sports - F2(c)",
        value: "outdoor sports",
    },
    {
        label: "Petting Farm - F2(c)",
        value: "petting farm",
    },
    {
        label: "Polo Ground - F2(c)",
        value: "polo ground",
    },
    {
        label: "Private >18 Hole Golf Course - F2(c)",
        value: "private >18 hole golf course",
    },
    {
        label: "Private 18 Hole Golf Course - F2(c)",
        value: "private 18 hole golf course",
    },
    {
        label: "Private 9 Hole Golf Course - F2(c)",
        value: "private 9 hole golf course",
    },
    {
        label: "Sports Arena - F2(c)",
        value: "sports arena",
    },
    {
        label: "Tennis Club  - F2(c)",
        value: "tennis club",
    },
    {
        label: "Tennis Courts - F2(c)",
        value: "tennis courts",
    },
    {
        label: "Watersports Centre - F2(c)",
        value: "watersports centre",
    },
    {
        label: "Ice Rink - F2(d)",
        value: "ice rink",
    },
    {
        label: "Leisure Centre (Swimming Pool Dominant) - F2(d)",
        value: "leisure centre (swimming pool dominant)",
    },
    {
        label: "Skating Rink - F2(d)",
        value: "skating rink",
    },
    {
        label: "Ski Slope - F2(d)",
        value: "ski slope",
    },
    {
        label: "Swimming Pool - F2(d)",
        value: "swimming pool",
    },
    {
        label: "Adult Entertainment Venue - Sui Generis",
        value: "adult entertainment venue",
    },
    {
        label: "Agricultural Unit - Sui Generis",
        value: "agricultural unit",
    },
    {
        label: "Alkali Work - Sui Generis",
        value: "alkali work",
    },
    {
        label: "Amusement Arcade - Sui Generis",
        value: "amusement arcade",
    },
    {
        label: "Amusement Centre - Sui Generis",
        value: "amusement centre",
    },
    {
        label: "Barn - Sui Generis",
        value: "barn",
    },
    {
        label: "Betting Shop - Sui Generis",
        value: "betting shop",
    },
    {
        label: "Bingo Hall - Sui Generis",
        value: "bingo hall",
    },
    {
        label: "Boarding Kennel - Sui Generis",
        value: "boarding kennel",
    },
    {
        label: "Builders Yard - Sui Generis",
        value: "builders yard",
    },
    {
        label: "Cab Company - Sui Generis",
        value: "cab company",
    },
    {
        label: "Car Hire - Sui Generis",
        value: "car hire",
    },
    {
        label: "Car Hire Centre - Sui Generis",
        value: "car hire centre",
    },
    {
        label: "Car Racing - Sui Generis",
        value: "car racing",
    },
    {
        label: "Car Rental - Sui Generis",
        value: "car rental",
    },
    {
        label: "Car Showroom - Sui Generis",
        value: "car showroom",
    },
    {
        label: "Caravan Site - Sui Generis",
        value: "caravan site",
    },
    {
        label: "Cash & Carry - Sui Generis",
        value: "cash & carry",
    },
    {
        label: "Casino - Sui Generis",
        value: "casino",
    },
    {
        label: "Cattery - Sui Generis",
        value: "cattery",
    },
    {
        label: "Chemical Treatment - Sui Generis",
        value: "chemical treatment",
    },
    {
        label: "Cinema - Sui Generis",
        value: "cinema",
    },
    {
        label: "Concert Hall - Sui Generis",
        value: "concert hall",
    },
    {
        label: "Dairy Farm - Sui Generis",
        value: "dairy farm",
    },
    {
        label: "Dance Hall - Sui Generis",
        value: "dance hall",
    },
    {
        label: "Drinking Establishments with Expanded Food Provision - Sui Generis",
        value: "drinking establishments with expanded food provision",
    },
    {
        label: "Electric Vehicle Charging Station - Sui Generis",
        value: "electric vehicle charging station",
    },
    {
        label: "Equestrian - Sui Generis",
        value: "equestrian",
    },
    {
        label: "Farm - Sui Generis",
        value: "farm",
    },
    {
        label: "Fast Food Takeaway - Sui Generis",
        value: "fast food takeaway",
    },
    {
        label: "Firearms Sports - Sui Generis",
        value: "firearms sports",
    },
    {
        label: "Fruit Growing - Sui Generis",
        value: "fruit growing",
    },
    {
        label: "Fuel Station - Sui Generis",
        value: "fuel station",
    },
    {
        label: "Funfair - Sui Generis",
        value: "funfair",
    },
    {
        label: "Garage (Fuel) - Sui Generis",
        value: "garage (fuel)",
    },
    {
        label: "Haulage Depot - Sui Generis",
        value: "haulage depot",
    },
    {
        label: "Hazardous Waste Disposal - Sui Generis",
        value: "hazardous waste disposal",
    },
    {
        label: "HMOs (7+ people) - Sui Generis",
        value: "HMOs (7+ people)",
    },
    {
        label: "Horticulture - Sui Generis",
        value: "horticulture",
    },
    {
        label: "Hostel - Sui Generis",
        value: "hostel",
    },
    {
        label: "Hot Food Takeaways - Sui Generis",
        value: "hot food takeaways",
    },
    {
        label: "Institutional Hostel - Sui Generis",
        value: "institutional hostel",
    },
    {
        label: "Kennels - Sui Generis",
        value: "kennels",
    },
    {
        label: "Landfill Site - Sui Generis",
        value: "landfill site",
    },
    {
        label: "Lap Dancing Club - Sui Generis",
        value: "lap dancing club",
    },
    {
        label: "Large HMOs - Sui Generis",
        value: "large HMOs",
    },
    {
        label: "Launderette - Sui Generis",
        value: "launderette",
    },
    {
        label: "Live Music Venue - Sui Generis",
        value: "live music venue",
    },
    {
        label: "Lorry Park - Sui Generis",
        value: "lorry park",
    },
    {
        label: "Market Gardens - Sui Generis",
        value: "market gardens",
    },
    {
        label: "Minicab Company - Sui Generis",
        value: "minicab company",
    },
    {
        label: "Motorcycle Showroom - Sui Generis",
        value: "motorcycle showroom",
    },
    {
        label: "Motorsport Arena - Sui Generis",
        value: "motorsport arena",
    },
    {
        label: "Multiplex Cinema - Sui Generis",
        value: "multiplex cinema",
    },
    {
        label: "Mushroom Farm - Sui Generis",
        value: "mushroom farm",
    },
    {
        label: "Nightclub - Sui Generis",
        value: "nightclub",
    },
    {
        label: "Nursery Ground - Sui Generis",
        value: "nursery ground",
    },
    {
        label: "Other Drinking Establishment - Sui Generis",
        value: "other drinking establishment",
    },
    {
        label: "Payday Loan Shop - Sui Generis",
        value: "payday loan shop",
    },
    {
        label: "Petrol Filling Station - Sui Generis",
        value: "petrol filling station",
    },
    {
        label: "Petrol Filling Station with Retail - Sui Generis",
        value: "petrol filling station with retail",
    },
    {
        label: "Petrol Station - Sui Generis",
        value: "petrol station",
    },
    {
        label: "Police Station - Sui Generis",
        value: "police station",
    },
    {
        label: "Postal Sorting Office - Sui Generis",
        value: "postal sorting office",
    },
    {
        label: "Private Hire Company - Sui Generis",
        value: "private hire company",
    },
    {
        label: "Pub - Sui Generis",
        value: "pub",
    },
    {
        label: "Pub / Restaurant - Sui Generis",
        value: "pub / restaurant",
    },
    {
        label: "Public House - Sui Generis",
        value: "public house",
    },
    {
        label: "Retail Warehouse Club - Sui Generis",
        value: "retail warehouse club",
    },
    {
        label: "Scrap Yard - Sui Generis",
        value: "scrap yard",
    },
    {
        label: "Student Block - Sui Generis",
        value: "student block",
    },
    {
        label: "Takeaway - Sui Generis",
        value: "takeaway",
    },
    {
        label: "Takeaway Shop - Sui Generis",
        value: "takeaway shop",
    },
    {
        label: "Taxi Company - Sui Generis",
        value: "taxi company",
    },
    {
        label: "Theatre - Sui Generis",
        value: "theatre",
    },
    {
        label: "Vehicle Sport - Sui Generis",
        value: "vehicle sport",
    },
    {
        label: "Visitor Centre - Sui Generis",
        value: "visitor centre",
    },
    {
        label: "Waste Disposal - Sui Generis",
        value: "waste disposal",
    },
    {
        label: "Waste Incinerator - Sui Generis",
        value: "waste incinerator",
    },
    {
        label: "Wine Bar - Sui Generis",
        value: "wine bar",
    },
    {
        label: "Yard for Breaking of Motor Vehicles - Sui Generis",
        value: "yard for breaking of motor vehicles",
    },
    {
        label: "Yard for Storage / Distribution of Minerals - Sui Generis",
        value: "yard for storage / distribution of minerals",
    },
    {
        label: "Zoo - Sui Generis",
        value: "zoo",
    },
    {
        label: "Agricultural",
        value: "agricultural",
    },
    {
        label: "Brownfield",
        value: "brownfield",
    },
    {
        label: "Derelict",
        value: "derelict",
    },
    {
        label: "Greenfield",
        value: "greenfield",
    },
    {
        label: "Mixed Use",
        value: "mixed use",
    },
    {
        label: "Other",
        value: "other",
    },
    {
        label: "Vacant",
        value: "vacant",
    },
];

export const usSiteUseOptions = [
    {
        label: "Multifamily Housing",
        value: "multifamily housing",
    },
    {
        label: "Derelict / vacant",
        value: "vacant",
    },
    {
        label: "Houses",
        value: "residential houses",
    },
    {
        label: "Industry",
        value: "industrial",
    },
    {
        label: "Mixed use",
        value: "mixed use",
    },
    {
        label: "Offices",
        value: "offices",
    },
    {
        label: "Retail and service",
        value: "retail",
    },
    {
        label: "Storage and Distribution",
        value: "storage and distribution",
    },
    {
        label: "Greenfield",
        value: "greenfield land",
    },
    {
        label: "Brownfield",
        value: "brownfield land",
    },
    {
        label: "Other",
        value: "other",
    },
];

export const externalToolboxModules = [
    {
        title: "TfL WebCAT (PTAL)",
        icon: TflWebcatPTALIcon,
        link: "https://tfl.gov.uk/info-for/urban-planning-and-construction/planning-with-webcat/webcat",
        name: "tfl_webcat_ptal",
    },
    {
        title: "National Highways Counts",
        icon: NationalHighwaysCountsIcon,
        link: "https://webtris.highwaysengland.co.uk/",
        name: "nat_highway_counts",
    },
    {
        title: "National Cycle Network (Sustrans)",
        icon: NationalCycleNetworkIcon,
        link: "https://explore.osmaps.com/?lat={{latitude}}&lon={{longitude}}&zoom=14&style=Standard&type=2d&overlays=os-ncn-layer",
        name: "nat_cycle_network",
    },
    {
        title: "Bike Share Schemes",
        icon: BikeShareSchemesIcon,
        link: "https://bikesharemap.com/#/8/{{longitude}}/{{latitude}}/",
        name: "bike_share_schemes",
    },
    {
        title: "EV Charging Points",
        icon: EVChargingPointsIcon,
        link: "https://www.zap-map.com/live/",
        name: "ev_charging_points",
    },
    {
        title: "Rail Station Usage",
        icon: RailStationUsageIcon,
        link: "https://dataportal.orr.gov.uk/statistics/usage/estimates-of-station-usage",
        name: "rail_station_usage",
    },
    {
        title: "Google Maps",
        icon: GoogleMapsIcon,
        link: "https://www.google.com/maps/@?api=1&map_action=map&center={{latitude}}%2C{{longitude}}&layer=none",
        name: "google_maps",
    },
    {
        title: "Google Street View",
        icon: StreetviewIcon,
        link: "https://www.google.com/maps/@?api=1&map_action=pano&viewpoint={{latitude}}%2C{{longitude}}",
        name: "google_streetview",
    },
    {
        title: "Google Maps Traffic View",
        icon: TrafficIcon,
        link: "https://www.google.com/maps/@?api=1&map_action=map&center={{latitude}}%2C{{longitude}}&layer=traffic",
        name: "google_maps_traffic",
    },
    {
        title: "Enterprise Car Club Locations",
        icon: CarClubIcon,
        link: "https://www.enterprisecarclub.co.uk/gb/en/programs/promotion/enterprise-car-club-map1.html",
        name: "enterprise_car_club",
    },
    {
        title: "ZipCar Locations",
        icon: CarClubIcon,
        link: "https://my.zipcar.com/search",
        name: "zipcar",
    },
    {
        title: "Co-Wheels Locations",
        icon: CarClubIcon,
        link: "https://www.co-wheels.org.uk/find-car",
        name: "co_wheels",
    },
    {
        title: "EV Charging Points (Zapmap)",
        icon: EVChargingPointsIcon,
        link: "https://www.zap-map.com/live/",
        name: "ev_charging_points_zapmap",
    },
    {
        title: "Offsite Parking (Parkopedia)",
        icon: OffsiteParkingIcon,
        link: "https://www.parkopedia.com/",
        name: "parkopedia",
    },
    {
        title: "Light Rail Usage (DfT)",
        icon: LightRailIcon,
        link: "https://www.gov.uk/government/statistical-data-sets/light-rail-and-tram-statistics-lrt##passenger-journeys-vehicle-miles-vehicle-kilometres-and-occupancy-lrt01",
        name: "light_rail_usage_dft",
    },
    {
        title: "Census Travel to Work Flows (ONS/Datashine, 2011)",
        icon: TravelToWorkFlowsIcon,
        link: "https://commute.datashine.org.uk/#mode=allflows&direction=both&msoa=E02000378&zoom=11.2&lon={{longitude}}&lat={{latitude}}",
        name: "census_travel_to_work_flows",
    },
    {
        title: "Census Travel to Work Methods (ONS, 2021)",
        icon: TravelToWorkMethodsIcon,
        link: "https://www.ons.gov.uk/census/maps/choropleth/work/method-of-travel-to-workplace/transport-to-workplace-12a/work-mainly-at-or-from-home/",
        name: "census_travel_to_work_methods",
    },
    {
        title: "Census Travel to Work Methods (ONS/Datashine, 2011)",
        icon: TravelToWorkMethodsIcon,
        link: "https://datashine.org.uk/#table=QS701EW&col=QS701EW0011&ramp=YlOrRd&layers=BTTT&zoom=12&lon={{longitude}}&lat={{latitude}}",
        name: "census_travel_to_work_methods_datashine",
    },
    {
        title: "Census Car Ownership (ONS, 2021)",
        icon: CarOwnershipIcon,
        link: "https://www.ons.gov.uk/census/maps/choropleth/housing/number-of-cars-or-vans/number-of-cars-3a/no-cars-or-vans-in-household",
        name: "census_car_ownership",
    },
    {
        title: "Census Distance Travelled to Work (ONS, 2021)",
        icon: DistanceTravelledToWorkIcon,
        link: "https://www.ons.gov.uk/census/maps/choropleth/work/distance-travelled-to-work/workplace-travel-4a/less-than-10km",
        name: "census_distance_travelled_to_work",
    },
    {
        title: "Census Travel to Work (ONS Nomis 2011)",
        icon: PolicyIcon,
        link: "https://www.nomisweb.co.uk/census/2011/wu03EW",
        name: "census_travel_to_work_2011_nomis",
    },
    {
        title: "2021 Census (Nomis)",
        icon: PolicyIcon,
        link: "https://www.nomisweb.co.uk/sources/census_2021/report",
        name: "census_2021_nomis",
    },
    {
        title: "TRICS",
        icon: TRICsIcon,
        link: "https://trics.co.uk",
        name: "trics",
    },
    {
        title: "NPPF - Dec 2024",
        icon: PolicyIcon,
        link: "https://www.gov.uk/government/publications/national-planning-policy-framework--2",
        name: "nppf_dec_2024",
    },
    {
        title: "NPPG - Mar 2014",
        icon: PolicyIcon,
        link: "https://www.gov.uk/guidance/travel-plans-transport-assessments-and-statements",
        name: "nppg_mar_2014",
    },
    {
        title: "DEFRA Survey Data",
        icon: SiteLocationMapIcon,
        link: "https://environment.data.gov.uk/survey",
        name: "defra_survey_data",
    },
    {
        title: "EA Main River Map",
        icon: SiteLocationMapIcon,
        link: "https://www.arcgis.com/apps/mapviewer/index.html?layers=781494c6b6ce4a6bb8147341f99c2daf",
        name: "ea_main_river_map",
    },
    {
        title: "BGS Geology Viewer",
        icon: SiteLocationMapIcon,
        link: "https://geologyviewer.bgs.ac.uk/",
        name: "bgs_geology_viewer",
    },
    {
        title: "EA FRA Standing Advice",
        icon: PolicyIcon,
        link: "https://www.gov.uk/guidance/flood-risk-assessment-standing-advice",
        name: "ea_fra_standing_advice",
    },
    {
        title: "EA Flood Map",
        icon: SiteLocationMapIcon,
        link: "https://flood-map-for-planning.service.gov.uk/location",
        name: "ea_flood_map",
    },
    {
        title: "EA Surface Water Map",
        icon: SiteLocationMapIcon,
        link: "https://check-long-term-flood-risk.service.gov.uk/map?map=SurfaceWater#",
        name: "ea_surface_water_map",
    },
    {
        title: "EA Reservoirs Map",
        icon: SiteLocationMapIcon,
        link: "https://check-long-term-flood-risk.service.gov.uk/map?map=Reservoirs",
        name: "ea_reservoir_map",
    },
    {
        title: "Caltrans: VMT-Focused Transportation Impact Study Guide",
        icon: PolicyIcon,
        link: "https://dot.ca.gov/-/media/dot-media/programs/transportation-planning/documents/sb-743/2020-05-20-approved-vmt-focused-tisg-a11y.pdf",
        name: "caltrans_vmt_tisg",
    },
    {
        title: "SANDAG: Traffic Generation Rates for the San Diego Region",
        icon: PolicyIcon,
        link: "https://www.sandiegocounty.gov/content/dam/sdc/pds/ceqa/LehmanTPM/38%20Appendix%20T9_SANDAG%20Trip%20Generation%20Rates.pdf",
        name: "sandag_trip_gen",
    },
    {
        title: "SANDAG VMT Map",
        icon: PolicyIcon,
        link: "https://sandag.maps.arcgis.com/apps/webappviewer/index.html?id=bb8f938b625c40cea14c825835519a2b",
        name: "sandag_vmt_map",
    },
    {
        title: "City of San Diego Trip Generation Manual",
        icon: PolicyIcon,
        link: "https://www.sandiego.gov/sites/default/files/appendix-m-trip-generation-manual_0.pdf",
        name: "sd_city_trip_gen",
    },
    {
        title: "City of San Diego Transportation Study Manual (TSM)",
        icon: PolicyIcon,
        link: "https://www.sandiego.gov/sites/default/files/10-transportation-study-manual.pdf",
        name: "sd_city_tsm",
    },
    {
        title: "County of San Diego Transportation Study Guidelines",
        icon: PolicyIcon,
        link: "https://www.sandiegocounty.gov/content/dam/sdc/pds/SB743/Transportation%20Study%20Guide%20-%20FINAL%20-%20September%202022.pdf",
        name: "sd_county_guidelines",
    },
    {
        title: "ITE Trip Generation",
        icon: PolicyIcon,
        link: "https://www.itetripgen.org/",
        name: "ite_trip_gen_external",
    },
    {
        title: "London Air Quality Map",
        icon: ExternalLinkIcon,
        link: "https://www.london.gov.uk/programmes-and-strategies/environment-and-climate-change/pollution-and-air-quality/london-air-quality-map",
        name: "london_air_quality_map",
    },
    {
        title: "London Environment Strategy (2018)",
        icon: ExternalLinkIcon,
        link: "https://www.london.gov.uk/programmes-and-strategies/environment-and-climate-change/london-environment-strategy",
        name: "london_environment_strategy",
    },
    {
        title: "London SPG: Control of Dust Emissions",
        icon: ExternalLinkIcon,
        link: "https://www.london.gov.uk/programmes-strategies/planning/implementing-london-plan/london-plan-guidance-and-spgs/control-dust-and",
        name: "london_spg_control_of_dust_emissions",
    },
    {
        title: "London PG: Air Quality Positive",
        icon: ExternalLinkIcon,
        link: "https://www.london.gov.uk/programmes-strategies/planning/implementing-london-plan/london-plan-guidance/air-quality-positive-aqp-guidance",
        name: "london_pg_air_quality_positive",
    },
    {
        title: "London PG: Air Quality Neutral",
        icon: ExternalLinkIcon,
        link: "https://www.london.gov.uk/programmes-strategies/planning/implementing-london-plan/london-plan-guidance/air-quality-neutral-aqn-guidance",
        name: "london_pg_air_quality_neutral",
    },
    {
        title: "The London Plan",
        icon: ExternalLinkIcon,
        link: "https://www.london.gov.uk/programmes-strategies/planning/london-plan",
        name: "london_plan",
    },
    {
        title: "London Atmospheric Emissions Inventory (2019)",
        icon: ExternalLinkIcon,
        link: "https://data.london.gov.uk/dataset/london-atmospheric-emissions-inventory--laei--2019",
        name: "london_atmospheric_emissions_inventory_2019",
    },
    {
        title: "London Air",
        icon: ExternalLinkIcon,
        link: "https://www.londonair.org.uk/LondonAir/Default.aspx",
        name: "london_air",
    },
    {
        title: "AURN",
        icon: ExternalLinkIcon,
        link: "https://uk-air.defra.gov.uk/networks/network-info?view=aurn",
        name: "aurn",
    },
    {
        title: "UK Air DEFRA Background Mapping Data (2021)",
        icon: ExternalLinkIcon,
        link: "https://uk-air.defra.gov.uk/data/laqm-background-home",
        name: "uk_air_defra_background_mapping_data_2021",
    },
    {
        title: "DfT Traffic Counts",
        icon: ExternalLinkIcon,
        link: "https://roadtraffic.dft.gov.uk/downloads",
        name: "dft_traffic_counts",
    },
    {
        title: "Emissions Factors Toolkit (2024)",
        icon: ExternalLinkIcon,
        link: "https://laqm.defra.gov.uk/air-quality/air-quality-assessment/emissions-factors-toolkit/",
        name: "emissions_factors_toolkit_2024",
    },
    {
        title: "DEFRA NOx to NO2 Calculator",
        icon: ExternalLinkIcon,
        link: "https://laqm.defra.gov.uk/air-quality/air-quality-assessment/nox-to-no2-calculator/",
        name: "defra_nox_to_no2_calculator",
    },
    {
        title: "IAQM, Planning for Air Quality (2017)",
        icon: ExternalLinkIcon,
        link: "https://iaqm.co.uk/text/guidance/air-quality-planning-guidance.pdf",
        name: "iaqm_planning_for_air_quality_2017",
    },
    {
        title: "DEFRA Pollutant Release and Transfer Register (PRTR)",
        icon: ExternalLinkIcon,
        link: "https://www.gov.uk/guidance/uk-pollutant-release-and-transfer-register-prtr-data-sets",
        name: "defra_prtr",
    },
    {
        title: "DEFRA Climate Change Allowances",
        icon: ExternalLinkIcon,
        link: "https://environment.data.gov.uk/hydrology/climate-change-allowances/river-flow",
        name: "defra_climate_change_allowances",
    },
    {
        title: "USGS Map Locator",
        icon: ExternalLinkIcon,
        link: "https://store.usgs.gov/map-locator",
        name: "usgs_map_locator",
    },
    {
        title: "USDA Web Soil Survey",
        icon: ExternalLinkIcon,
        link: "https://websoilsurvey.sc.egov.usda.gov/App/WebSoilSurvey.aspx",
        name: "usda_web_soil_survey",
    },
    {
        title: "USFWS Endangered Species",
        icon: ExternalLinkIcon,
        link: "https://ipac.ecosphere.fws.gov/",
        name: "usfws_endangered_species",
    },
    {
        title: "FEMA National Flood Hazard Layer",
        icon: ExternalLinkIcon,
        link: "https://hazards-fema.maps.arcgis.com/apps/webappviewer/index.html?id=8b0adb51996444d4879338b5529aa9cd",
        name: "fema_flood_hazard",
    },
    {
        title: "EPA EnviroMapper",
        icon: ExternalLinkIcon,
        link: "https://enviro.epa.gov/envirofacts/enviromapper/search",
        name: "epa_enviromapper",
    },
    {
        title: "EPA Green Book Pollutants",
        icon: ExternalLinkIcon,
        link: "https://www.epa.gov/green-book",
        name: "epa_green_book",
    },
    {
        title: "CA Wildlife BIOS Viewer",
        icon: ExternalLinkIcon,
        link: "https://apps.wildlife.ca.gov/bios6/?tool=cnddbqv",
        name: "ca_wildlife_bios",
    },
    {
        title: "CA Water SGMA Viewer",
        icon: ExternalLinkIcon,
        link: "https://sgma.water.ca.gov/webgis/?appid=SGMADataViewer#gwlevels",
        name: "ca_water_sgma",
    },
    {
        title: "CA Well Finder",
        icon: ExternalLinkIcon,
        link: "https://maps.conservation.ca.gov/doggr/wellfinder/",
        name: "ca_well_finder",
    },
    {
        title: "CA Coastal Zone",
        icon: ExternalLinkIcon,
        link: "https://www.coastal.ca.gov/maps/czb/",
        name: "ca_coastal_zone",
    },
    {
        title: "CA Historical Resources",
        icon: ExternalLinkIcon,
        link: "https://ohp.parks.ca.gov/ListedResources/",
        name: "ca_historical_resources",
    },
    {
        title: "FAA Order 1050.1F Environmental Impacts",
        icon: ExternalLinkIcon,
        link: "https://www.faa.gov/regulations_policies/orders_notices/index.cfm/go/document.current/documentnumber/1050.1",
        name: "faa_order_1050_1f",
    },
    {
        title: "FAA Order 5050-4B NEPA Instructions",
        icon: ExternalLinkIcon,
        link: "https://www.faa.gov/regulations_policies/orders_notices/index.cfm/go/document.information/documentid/14836",
        name: "faa_order_5050_4b",
    },
    {
        title: "FAA CATEX SOP",
        icon: ExternalLinkIcon,
        link: "https://www.faa.gov/airports/resources/sops/arp-SOP-510-catex",
        name: "faa_catex_sop",
    },
    {
        title: "Standard CATEX Template",
        icon: ExternalLinkIcon,
        link: "https://www.faa.gov/sites/faa.gov/files/airports/western_pacific/airports_forms/arp-SOP-510-catex-documentation.docx",
        name: "standard_catex_template",
    },
    {
        title: "National Wetlands Inventory",
        icon: ExternalLinkIcon,
        link: "https://fwsprimary.wim.usgs.gov/wetlands/apps/wetlands-mapper/",
        name: "national_wetlands_inventory",
    },
    {
        title: "National Wilderness Preservation System",
        icon: ExternalLinkIcon,
        link: "https://umontana.maps.arcgis.com/apps/webappviewer/index.html?id=a415bca07f0a4bee9f0e894b0db5c3b6",
        name: "national_wilderness_preservation",
    },
    {
        title: "EPA EJSCREEN",
        icon: ExternalLinkIcon,
        link: "https://www.epa.gov/ejscreen",
        name: "epa_ejscreen",
    },
    {
        title: "Climate and Economic Justice Screening",
        icon: ExternalLinkIcon,
        link: "https://screeningtool.geoplatform.gov/en/#12/{{latitude}}/{{longitude}}",
        name: "climate_economic_justice",
    },
    {
        title: "FHWA Screening Tool for Equity Analysis",
        icon: ExternalLinkIcon,
        link: "https://maps.dot.gov/fhwa/steap/",
        name: "fhwa_equity",
    },
    {
        title: "DOT Equitable Transportation Community Explorer",
        icon: ExternalLinkIcon,
        link: "https://www.transportation.gov/priorities/equity/justice40/etc-explorer",
        name: "dot_explorer",
    },
    {
        title: "US Census",
        icon: ExternalLinkIcon,
        link: "https://data.census.gov/",
        name: "us_census",
    },
    {
        title: "DfT Local Transport Note 1/20 - Jul 2020",
        icon: PolicyIcon,
        link: "https://assets.publishing.service.gov.uk/media/5ffa1f96d3bf7f65d9e35825/cycle-infrastructure-design-ltn-1-20.pdf",
        name: "dft_ltn_120",
    },
    {
        title: "Active Travel England Framework Document - Jul 2022",
        icon: PolicyIcon,
        link: "https://www.gov.uk/government/publications/active-travel-england-framework-document-department-for-transport/framework-document-active-travel-england",
        name: "ate_framework",
    },
    {
        title: "Soilscapes Viewer",
        icon: ExternalLinkIcon,
        link: "https://www.landis.org.uk/soilscapes/",
        name: "soilscapes_viewer",
    },
    {
        title: "National Register of Historic Places Map Viewer",
        icon: ExternalLinkIcon,
        link: "https://www.nps.gov/maps/full.html?mapId=7ad17cc9-b808-4ff8-a2f9-a99909164466",
        name: "national_register_historic_places",
    },
    {
        title: "USGS topoView Map Viewer",
        icon: ExternalLinkIcon,
        link: "https://ngmdb.usgs.gov/topoview/viewer/#4/40.01/-100.06",
        name: "usgs_topoview",
    },
    {
        title: "Land & Water Conservation Fund Map Viewer",
        icon: ExternalLinkIcon,
        link: "https://lwcf.tplgis.org/mappast/",
        name: "land_water_conservation_fund",
    },
    {
        title: "National ESA Critical Habitat Mapper",
        icon: ExternalLinkIcon,
        link: "https://noaa.maps.arcgis.com/apps/webappviewer/index.html?id=68d8df16b39c48fe9f60640692d0e318",
        name: "national_esa_critical_habitat_mapper",
    },
    {
        title: "EPA Green Book Nonattainment Areas",
        icon: ExternalLinkIcon,
        link: "https://www.epa.gov/green-book",
        name: "epa_green_book_nonattainment_areas",
    },
    {
        title: "BGS Onshore Borehole Viewer",
        icon: ExternalLinkIcon,
        link: "https://mapapps2.bgs.ac.uk/geoindex/home.html?layer=BGSBoreholes&_ga=2.120613899.2064869057.1743604692-664498197.1743604692",
        name: "bgs_onshore_borehole_viewer",
    },
    {
        title: "DEFRA Climate Change Allowances Map",
        icon: ExternalLinkIcon,
        link: "https://environment.data.gov.uk/hydrology/climate-change-allowances/river-flow",
        name: "defra_climate_change_allowances_map",
    },
    {
        title: "Environment Agency - Recorded Flood Outline Map",
        icon: ExternalLinkIcon,
        link: "https://environment.data.gov.uk/explore/8c75e700-d465-11e4-8b5b-f0def148f590",
        name: "environment_agency_recorded_flood_outline_map",
    },
];

export const schemeflowToolboxModules = [
    {
        title: "Site Location Map",
        icon: SiteLocationMapIcon,
        to: "site_map",
        name: "site_map",
        browserOnly: false,
    },
    {
        title: "Regional Map",
        icon: SiteLocationMapIcon,
        to: "regional_site_map",
        name: "regional_site_map",
        browserOnly: false,
    },
    {
        title: "Bus Services",
        icon: BusServicesIcon,
        to: "bus_services",
        name: "bus_services",
        browserOnly: false,
    },
    {
        title: "Rail Services",
        icon: RailServicesIcon,
        to: "rail_services",
        name: "rail_services",
        browserOnly: false,
    },
    {
        title: "Walking Isochrone",
        icon: WalkingIsochroneIcon,
        to: "pedestrian_map",
        name: "pedestrian_map",
        browserOnly: false,
    },
    {
        title: "Satellite Map",
        icon: SatelliteMapIcon,
        to: "satellite_map",
        name: "satellite_map",
        browserOnly: false,
    },
    {
        title: "Local Highways Map",
        icon: HighwaysMapIcon,
        to: "highways_map",
        name: "highways_map",
        browserOnly: false,
    },
    {
        title: "Driving Isochrone",
        icon: DrivingIsochroneIcon,
        to: "driving_map",
        name: "driving_map",
        browserOnly: false,
    },
    {
        title: "Collision Map",
        icon: CollisionMapIcon,
        to: "collision_map",
        name: "collision_map",
        browserOnly: false,
    },
    {
        title: "Street Segments",
        icon: RoadsIcon,
        to: "street_segments_map",
        name: "street_segments_map",
        browserOnly: false,
    },
    {
        title: "Intersections",
        icon: IntersectionsIcon,
        to: "intersections_map",
        name: "intersections_map",
        browserOnly: false,
    },
    {
        title: "Policy Explorer",
        icon: PolicyIcon,
        to: "policy_explorer",
        name: "policy_explorer",
        browserOnly: false,
    },
    {
        title: "Public Transport Isochrone Map",
        icon: PublicTransportIsochroneMapIcon,
        to: "public_transport_isochrone_map",
        name: "public_transport_isochrone_map",
        browserOnly: false,
    },
    // Amenities maps
    ...Object.entries(amenitiesMaps).reduce((acc, [mapKey, mapConfig]) => {
        acc.push({
            title: mapConfig.name,
            icon: mapConfig.icon_url || SiteLocationMapIcon,
            to: mapKey,
            name: mapKey,
            browserOnly: false,
        });
        return acc;
    }, []),
];

export const stripeKeys = {
    UK: import.meta.env.VITE_STRIPE_API_KEY_UK,
    USA: import.meta.env.VITE_STRIPE_API_KEY_USA,
};

export const snippetReportChapterOptions = [
    "Contents",
    "Introduction",
    "Existing Conditions",
    "Proposed Development",
    "Trip Generation",
    "Future Conditions",
    "Parking",
    "Conclusions",
];

export const snippetClassOptions = [
    "Data Summary",
    "Introduction",
    "Legal / Disclaimer",
    "Methodology",
    "Overview",
    "Standards / Regulation",
];

export const isochroneDistanceDisplayUnits = Object.freeze({
    metres: {
        unitName: "Metres",
        symbol: "m",
        conversion: 1.0,
    },
    kilometres: {
        unitName: "Kilometres",
        symbol: "km",
        conversion: 0.001,
    },
});

export const compassDirectionOptions = [
    "North",
    "North East",
    "East",
    "South East",
    "South",
    "South West",
    "West",
    "North West",
];
