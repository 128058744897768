import { fragment } from "xmlbuilder2";
import { SF_XML_NAMESPACE } from "@/word/contentControlConstants";
import axios from "axios";
import { parseNumber, parseValue } from "../word/parseXML";

export interface StreetSegment {
    name: string;
    segment_description: string;
    street_classification?: string;
    number_of_lanes?: number;
    divide?: "undivided" | "undivided with two-way left-turn lane" | "undivided with intermittent turning lanes" | "raised median" | "other divider";
    speed_limit?: number;
    no_speed_limit?: boolean;
    sidewalks?: "None" | "One side" | "Both sides";
    sidewalks_contiguous?: "Contiguous" | "Not Contiguous";
    onstreet_parking?: "no onstreet parking" | "parking on one side" | "parking on both sides";
    bike_lane?: "None" | "Class I" | "Class II" | "Class III" | "Class IV";
    bike_lane_buffered?: "Yes" | "No";
    bike_lane_sides?: "Both sides" | "One side";
    bike_route_direction?: "Northbound" | "Southbound" | "Eastbound" | "Westbound";
    cycle_track_sides?: "One-way" | "Two-way";
}

/**
 * Encodes an array of street segments into a custom XML format for Word document integration.
 * The XML structure is a flat list of all street segments
 *
 * @param streetSegments - Array of street segment objects containing road characteristics
 * @returns A formatted XML string representation of the street segments
 */
export const encodeStreetSegmentsForWordCustomXML = (streetSegments: StreetSegment[]): string => {

    // First construct the object structure
    const xmlObj = {
        street_segments: {
            "@xmlns": SF_XML_NAMESPACE,
            item: streetSegments.map(segment => ({
                name: segment.name || '',
                segment_description: segment.segment_description || '',
                street_classification: segment.street_classification || '',
                number_of_lanes: segment.number_of_lanes?.toString() || '',
                divide: segment.divide || '',
                speed_limit: segment.speed_limit?.toString() || '',
                no_speed_limit: segment.no_speed_limit?.toString() || '',
                sidewalks: segment.sidewalks || '',
                sidewalks_contiguous: segment.sidewalks_contiguous || '',
                onstreet_parking: segment.onstreet_parking || '',
                bike_lane: segment.bike_lane || '',
                bike_lane_buffered: segment.bike_lane_buffered || '',
                bike_lane_sides: segment.bike_lane_sides || '',
                bike_route_direction: segment.bike_route_direction || '',
                cycle_track_sides: segment.cycle_track_sides || ''
            }))
        }
    };

    // Convert object to XML
    const xml = fragment(xmlObj).end({ prettyPrint: true });

    return xml;

}


/**
 * Decodes a custom XML string from Word document into an array of street segment objects.
 * Skips any items that don't have a name.
 * This function is broadly the inverse of encodeStreetSegmentsForWordCustomXML.
 *
 * @param obj - The object representation of street segments, as decoded by xmlbuilder2 and extracted from the full custom XML part
 * @returns Array of StreetSegment objects
 */
export const decodeStreetSegmentsFromWordCustomXMLObject = (obj: any): StreetSegment[] => {
    // Extract the items array from the object structure
    const items = Array.isArray(obj.item)
        ? obj.item
        : [obj.item].filter(Boolean); // Handle single item case

    // Map the items to StreetSegment objects, but skip any items that don't have a name
    return items.filter(item => parseValue(item.name, '')).map(item => {
        return {
            name: parseValue(item.name, ''),
            segment_description: parseValue(item.segment_description, ''),
            street_classification: parseValue(item.street_classification),
            number_of_lanes: parseNumber(item.number_of_lanes),
            divide: parseValue(item.divide) as StreetSegment["divide"],
            speed_limit: parseNumber(item.speed_limit),
            no_speed_limit: parseValue(item.no_speed_limit) === 'true',
            sidewalks: parseValue(item.sidewalks) as StreetSegment["sidewalks"],
            sidewalks_contiguous: parseValue(item.sidewalks_contiguous) as StreetSegment["sidewalks_contiguous"],
            onstreet_parking: parseValue(item.onstreet_parking) as StreetSegment["onstreet_parking"],
            bike_lane: parseValue(item.bike_lane) as StreetSegment["bike_lane"],
            bike_lane_buffered: parseValue(item.bike_lane_buffered) as StreetSegment["bike_lane_buffered"],
            bike_lane_sides: parseValue(item.bike_lane_sides) as StreetSegment["bike_lane_sides"],
            bike_route_direction: parseValue(item.bike_route_direction) as StreetSegment["bike_route_direction"],
            cycle_track_sides: parseValue(item.cycle_track_sides) as StreetSegment["cycle_track_sides"]
        };
    });
};

export interface StreetSegmentsStreet {
    name: string;
    segments: StreetSegment[];
    paragraph_description?: string;
}

/**
 * Groups street segments by street name
 * @param streetSegments - Array of street segment objects
 * @returns Array of street segments grouped by street name
 */
export const groupStreetSegmentsByStreetName = (streetSegments: StreetSegment[]): StreetSegmentsStreet[] => {
    const grouped = streetSegments.reduce((acc, segment) => {
        // Get or create array for this street name
        if (!acc[segment.name]) {
            acc[segment.name] = {
                name: segment.name,
                segments: [],
            };
        }
        acc[segment.name].segments.push(segment);
        return acc;
    }, {} as Record<string, StreetSegmentsStreet>);

    return Object.values(grouped);
}

export const generateStreetSegmentsSummary = async (streets: StreetSegmentsStreet[]): Promise<StreetSegmentsStreet[]> => {
    const response = await axios.post('/api/street_segments/summary', { streets });
    return response.data;
}

/**
 * Encodes an array of street segments into a custom XML format for Word document integration.
 * The XML structure is a hierarchical view grouped by street name
 *
 * @param streetSegmentsByStreet - Array of street segment objects grouped by street name
 * @returns A formatted XML string representation of the street segments grouped by street name
 */

export const encodeStreetSegmentsByStreetNameForWordCustomXML = (streetSegmentsByStreet: StreetSegmentsStreet[]): string => {

    // First construct the object structure
    const xmlObj = {
        street_segments_by_street: {
            "@xmlns": SF_XML_NAMESPACE,
            item: Object.values(streetSegmentsByStreet).map(street => ({
                name: street.name,
                segments: {
                    item: street.segments.map(segment => ({
                        name: segment.name || '',
                        segment_description: segment.segment_description || '',
                        street_classification: segment.street_classification || '',
                        number_of_lanes: segment.number_of_lanes?.toString() || '',
                        divide: segment.divide || '',
                        speed_limit: segment.speed_limit?.toString() || '',
                        no_speed_limit: segment.no_speed_limit?.toString() || '',
                        sidewalks: segment.sidewalks || '',
                        sidewalks_contiguous: segment.sidewalks_contiguous || '',
                        onstreet_parking: segment.onstreet_parking || '',
                        bike_lane: segment.bike_lane || '',
                        bike_lane_buffered: segment.bike_lane_buffered || '',
                        bike_lane_sides: segment.bike_lane_sides || '',
                        bike_route_direction: segment.bike_route_direction || '',
                        cycle_track_sides: segment.cycle_track_sides || ''
                    })),
                },
                paragraph_description: street.paragraph_description || ''
            }))
        }
    };

    // Convert object to XML
    const xml = fragment(xmlObj).end({ prettyPrint: true });

    return xml;

}

export const sanDiegoStreetClassificationOptions = [
    {
        label: "Alleyway (City of San Diego)",
        value: "Alleyway"
    },
    {
        label: "Private Street (City of San Diego)",
        value: "Private Street"
    },
    {
        label: "Pedestrianway/Bikeway (City of San Diego)",
        value: "Pedestrianway/Bikeway"
    },
    {
        label: "Bike Path (City of San Diego)",
        value: "Bike Path"
    },
    {
        label: "Transitway (City of San Diego)",
        value: "Transitway"
    },
    {
        label: "Local Street (City of San Diego)",
        value: "Local Street"
    },
    {
        label: "Collector Street (City of San Diego)",
        value: "Collector Street"
    },
    {
        label: "Major Street (City of San Diego)",
        value: "Major Street"
    },
    {
        label: "Primary Arterial (City of San Diego)",
        value: "Primary Arterial"
    },
    {
        label: "Rural Local Road (City of San Diego)",
        value: "Rural Local Road"
    },
    {
        label: "Rural Collector Road (City of San Diego)",
        value: "Rural Collector Road"
    },
    {
        label: "6.1 Expressway (San Diego County)",
        value: "6.1 Expressway"
    },
    {
        label: "6.2 Prime Arterial (San Diego County)",
        value: "6.2 Prime Arterial"
    },
    {
        label: "4.1A Major Road with Raised Median (San Diego County)",
        value: "4.1A Major Road with Raised Median"
    },
    {
        label: "4.1B Major Road with Intermittent Turn Lanes (San Diego County)",
        value: "4.1B Major Road with Intermittent Turn Lanes"
    },
    {
        label: "4.2A Boulevard with Raised Median (San Diego County)",
        value: "4.2A Boulevard with Raised Median"
    },
    {
        label: "4.2B Boulevard with Intermittent Turn Lane (San Diego County)",
        value: "4.2B Boulevard with Intermittent Turn Lane"
    },
    {
        label: "2.1A Community Collector with Raised Median (San Diego County)",
        value: "2.1A Community Collector with Raised Median"
    },
    {
        label: "2.1B Community Collector with Continuous Turn Lane (San Diego County)",
        value: "2.1B Community Collector with Continuous Turn Lane"
    },
    {
        label: "2.1C Community Collector with Intermittent Turn Lane (San Diego County)",
        value: "2.1C Community Collector with Intermittent Turn Lane"
    },
    {
        label: "2.1D Community Collector with Improvement Options (San Diego County)",
        value: "2.1D Community Collector with Improvement Options"
    },
    {
        label: "2.1E Community Collector (San Diego County)",
        value: "2.1E Community Collector"
    },
    {
        label: "2.2A Light Collector with Raised Median (San Diego County)",
        value: "2.2A Light Collector with Raised Median"
    },
    {
        label: "2.2B Light Collector with Continuous Turn Lane (San Diego County)",
        value: "2.2B Light Collector with Continuous Turn Lane"
    },
    {
        label: "2.2C Light Collector with Intermittent Turn Lanes (San Diego County)",
        value: "2.2C Light Collector with Intermittent Turn Lanes"
    },
    {
        label: "2.2D Light Collector with Improvement Options (San Diego County)",
        value: "2.2D Light Collector with Improvement Options"
    },
    {
        label: "2.2E Light Collector (San Diego County)",
        value: "2.2E Light Collector"
    },
    {
        label: "2.2F Light Collector with Reduced Shoulder (San Diego County)",
        value: "2.2F Light Collector with Reduced Shoulder"
    },
    {
        label: "2.3A Minor Collector with Raised Median (San Diego County)",
        value: "2.3A Minor Collector with Raised Median"
    },
    {
        label: "2.3B Minor Collector with Intermittent Turn Lane (San Diego County)",
        value: "2.3B Minor Collector with Intermittent Turn Lane"
    },
    {
        label: "2.3C Minor Collector (San Diego County)",
        value: "2.3C Minor Collector"
    },
]

export const sanDiegoStreetNameSuffixMapping = {
    "ALY": "Alley",
    "ARC": "Arcade",
    "AVE": "Avenue",
    "BP": "Bikepath",
    "BLVD": "Boulevard",
    "BRG": "Bridge",
    "BYP": "Bypass",
    "CSWY": "Causeway",
    "CIR": "Circle",
    "CP": "Cape",
    "CTE": "Corte",
    "CT": "Court",
    "CV": "Cove",
    "CRES": "Crescent",
    "XING": "Crossing",
    "DR": "Drive",
    "DRWY": "Driveway",
    "EXPY": "Expressway",
    "EXT": "Extension",
    "FRY": "Ferry",
    "FWY": "Freeway",
    "GLEN": "Glen",
    "HWY": "Highway",
    "INTR": "Interchange",
    "LN": "Lane",
    "LOOP": "Loop",
    "MALL": "Mall",
    "PKY": "Parkway",
    "PASS": "Pass",
    "PATH": "Path",
    "PL": "Place",
    "PLZ": "Plaza",
    "PT": "Point",
    "PTE": "Pointe",
    "RAMP": "Ramp",
    "RD": "Road",
    "ROW": "Row",
    "SQ": "Square",
    "ST": "Street",
    "TER": "Terrace",
    "TRL": "Trail",
    "TKTL": "Trucktrail",
    "WALK": "Walk",
    "WAY": "Way"
}

export const sanDiegoStreetNameDirectionsMapping = {
    "E": "East",
    "N": "North",
    "S": "South",
    "W": "West",
    "NE": "Northeast",
    "NW": "Northwest",
    "SE": "Southeast",
    "SW": "Southwest"
};


export const sanDiegoStreetSegmentClasses = {
    1: "Freeway/Expressway",
    2: "Highway/State Routes",
    3: "Minor Highway/Major Roads",
    4: "Arterial or Collector",
    5: "Local Street",
    6: "Unpaved Road",
    7: "Private Road",
    8: "Freeway Transition Ramp",
    9: "Freeway On/Off Ramp",
    A: "Alley",
    H: "Speed Hump",
    M: "Military Street within Base",
    P: "Paper Street",
    W: "Walkway",
    Z: "Named Private Roads",
};

export const sanDiegoStreetFunctionalClasses = {
    1: "Freeway to freeway ramp",
    2: "Light (2-lane) collector street",
    3: "Rural collector road",
    4: "Major road/4-lane major road",
    5: "Rural light collector/local road",
    6: "Prime (primary) arterial",
    7: "Private street",
    8: "Recreational parkway",
    9: "Rural mountain road",
    A: "Alley",
    B: "Class I bicycle path",
    C: "Collector/4-lane collector street",
    D: "Two-lane major street",
    E: "Expressway",
    F: "Freeway",
    L: "Local street/cul-de-sac",
    M: "Military street within base",
    P: "Paper street",
    R: "Freeway/expressway on/off ramp",
    S: "Six-lane major street",
    T: "Transitway",
    U: "Unpaved road",
    W: "Pedestrianway/bikeway",
};
