<template>
    <SchemeflowMap
        mapKey="collision_map"
        isochrone-profile="select"
        :legendConfigMasterList="['site_loc', 'isochrones', 'road_accidents', 'buffer_zones']"
        :markReadyForExportMilliseconds="5000"
    />
</template>

<script setup>
import SchemeflowMap from "@/components/SchemeflowMap.vue";
import { useFormDataStore } from "@/stores/FormDataStore";
import { useUserStore } from "@/stores/UserStore";
import { onMounted, watch } from "vue";
import { watchDebounced } from "@vueuse/core";
import { accidentLegendItems } from "@/maps/constants";

const store = useFormDataStore();
const userStore = useUserStore();

onMounted(() => {
    // Include road accidents layer
    store.maps.collision_map.toggleOverlayLayer("road_accidents", true);

    // If accident data in store, set to map, otherwise fetch accident data
    if (store.formData.collision_locations?.features.length) {
        store.maps.collision_map.setRoadAccidents(store.formData.collision_locations);
    } else {
        // Fetch accidents, store locations, and display on map
        store.fetchCollisionLocations(userStore.thisRegionConfig);
    }

    watchDebounced(
        [() => store.formData.collision_map.radius, () => store.formData.collision_map.analysis_location],
        () => {
            store.fetchCollisionLocations(userStore.thisRegionConfig);
        },
        {
            debounce: 500,
            maxWait: 1000,
            deep: true,
        }
    );

    watch(
        () => store.formData.collision_locations,
        (collision_locations) => {
            const collisionLegendItems = [];

            // Get a set of all severities included in data
            const includedSeverities = new Set();
            collision_locations?.features.forEach((feat) => includedSeverities.add(feat.properties.severity));

            // Add legend items for severities for which at least one feature is displayed
            for (const severity in accidentLegendItems) {
                if (includedSeverities.has(severity)) {
                    collisionLegendItems.push(accidentLegendItems[severity]);
                }
            }

            store.maps.collision_map.legend_config.road_accidents = collisionLegendItems;
        },
        {
            immediate: true,
        }
    );
});
</script>
