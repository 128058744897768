<template>
    <div class="border-b border-gray-200 flex flex-row items-center mb-2">
        <h1 class="text-xl flex-grow"><slot name="title"></slot></h1>

        <ul class="flex flex-wrap -mb-px text-xs font-medium text-center text-gray-500">
            <template v-if="$slots['formatting']">
                <li>
                    <button
                        title="Analysis"
                        @click="tab = 'inputs'"
                        class="inline-flex items-center justify-center p-2 border-b-2 rounded-t-lg hover:text-gray-900 hover:border-gray-500 group"
                        :class="tab === 'inputs' ? 'text-gray-900 border-gray-500' : 'border-transparent'"
                    >
                        <AdjustmentsHorizontalIcon class="h-6 w-6"></AdjustmentsHorizontalIcon>
                    </button>
                </li>
                <li>
                    <button
                        title="Formatting"
                        @click="tab = 'formatting'"
                        class="inline-flex items-center justify-center p-2 border-b-2 rounded-t-lg hover:text-gray-900 hover:border-gray-500 group"
                        :class="tab === 'formatting' ? 'text-gray-900 border-gray-500' : 'border-transparent'"
                    >
                        <PaintBrushIcon class="h-6 w-6"></PaintBrushIcon>
                    </button>
                </li>
            </template>
        </ul>
    </div>

    <template v-if="tab === 'inputs'">
        <template v-if="$slots['instructions']">
            <h2 class="font-bold text-lg mb-2">Instructions</h2>
            <slot name="instructions"></slot>
            <hr class="h-px bg-gray-700 my-2" />
        </template>
        <template v-if="$slots['inputs']">
            <div>
                <slot name="inputs"></slot>
                <hr class="h-px bg-gray-700 my-2" />
            </div>
        </template>
        <div class="flex-grow"></div>
        <template v-if="$slots['sources']">
            <div>
                <h4 class="font-bold">Data Sources</h4>
                <div class="text-xs pt-2">
                    <slot name="sources"></slot>
                </div>
                <hr class="h-px bg-gray-700 my-2" />
            </div>
        </template>
        <template v-if="$slots['analysis']">
            <div>
                <h4 class="font-bold">Analysis</h4>
                <div class="text-xs pt-2">
                    <slot name="analysis"></slot>
                </div>
                <hr class="h-px bg-gray-700 my-2" />
            </div>
        </template>
        <template v-if="$slots['validation']">
            <div>
                <h4 class="font-bold">Validation</h4>
                <div class="text-xs pt-2">
                    <slot name="validation"></slot>
                </div>
                <hr class="h-px bg-gray-700 my-2" />
            </div>
        </template>
        <template v-if="$slots['further_analysis']">
            <div>
                <h4 class="font-bold">Further Analysis</h4>
                <div class="text-xs pt-2">
                    <slot name="further_analysis"></slot>
                </div>
                <hr class="h-px bg-gray-700 my-2" />
            </div>
        </template>

        <template v-if="$slots['official_guidance']">
            <div>
                <h4 class="font-bold">Official Guidance</h4>
                <div class="text-xs pt-2">
                    <slot name="official_guidance"></slot>
                </div>
                <hr class="h-px bg-gray-700 my-2" />
            </div>
        </template>

        <template v-if="$slots['licensing']">
            <div>
                <h4 class="font-bold">Licensing & Permissions</h4>
                <div class="text-xs pt-2">
                    <slot name="licensing"></slot>
                </div>
                <hr class="h-px bg-gray-700 my-2" />
            </div>
        </template>

        <template v-if="$slots['further_info']">
            <div>
                <h4 class="font-bold">Further Information</h4>
                <div class="text-xs pt-2">
                    <slot name="further_info"></slot>
                </div>
                <hr class="h-px bg-gray-700 my-2" />
            </div>
        </template>
    </template>

    <template v-if="tab === 'formatting'">
        <slot name="formatting"></slot>
    </template>
</template>

<script setup>
import { ref } from "vue";
import { AdjustmentsHorizontalIcon, PaintBrushIcon } from "@heroicons/vue/24/outline";

const tab = ref("inputs");
</script>
