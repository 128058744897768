import convert from 'color-convert'
import Event from 'ol/events/Event';
import { Feature } from 'ol';
import { Style, Fill, Stroke, Icon, Circle } from 'ol/style';
import { MultiPoint } from 'ol/geom';
import RegularShape from 'ol/style/RegularShape';
import Point from 'ol/geom/Point.js';
import Map from 'ol/Map';

export class TextAnnotationStyleEditChangeEvent extends Event {
  annotationId: string | null;

  constructor(annotationId: string | null) {
    super('textAnnotationStyleEditingChanged');
    this.annotationId = annotationId;
  }
}


export class ShapeAnnotationStyleEditChangeEvent extends Event {
  annotationId: string | null;

  constructor(annotationId: string | null) {
    super('shapeAnnotationStyleEditingChanged');
    this.annotationId = annotationId;
  }
}


enum BasicSize {
  EXTRA_SMALL = 'extrasmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  EXTRA_LARGE = 'extralarge',
}

enum LabelShape {
  SQUARE = 'square',
  ROUNDED = 'rounded',
  PILL = 'pill',
}

enum TextJustification {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export interface TextAnnotationStyle {
  font_family: string;
  font_size: BasicSize;
  text_color: string;
  background_color: string;
  border_width: BasicSize;
  border_color: string;
  shape: LabelShape;
  justify: TextJustification;
  orientation: number;
  padding: number;
  shadow: boolean;
}

export const textAnnotationDefaultStyle: TextAnnotationStyle = {
  font_family: 'sans',
  font_size: BasicSize.MEDIUM,
  text_color: "#000000",
  background_color: "#FFFFFFCC",
  border_width: BasicSize.MEDIUM,
  border_color: "#00000000",
  shape: LabelShape.SQUARE,
  justify: TextJustification.LEFT,
  orientation: 0,
  padding: 0.5,
  shadow: false,
};

const fontFamilyStacks = {
  sans: 'ui-sans-serif, system-ui, sans-serif',
  serif: 'ui-serif, serif',
  monospace: 'ui-monospace, monospace',
  arial: 'Arial, ui-sans-serif, sans-serif',
  calisto: 'Calisto, ui-serif, serif',
  century_gothic: '"Century Gothic", ui-sans-serif, system-ui, sans-serif',
  courier_new: '"Courier New", ui-monospace, monospace',
  didot: 'Didot, ui-serif, serif',
  garamond: 'Garamond, ui-serif, serif',
  georgia: 'Georgia, ui-serif, serif',
  helvetica: '"Helvetica Neue", Helvetica, ui-sans-serif, system-ui, sans-serif',
  monaco: 'Monaco, ui-monospace, monospace',
  optima: 'Optima, ui-sans-serif, system-ui, sans-serif',
  palatino: 'Palatino, ui-serif, serif',
  rockwell: 'Rockwell, ui-serif, serif',
  times_new_roman: '"Times New Roman", ui-serif, serif',
  verdana: 'Verdana, ui-sans-serif, system-ui, sans-serif',
}

const fontSizes = {
  extrasmall: '0.5rem',
  small: '0.75rem',
  medium: '0.875rem',
  large: '1rem',
  extralarge: '1.125rem',
};

const lineHeights = {
  extrasmall: '0.75rem',
  small: '1rem',
  medium: '1.25rem',
  large: '1.5rem',
  extralarge: '1.75rem',
}

const borderWidths = {
  extrasmall: '1px',
  small: '2px',
  medium: '4px',
  large: '6px',
  extralarge: '8px',
};

const shapeBorderRadius = {
  square: '0px',
  rounded: '0.25rem',
  pill: '9999px',
};

interface TextAnnotationStyleCSS {
  padding: string;
  maxWidth: string;
  userSelect: string;
  fontFamily: string;
  fontSize: string;
  lineHeight: string;
  color: string;
  backgroundColor: string;
  borderWidth: string;
  borderColor: string;
  borderRadius: string;
  textAlign: string;
  transform: string;
  shadow: string;
}

export function getStyleAttributeForStyle(style: TextAnnotationStyle): TextAnnotationStyleCSS {
  const cssValues = {
    padding: `${style.padding}rem`,
    maxWidth: '12rem',
    userSelect: 'none',
    fontFamily: fontFamilyStacks[style.font_family],
    fontSize: fontSizes[style.font_size],
    lineHeight: lineHeights[style.font_size],
    color: style.text_color,
    backgroundColor: style.background_color,
    borderWidth: borderWidths[style.border_width],
    borderColor: style.border_color,
    borderRadius: shapeBorderRadius[style.shape],
    textAlign: style.justify,
    transform: `rotate(${style.orientation}deg)`,
    boxShadow: style.shadow ? '2px 2px 5px rgb(0 0 0 / 60%)' : 'none',
  };

  return cssValues;
}

enum LineStyle {
  SOLID = 'solid',
  DASHED = 'dashed',
  DOTTED = 'dotted',
}

enum LineShape {
  SQUARE = 'square',
  ROUNDED = 'rounded',
}

export enum LineArrowStyle {
  NONE = '0',
  LINE = '1',
  FILLED = '2',
  POINTY = '3',
  DOT = '4',
}

export interface ShapeAnnotationLineStyle {
  color: string;
  width: number;
  line_style: LineStyle;
  shape: LineShape;
  arrow: LineArrowStyle;
}

export const shapeAnnotationLineDefaultStyle: ShapeAnnotationLineStyle = {
  color: "#338333AA",
  width: 4,
  line_style: LineStyle.SOLID,
  shape: LineShape.SQUARE,
  arrow: LineArrowStyle.NONE,
};

export const shapeAnnotationArrowDefaultStyle: ShapeAnnotationLineStyle = {
  color: "#000000CC",
  width: 2,
  line_style: LineStyle.SOLID,
  shape: LineShape.SQUARE,
  arrow: LineArrowStyle.LINE,
}

function convertHex8ToOlArray(hex8: string): [number, number, number, number] {
  // Convert a hex8 string ('#FF0000FF') into an open layers colour array ([255, 0, 0, 1.0])
  const r = hex8.slice(1, 3);
  const g = hex8.slice(3, 5);
  const b = hex8.slice(5, 7);
  const a = hex8.slice(7);

  return [
    parseInt(r, 16),
    parseInt(g, 16),
    parseInt(b, 16),
    parseInt(a, 16) / 255,
  ];
}

const lineCap: Record<LineShape, string> = {
  [LineShape.SQUARE]: 'butt',
  [LineShape.ROUNDED]: 'round',
};

const lineJoin: Record<LineShape, string> = {
  [LineShape.SQUARE]: 'miter',
  [LineShape.ROUNDED]: 'round',
};

export function getLineDashArray(style: LineStyle, width: number, shape: LineShape): number[] | null {
  let roundedAdjustment = 0;
  if (shape === LineShape.ROUNDED) {
    roundedAdjustment = width
  }

  switch (style) {
    case LineStyle.SOLID:
      return null;
    case LineStyle.DASHED:
      return [(width * 3) + 5 - roundedAdjustment, (width * 3) + 5 + roundedAdjustment];
    case LineStyle.DOTTED:
      return [width - roundedAdjustment, width + roundedAdjustment];
  }
}

const arrowSrcFile: Record<LineArrowStyle, string> = {
  [LineArrowStyle.LINE]: 'ArrowHead-Line.svg',
  [LineArrowStyle.FILLED]: 'ArrowHead-Filled.svg',
  [LineArrowStyle.POINTY]: 'ArrowHead-Pointy.svg',
};

function getShapeAnnotationLineStyle(feature: Feature, style: ShapeAnnotationLineStyle, map: Map): Style[] {
  const styles = [
    new Style({
      stroke: new Stroke({
        color: convertHex8ToOlArray(style.color),
        width: style.width,
        lineCap: lineCap[style.shape],
        lineJoin: lineJoin[style.shape],
        lineDash: getLineDashArray(style.line_style, style.width, style.shape),
      }),
    })
  ];

  if (style.arrow == LineArrowStyle.NONE || feature.getGeometry().getCoordinates().length < 2) {
    return styles;
  }

  if (style.arrow == LineArrowStyle.DOT) {
    const endPoint = feature.getGeometry().getCoordinates().at(-1);
    styles.push(
      new Style({
        image: new Circle({
          fill: new Fill({ color: convertHex8ToOlArray(style.color) }),
          radius: 2 + style.width * 1.2,
         }),
        geometry: new Point(endPoint),
      }),
    );
    return styles;
  }

  // Handle arrow styles
  const coordinates = feature.getGeometry().getCoordinates();
  const endPoint = coordinates.at(-1);
  const penultimatePoint = coordinates.at(-2);
  const endPointPixel = map.getPixelFromCoordinate(endPoint);
  const penultimatePointPixel = map.getPixelFromCoordinate(penultimatePoint);

  const dx = endPointPixel[0] - penultimatePointPixel[0];
  const dy = endPointPixel[1] - penultimatePointPixel[1];
  const rotation = Math.atan2(dy, dx);

  styles.push(
    new Style({
      image: new Icon({
        src: `/images/icons/${arrowSrcFile[style.arrow]}`,
        rotation: rotation,
        rotateWithView: true,
        color: convertHex8ToOlArray(style.color),
        scale: style.width / 4,
      }),
      geometry: new Point(endPoint),
    }),
  );
  return styles;
}

export interface ShapeAnnotationPolygonStyle {
  outline_color: string;
  outline_width: number;
  outline_style: LineStyle;
  outline_shape: LineShape;
  fill_color: string;
}

export const shapeAnnotationPolygonDefaultStyle: ShapeAnnotationPolygonStyle = {
  outline_color: "#ADD8E6FF",
  outline_width: 4,
  outline_style: LineStyle.SOLID,
  outline_shape: LineShape.SQUARE,
  fill_color: "#ADD8E6AA",
};

function getShapeAnnotationPolygonStyle(style: ShapeAnnotationPolygonStyle): Style[] {
  return [
    new Style({
      stroke: new Stroke({
        color: convertHex8ToOlArray(style.outline_color),
        width: style.outline_width,
        lineCap: lineCap[style.outline_shape],
        lineJoin: lineJoin[style.outline_shape],
        lineDash: getLineDashArray(style.outline_style, style.outline_width, style.outline_shape),
      }),
      fill: new Fill({
        color: convertHex8ToOlArray(style.fill_color),
      }),
    })
  ]
}

export function getStyleForShapeAnnotationFeature(feature: Feature, map: Map): Style[] {
  const geometryType = feature.getGeometry().getType();
  const featureStyle = feature.get('style');

  switch (geometryType) {
    case 'LineString':
      return getShapeAnnotationLineStyle(feature, { ...shapeAnnotationLineDefaultStyle, ...featureStyle }, map);
    case 'Polygon':
      return getShapeAnnotationPolygonStyle({ ...shapeAnnotationPolygonDefaultStyle, ...featureStyle });
    default:
      throw new TypeError(`Unexpected feature geometry for shape annotation style: ${geometryType}`);
  }
}
export function shapeAnnotationDrawLineStyle(feature: Feature): Style[] {
  const geometryType = feature.getGeometry().getType();

  switch (geometryType) {
    case 'Point':
      // Crosshairs style for pointer
      return [
        new Style({
          image: new RegularShape({
            stroke: new Stroke({ color: 'black', width: 2 }),
            points: 4,
            radius: 10,
            radius2: 0,
            angle: 0,
          }),
        }),
      ];
    case 'LineString':
      return [
        // Line color
        new Style({
          stroke: new Stroke({
            color: 'green', width: 2
          }),
        }),
        // Markers at points
        new Style({
          image: new RegularShape({
            fill: new Fill({ color: 'green' }),
            points: 4,
            radius: 5,
            angle: Math.PI / 4,
          }),
          geometry: new MultiPoint(feature.getGeometry().getCoordinates()),
        }),
      ];
    default:
      throw new TypeError(`Unexpected feature geometry for line drawing style: ${geometryType}`);
  }
}

export function shapeAnnotationDrawPolygonStyle(feature: Feature): Style[] {
  const geometryType = feature.getGeometry().getType();

  switch (geometryType) {
    case 'Point':
      // Crosshairs style for pointer
      return [
        new Style({
          image: new RegularShape({
            stroke: new Stroke({ color: 'black', width: 2 }),
            points: 4,
            radius: 10,
            radius2: 0,
            angle: 0,
          }),
        }),
      ];
    case 'LineString':
      return [
        // Line color
        new Style({
          stroke: new Stroke({
            color: 'green',
            width: 2,
          }),
        }),
        // Markers at points
        new Style({
          image: new RegularShape({
            fill: new Fill({ color: 'green' }),
            points: 4,
            radius: 5,
            angle: Math.PI / 4,
          }),
          geometry: new MultiPoint(feature.getGeometry().getCoordinates()),
        }),
      ];
    case 'Polygon':
      return [
        // Polygon style
        new Style({
          stroke: new Stroke({ color: 'green', width: 2 }),
          fill: new Fill({ color: [144, 238, 144, 0.5]}),
        }),
        // Markers at points
        new Style({
          image: new RegularShape({
            fill: new Fill({ color: 'green' }),
            points: 4,
            radius: 5,
            angle: Math.PI / 4,
          }),
          geometry: new MultiPoint(feature.getGeometry().getCoordinates()),
        }),
      ];
    default:
      throw new TypeError(`Unexpected feature geometry for polygon drawing style: ${geometryType}`);
  }
}

export function shapeAnnotationSelectedFeatureStyle(feature: Feature, map: Map): Style[] {
  const geometryType = feature.getGeometry().getType();
  const featureStyle = feature.get('style') || {};

  let featureStyleWithDefaults
  let styles

  switch (geometryType) {
    case 'LineString':
      // Set line color to SF orange, but otherwise keep line style
      featureStyleWithDefaults = { ...shapeAnnotationLineDefaultStyle, ...featureStyle };
      featureStyleWithDefaults.color = "#FF5600FF";
      styles = getShapeAnnotationLineStyle(feature, featureStyleWithDefaults, map);
      // Add markers to vertices
      styles.push(
        new Style({
          image: new RegularShape({
            fill: new Fill({ color: [255, 86, 0, 1.0] }),
            points: 4,
            radius: featureStyleWithDefaults.width + 3,
            angle: Math.PI / 4,
          }),
          geometry: new MultiPoint(feature.getGeometry().getCoordinates()),
        }),
      )
      return styles;
    case 'Polygon':
      // Set line color to SF orange and fill to translucent SF orange
      featureStyleWithDefaults = { ...shapeAnnotationPolygonDefaultStyle, ...featureStyle };
      featureStyleWithDefaults.outline_color = "#FF5600FF";
      featureStyleWithDefaults.fill_color = "#FF5600AA";

      styles = getShapeAnnotationPolygonStyle(featureStyleWithDefaults);
      // Add markers to vertices
      styles.push(
        new Style({
          image: new RegularShape({
            fill: new Fill({ color: [255, 86, 0, 1.0] }),
            points: 4,
            radius: featureStyleWithDefaults.outline_width + 3,
            angle: Math.PI / 4,
          }),
          geometry: new MultiPoint(feature.getGeometry().getCoordinates()),
        }),
      );
      return styles;
    case 'MultiPolygon':
      // Not used for annotations, but may be used to style polygon features for editing
      featureStyleWithDefaults = { ...shapeAnnotationPolygonDefaultStyle, ...featureStyle };
      featureStyleWithDefaults.outline_color = "#FF5600FF";
      featureStyleWithDefaults.fill_color = "#FF5600AA";

      styles = getShapeAnnotationPolygonStyle(featureStyleWithDefaults);
      // Add markers for vertices
      styles.push(
        new Style({
          image: new RegularShape({
            fill: new Fill({ color: [255, 86, 0, 1.0] }),
            points: 4,
            radius: featureStyleWithDefaults.outline_width + 3,
            angle: Math.PI / 4,
          }),
          geometry: new MultiPoint(feature.getGeometry().getCoordinates().flat(2)),
        }),
      );
      return styles;
    default:
      throw new TypeError(`Unexpected feature geometry for selected feature style: ${geometryType}`);
  }
}
