<template>
    <div>
        <a :href="props.href" class="text-blue-500 flex items-center" target="_blank" rel="noopener noreferrer">
            <slot></slot>
            <ArrowTopRightOnSquareIcon class="h-4 w-4 ml-1 mb-1" />
        </a>
    </div>
</template>

<script setup>
import { ArrowTopRightOnSquareIcon } from "@heroicons/vue/24/outline";

const props = defineProps({
    href: {
        type: String,
        required: true,
    },
});
</script>
