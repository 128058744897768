<template>
    <SchemeflowMap
        :key="props.mapKey"
        :mapKey="props.mapKey"
        isochrone-profile="select"
        :legendConfigMasterList="['site_loc', 'isochrones', 'amenities']"
        :amenities="props.amenities"
        :markReadyForExportMilliseconds="5000"
    ></SchemeflowMap>
</template>

<script setup>
import SchemeflowMap from "@/components/SchemeflowMap.vue";

const props = defineProps({
    mapKey: String,
    amenities: {
        type: Object,
        default() {
            return {};
        },
    },
});
</script>
