import busOrangeIcon from "@/assets/busOrange.svg?url";
import fatalAccidentMarker from "@/assets/accident_marker_black.svg?url";
import seriousAccidentMarker from "@/assets/accident_marker_red.svg?url";
import slightAccidentMarker from "@/assets/accident_marker_amber.svg?url";
import { Style, Stroke, Icon, RegularShape, Fill } from "ol/style";
import { MultiPoint, Polygon } from "ol/geom";

import { SFLegendItem, SFLegendIconImage, SFLegendIconRectangle, SFLegendIconLine } from "@/maps/sflegend";

// Import north arrow svgs
import northarrow_plain from "@/assets/icons/northarrows/northarrow-plain.svg?raw";
import northarrow_plain_short from "@/assets/icons/northarrows/northarrow-plain_short.svg?raw";
import northarrow_plain_plus_n from "@/assets/icons/northarrows/northarrow-plain_plus_n.svg?raw";
import northarrow_short_plus_text from "@/assets/icons/northarrows/northarrow-short_plus_text.svg?raw";
import northarrow_needle_in_circle from "@/assets/icons/northarrows/northarrow-needle_in_circle.svg?raw";
import northarrow_arrow_in_circle from "@/assets/icons/northarrows/northarrow-arrow_in_circle.svg?raw";
import northarrow_arrow_with_n_in_circle from "@/assets/icons/northarrows/northarrow-arrow_with_n_in_circle.svg?raw";
import northarrow_semi_shaded from "@/assets/icons/northarrows/northarrow-semi_shaded.svg?raw";
import northarrow_arrow_with_n_in_target from "@/assets/icons/northarrows/northarrow-arrow_with_n_in_target.svg?raw";
import northarrow_circle_with_north_line_plus_n from "@/assets/icons/northarrows/northarrow-circle_with_north_line_plus_n.svg?raw";

// Map tile URLs
export const OS_OUTDOOR_MAP_TILES_URL =
    "https://api.os.uk/maps/raster/v1/zxy/Outdoor_3857/{z}/{x}/{y}.png?key=" + import.meta.env.VITE_OS_API_KEY;
export const OS_LIGHT_MAP_TILES_URL =
    "https://api.os.uk/maps/raster/v1/zxy/Light_3857/{z}/{x}/{y}.png?key=" + import.meta.env.VITE_OS_API_KEY;
export const OS_MAP_ROAD_TILES_URL =
    "https://api.os.uk/maps/raster/v1/zxy/Road_3857/{z}/{x}/{y}.png?key=" + import.meta.env.VITE_OS_API_KEY;
export const SATELLITE_MAP_TILES_URL =
    "https://ibasemaps-api.arcgis.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}?token=" +
    import.meta.env.VITE_ESRI_API_KEY;
export const ESRI_VECTOR_TILES_URL =
    "https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles/arcgis/imagery/?type=style&token=" +
    import.meta.env.VITE_ESRI_API_KEY;
export const SANGIS_ROADS_ALL_LAYER_URL = "https://geo.sandag.org/server/rest/services/Hosted/Roads_All/FeatureServer/";
export const SANGIS_BIKEWAYS_LAYER_URL = "https://geo.sandag.org/server/rest/services/Hosted/Bikeways/FeatureServer/";
export const SANGIS_APN_PARCELS_LAYER_URL = "https://geo.sandag.org/server/rest/services/Hosted/Parcels/FeatureServer/";

export const BASEMAP_OPTIONS = {
    OSM: "OpenStreetMap",
    OSLight: "OS Light",
    OSOutdoor: "OS Outdoor",
    OSRoad: "OS Road",
    Satellite: "Satellite",
    SatelliteWithOverlays: "Satellite with Overlays",
};

// Styles
const POLYGON_TRANSLUCENT_FILL_COLOR = "rgba(255,0,0,0.15)";

export const drawStyleFunction = (feature) => {
    let geometry = feature.getGeometry();
    if (geometry.getType() === "Point") {
        let styles = [
            new Style({
                // crosshairs pointer
                image: new RegularShape({
                    stroke: new Stroke({ color: "black", width: 2 }),
                    points: 4,
                    radius: 10,
                    radius2: 0,
                    angle: 0,
                }),
            }),
        ];
        return styles;
    }
    if (geometry.getType() === "Polygon") {
        let styles = [
            new Style({
                stroke: new Stroke({
                    color: "black",
                    width: 2,
                }),
            }),
            new Style({
                // Translucent red fill for clicked points
                fill: new Fill({
                    color: POLYGON_TRANSLUCENT_FILL_COLOR,
                }),
                geometry: function (feature) {
                    // remove second-to-last point
                    const coordinates = feature.getGeometry().getCoordinates()[0];
                    const coordinates1 = coordinates.slice(0, -2);
                    const coordinates2 = coordinates.slice(-1, coordinates.length);
                    const new_coordinates = [...coordinates1, ...coordinates2];
                    return new Polygon([new_coordinates]);
                },
            }),
            // Green dots for already-drawn points (except last one)
            new Style({
                image: new RegularShape({
                    fill: new Fill({
                        color: "green",
                    }),
                    points: 4,
                    radius: 5,
                    angle: Math.PI / 4,
                }),
                geometry: function (feature) {
                    // Filter out last two points (floating point not clicked yet, and duplicate first point)
                    const coordinates = feature.getGeometry().getCoordinates()[0].slice(0, -3);
                    return new MultiPoint(coordinates);
                },
            }),
            // Red point on latest clicked point
            new Style({
                image: new RegularShape({
                    fill: new Fill({
                        color: "red",
                    }),
                    points: 4,
                    radius: 5,
                    angle: Math.PI / 4,
                }),
                geometry: function (feature) {
                    const coordinates = feature.getGeometry().getCoordinates()[0].slice(-3, -2);
                    return new MultiPoint(coordinates);
                },
            }),
            new Style({
                stroke: new Stroke({
                    color: "red",
                    width: 2,
                }),
                // filter to exclude last point, and repeat first point
                geometry: function (feature) {
                    // remove second-to-last point
                    const coordinates = feature.getGeometry().getCoordinates()[0];
                    const coordinates1 = coordinates.slice(0, -2);
                    const coordinates2 = coordinates.slice(-1, coordinates.length);
                    const new_coordinates = [...coordinates1, ...coordinates2];
                    return new Polygon([new_coordinates]);
                },
            }),
        ];
        return styles;
    }
    return false;
};

export function polygonEditStyleFunction(feature) {
    var geometry = feature.getGeometry();
    let styles;
    if (geometry.getType() === "Polygon") {
        styles = [
            new Style({
                stroke: new Stroke({
                    color: "red",
                    width: 2,
                }),
            }),
            new Style({
                // Translucent red fill for clicked points
                fill: new Fill({
                    color: POLYGON_TRANSLUCENT_FILL_COLOR,
                }),
                geometry: function (feature) {
                    const coordinates = feature.getGeometry().getCoordinates()[0];
                    return new Polygon([coordinates]);
                },
            }),
            // Red dots for already-drawn points
            new Style({
                image: new RegularShape({
                    fill: new Fill({
                        color: "red",
                    }),
                    points: 4,
                    radius: 5,
                    angle: Math.PI / 4,
                }),
                geometry: function (feature) {
                    // Filter out last two points (floating point not clicked yet, and duplicate first point)
                    const coordinates = feature.getGeometry().getCoordinates()[0];
                    return new MultiPoint(coordinates);
                },
            }),
        ];
        return styles;
    }
    return false;
}

export const highlightedPolygonStyle = new Style({
    stroke: new Stroke({
        color: "rgba(0,0,255)", // Blue
        width: 2,
        lineDash: [6, 6], // Add dashed line pattern
    }),
    fill: new Fill({
        color: "rgba(0,0,255,0.15)", // Blue, translucent
    }),
});

export const isochroneColors = [
    "#D2E3AFB2", // Green
    "#FCFCBEB2", // Yellow
    "#FCE1B2B2", // Orange
    "#FF727680", // Red
    "#B2D4F2B2", // (Soft sky blue)
    "#CBB2F2B2", // (Muted lavender purple)
    "#F2B2E1B2", // (Pastel pinkish magenta)
    "#B2F2D9B2", // (Cool mint green)
    "#F2D3B2B2", // (Light apricot)
    "#F2B2B2B2", // (Pastel dusty rose)
    "#B2C2F2B2", // (Muted periwinkle blue)
    "#E3B2F2B2", // (Soft mauve)
];

export const isochroneDefaultStyles = [
    {
        outline_style: "solid",
        outline_width: 2,
        outline_color: "#B4B4B4FF",
        show_outline: true,
        fill_color: isochroneColors[0],
        show_fill: true,
    },
    {
        outline_style: "solid",
        outline_width: 2,
        outline_color: "#B4B4B4FF",
        show_outline: true,
        fill_color: isochroneColors[1],
        show_fill: true,
    },
    {
        outline_style: "solid",
        outline_width: 2,
        outline_color: "#B4B4B4FF",
        show_outline: true,
        fill_color: isochroneColors[2],
        show_fill: true,
    },
    {
        outline_style: "solid",
        outline_width: 2,
        outline_color: "#B4B4B4FF",
        fill_color: isochroneColors[3],
        show_fill: true,
    },
    {
        outline_style: "solid",
        outline_width: 2,
        outline_color: "#B4B4B4FF",
        fill_color: isochroneColors[4],
        show_fill: true,
    },
    {
        outline_style: "solid",
        outline_width: 2,
        outline_color: "#B4B4B4FF",
        fill_color: isochroneColors[5],
        show_fill: true,
    },
    {
        outline_style: "solid",
        outline_width: 2,
        outline_color: "#B4B4B4FF",
        fill_color: isochroneColors[6],
        show_fill: true,
    },
    {
        outline_style: "solid",
        outline_width: 2,
        outline_color: "#B4B4B4FF",
        fill_color: isochroneColors[7],
        show_fill: true,
    },
    {
        outline_style: "solid",
        outline_width: 2,
        outline_color: "#B4B4B4FF",
        fill_color: isochroneColors[8],
        show_fill: true,
    },
    {
        outline_style: "solid",
        outline_width: 2,
        outline_color: "#B4B4B4FF",
        fill_color: isochroneColors[9],
        show_fill: true,
    },
    {
        outline_style: "solid",
        outline_width: 2,
        outline_color: "#B4B4B4FF",
        fill_color: isochroneColors[10],
        show_fill: true,
    },
    {
        outline_style: "solid",
        outline_width: 2,
        outline_color: "#B4B4B4FF",
        fill_color: isochroneColors[11],
        show_fill: true,
    },
];

export const amenitiesDefaultStyles = {
    supermarket: {
        img: "shopping_cart.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#f88702", // Orange
        },
    },
    shop: {
        img: "shopping_cart.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#f8b878", // Orange
        },
    },
    pharmacy: {
        img: "medication.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#1dab30", // Green
        },
    },
    post_office: {
        img: "mail.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#ff0000", // Red
        },
    },
    primary_school: {
        img: "school.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#40aefe", // Light blue
        },
    },
    secondary_school: {
        img: "school.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#fc30ef", // Pink
        },
    },
    charge_point: {
        img: "charger.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#5af904", // Light green
        },
    },
    rail_station: {
        img: "train.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#e60000", // Red (rail red)
        },
    },
    metro_station: {
        img: "train.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#800080", // Purple
        },
    },
    light_rail_station: {
        img: "train.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#87cefa", // Light blue
        },
    },
    tram_stop: {
        img: "train.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#007500", // Light green
        },
    },
    bus_stop: {
        img: "bus.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#1c28f7", // Blue
        },
    },
    ferry_port: {
        img: "boat.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#B87333", // Brown
        },
    },
    restaurant: {
        img: "restaurant.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#ffa089", // Orange
        },
    },
    cafe: {
        img: "restaurant.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#77dd77", // Teal
        },
    },
    hospital: {
        img: "hospital.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#ff0800", // Candy Apple Red
        },
    },
    clinic: {
        img: "hospital.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#32cd32", // Lime green
        },
    },
    kindergarten: {
        img: "school.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#da70d6", // orchid
        },
    },
    school: {
        img: "school.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#5f9ea0", // Cadet blue
        },
    },
    college: {
        img: "school.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#00703c", // Dark green
        },
    },
    university: {
        img: "school.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#041690", // Royal blue
        },
    },
    fire_station: {
        img: "fire_truck.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#ff2400", // Scarlet
        },
    },
    place_of_worship: {
        img: "folded_hands.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#8b008b", // Dark Magenta
        },
    },
    community_centre: {
        img: "groups_2.svg",
        width: 12,
        height: 12,
        bg: {
            color: "#ffc40c", // Orangey yellow
        },
    },
    site_location: {
        img: "map-pin.svg",
        width: 12,
        height: 21,
        anchor: [0.5, 1],
    },
    reservoir_flood_extents_dry_day: {
        img: "ea_reservoir_flood_extents_dry_day.svg",
        width: 18,
        height: 18,
    },
    reservoir_flood_extents_wet_day: {
        img: "ea_reservoir_flood_extents_wet_day.svg",
        width: 18,
        height: 18,
    },
    ea_flood_risk_zone_2: {
        img: "ea_flood_risk_zone_2.svg",
        width: 18,
        height: 18,
    },
    ea_flood_risk_zone_3: {
        img: "ea_flood_risk_zone_3.svg",
        width: 18,
        height: 18,
    },
    ea_main_rivers: {
        img: "ea_main_river.svg",
        width: 18,
        height: 18,
    },
    ea_rofsw_4band_low: {
        img: "ea_rofsw_4band_low.svg",
        width: 18,
        height: 18,
    },
    ea_rofsw_4band_medium: {
        img: "ea_rofsw_4band_medium.svg",
        width: 18,
        height: 18,
    },
    ea_rofsw_4band_high: {
        img: "ea_rofsw_4band_high.svg",
        width: 18,
        height: 18,
    },
    ea_rofsw_4band_unavailable: {
        img: "ea_rofsw_4band_unavailable.svg",
        width: 18,
        height: 18,
    },
    national_cycle_network: {
        img: "national_cycle_network.svg",
        width: 18,
        height: 18,
    },
    underground_station: {
        img: "underground_roundel.svg",
        width: 16,
        height: 13,
        bg: false,
    },
    dlr_station: {
        img: "dlr_roundel.svg",
        width: 16,
        height: 13,
        bg: false,
    },
    elizabeth_line_station: {
        img: "elizabeth_line_roundel.svg",
        width: 16,
        height: 13,
        bg: false,
    },
    overground_station: {
        img: "overground_roundel.svg",
        width: 16,
        height: 13,
        bg: false,
    },
};

export const legendDefaultLabels = {
    supermarket: "Supermarket",
    shop: "Shop",
    pharmacy: "Pharmacy",
    post_office: "Post Office",
    primary_school: "Primary School",
    secondary_school: "Secondary School",
    charge_point: "Charging Point",
    rail_station: "Rail Station",
    metro_station: "Metro Station",
    light_rail_station: "Light Rail Station",
    tram_stop: "Tram Stop",
    bus_stop: "Bus Stop",
    ferry_port: "Ferry Port",
    restaurant: "Restaurant",
    cafe: "Bar/Cafe",
    hospital: "Hospital",
    clinic: "Doctor/Clinic",
    kindergarten: "Nursery",
    school: "School",
    college: "College",
    university: "University",
    fire_station: "Fire Station",
    place_of_worship: "Place of Worship",
    community_centre: "Community Centre",
    site_location: "Site Location",
    reservoir_flood_extents_dry_day: "Reservoir Flood Extents (Dry Day)",
    reservoir_flood_extents_wet_day: "Reservoir Flood Extents (Wet Day)",
    ea_flood_risk_zone_2: "Flood Zone 2",
    ea_flood_risk_zone_3: "Flood Zone 3",
    ea_main_rivers: "Main River",
    ea_rofsw_4band_unavailable: "Unavailable",
    ea_rofsw_4band_low: "0.1% AEP (low)",
    ea_rofsw_4band_medium: "1% AEP (medium)",
    ea_rofsw_4band_high: "3.3% AEP (high)",
    national_cycle_network: "National Cycle Network",
    underground_station: "Underground Station",
    dlr_station: "DLR Station",
    elizabeth_line_station: "Elizabeth Line Station",
    overground_station: "Overground Station",
    non_london_metro_station: "Metro Station",
};

export const highlightedBusStopStyle = new Style({
    image: new Icon({
        src: busOrangeIcon,
        width: 16,
        height: 16,
    }),
});

export const redlineStyle = new Style({
    stroke: new Stroke({
        color: "#DB1B10",
        width: 2,
    }),
});

export const roadSidesStyle = new Style({
    stroke: new Stroke({
        color: "#8F8F8F",
        width: 7,
    }),
});

export const osRoadStyles = {
    motorway: [
        roadSidesStyle,
        new Style({
            stroke: new Stroke({
                color: "#06B1CA",
                width: 5,
            }),
        }),
    ],
    primary_road: [
        roadSidesStyle,
        new Style({
            stroke: new Stroke({
                color: "#37C256",
                width: 5,
            }),
        }),
    ],
    a_road: [
        roadSidesStyle,
        new Style({
            stroke: new Stroke({
                color: "#FF889D",
                width: 5,
            }),
        }),
    ],
    b_road: [
        roadSidesStyle,
        new Style({
            stroke: new Stroke({
                color: "#FFC073",
                width: 5,
            }),
        }),
    ],
    minor_road: [
        roadSidesStyle,
        new Style({
            stroke: new Stroke({
                color: "#FEF2B4",
                width: 5,
            }),
        }),
    ],
    local_road: [
        roadSidesStyle,
        new Style({
            stroke: new Stroke({
                color: "white",
                width: 5,
            }),
        }),
    ],
};

// Legend items
export const siteBoundaryLegendItem = new SFLegendItem({
    title: "Site Boundary",
    icon: new SFLegendIconRectangle({
        fillOpacity: 0.0,
        outlineColor: "#DB1B10",
    }),
});

export const highwayMapLegendItems = [
    new SFLegendItem({
        title: "Motorway",
        icon: new SFLegendIconLine({
            fillColor: osRoadStyles.motorway[1].getStroke().getColor(),
        }),
    }),
    new SFLegendItem({
        title: "Primary Road",
        icon: new SFLegendIconLine({
            fillColor: osRoadStyles.primary_road[1].getStroke().getColor(),
        }),
    }),
    new SFLegendItem({
        title: "A Road",
        icon: new SFLegendIconLine({
            fillColor: osRoadStyles.a_road[1].getStroke().getColor(),
        }),
    }),
    new SFLegendItem({
        title: "B Road",
        icon: new SFLegendIconLine({
            fillColor: osRoadStyles.b_road[1].getStroke().getColor(),
        }),
    }),
    new SFLegendItem({
        title: "Minor Road",
        icon: new SFLegendIconLine({
            fillColor: osRoadStyles.minor_road[1].getStroke().getColor(),
        }),
    }),
    new SFLegendItem({
        title: "Local Road",
        icon: new SFLegendIconLine({
            fillColor: osRoadStyles.local_road[1].getStroke().getColor(),
        }),
    }),
];

// US paper size aspect ratios based on 1 inch margin on all sides
// Letter: 6.5✕9 inches
// Legal: 6.5✕12 inches
export const aspectRatios = {
    "16:9": 1.777777777778,
    "4:3": 1.333333333333,
    "1:1": 1,
    a4_landscape: 1.4142135624,
    a4_portrait: 0.7071067812,
    us_letter_landscape: 1.3846153846,
    us_letter_portrait: 0.7222222222,
    us_legal_landscape: 1.8461538462,
    us_legal_portrait: 0.5416666667,
    "580:612": 0.9477124183,
};

export const isochroneDefaults = {
    walk: {
        profile: "pedestrian",
        range_list: [5, 10, 15],
        range_type: "time",
        show_isochrones: true,
    },
    cycle: {
        profile: "bicycle",
        range_list: [10, 20, 30],
        range_type: "time",
        show_isochrones: true,
    },
    drive: {
        profile: "auto",
        range_list: [10, 20, 30],
        range_type: "time",
        show_isochrones: true,
    },
    select: {
        profile: "pedestrian",
        range_list: [5, 10, 15],
        range_type: "time",
        show_isochrones: false,
    },
};

// Default maximum zoom levels for maps
// Initial map view when scheme is created is based on required features on the
// map.
// But satellite map will not load base map if resolution is too high (low
// metres/pixel value from getResolution() = high zoom value)
// And Highways map should take a broader view of site context. More intelligent
// approach would analyse nearby road types and set map view intelligently using
// bbox_to_fit, but simple version is just to zoom out quite a bit
export const maxInitialZoomLevel = {
    satellite_map: 18.0,
    highways_map: 16.5,
};

export const accidentMarker = {
    fatal: new Style({
        image: new Icon({
            anchor: [0.5, 1],
            src: fatalAccidentMarker,
            height: 20,
            width: 12,
        }),
    }),
    serious: new Style({
        image: new Icon({
            anchor: [0.5, 1],
            src: seriousAccidentMarker,
            height: 20,
            width: 12,
        }),
    }),
    slight: new Style({
        image: new Icon({
            anchor: [0.5, 1],
            src: slightAccidentMarker,
            height: 20,
            width: 12,
        }),
    }),
};

export const accidentLegendItems = {
    fatal: new SFLegendItem({
        title: "Fatal Collision",
        icon: new SFLegendIconImage({
            src: fatalAccidentMarker,
            height: 20,
            width: 12,
        }),
    }),
    serious: new SFLegendItem({
        title: "Serious Collision",
        icon: new SFLegendIconImage({
            src: seriousAccidentMarker,
            height: 20,
            width: 12,
        }),
    }),
    slight: new SFLegendItem({
        title: "Slight Collision",
        icon: new SFLegendIconImage({
            src: slightAccidentMarker,
            height: 20,
            width: 12,
        }),
    }),
};

export const basemapLayers = {
    OSRoad: {
        name: "OS Road",
        source: {
            title: "Ordnance Survey (OS Road style via OS Maps API)",
            link: "https://osdatahub.os.uk/docs/wmts/technicalSpecification",
        },
    },
    OSOutdoor: {
        name: "OS Outdoor",
        source: {
            title: "Ordnance Survey (OS Outdoor style via OS Maps API)",
            link: "https://osdatahub.os.uk/docs/wmts/technicalSpecification",
        },
    },
    OSLight: {
        name: "OS Light",
        source: {
            title: "Ordnance Survey (OS Light style via OS Maps API)",
            link: "https://osdatahub.os.uk/docs/wmts/technicalSpecification",
        },
    },
    Satellite: {
        name: "Satellite",
        source: {
            title: "Esri (World Imagery via ArcGIS Mapping API)",
            link: "https://developers.arcgis.com/documentation/mapping-apis-and-services/",
        },
    },
    SatelliteWithOverlays: {
        name: "Satellite with Overlays",
        source: {
            title: "Esri (World Imagery via ArcGIS Mapping API)",
            link: "https://developers.arcgis.com/documentation/mapping-apis-and-services/",
        },
    },
    OSM: {
        name: "OpenStreetMap",
        source: {
            title: "OpenStreetMap (Carto style)",
            link: "https://www.openstreetmap.org/",
        },
    },
};

export const overlaySelectLayers = ["sangis_roads", "sangis_bikeways", "sangis_apn_parcels"];

// North arrows
export const northArrows = {
    plain: northarrow_plain,
    plain_short: northarrow_plain_short,
    plain_plus_n: northarrow_plain_plus_n,
    short_plus_text: northarrow_short_plus_text,
    needle_in_circle: northarrow_needle_in_circle,
    arrow_in_circle: northarrow_arrow_in_circle,
    arrow_with_n_in_circle: northarrow_arrow_with_n_in_circle,
    semi_shaded: northarrow_semi_shaded,
    arrow_with_n_in_target: northarrow_arrow_with_n_in_target,
    circle_with_north_line_plus_n: northarrow_circle_with_north_line_plus_n,
};

export const northArrowColor = {
    black: "#000000",
    darkgrey: "#4b5255",
    midgrey: "#7e7e7d",
    lightgrey: "#dededf",
    white: "#ffffff",
};
