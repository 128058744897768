import { ref, computed, inject } from "vue";
import { defineStore } from "pinia";
import { generateBusTableHTML, generateBusTableXML } from "@/analyses/busTimetables";
import {
    generateRailStationsTableHTML,
    generateRailStationsXMLString,
    generateRailServicesTableHTML,
    generateRailServicesXMLString,
    generateRailStationAmenitiesHTML,
    generateUndergroundServicesTableHTML,
} from "../analyses/railStations";
import { generateCollisionSummaryTable } from "@/analyses/collisions";
import { highlightedBusStopStyle, isochroneDefaultStyles } from "../maps/constants";
import { formatTimeAsLocalTime } from "@/utils";
import { isochroneDistanceDisplayUnits } from "@/constants";

import { Vector as VectorSource } from "ol/source";
import { Style } from "ol/style";
import { SFLegendItem, SFLegendIconRectangle } from "@/maps/sflegend";

import { useEventBus } from "@vueuse/core";

export const useFormDataStore = defineStore("FormData", () => {
    const axios = inject("axios");
    const formData = ref({});
    const isLoading = ref(false);
    const isSaving = ref(false);
    const lastModified = ref(null);
    const maps = ref({});

    const errorBus = useEventBus("error");

    const longitude = computed(() => {
        if (formData.value.latlong) {
            return formData.value.latlong.split(",")[1].trim();
        } else {
            return -1.9028874721719107; // Default location = Swallow Street
        }
    });

    const latitude = computed(() => {
        if (formData.value.latlong) {
            return formData.value.latlong.split(",")[0].trim();
        } else {
            return 52.47869083180635; // Default location = Swallow Street
        }
    });

    // National Rail Stations tables
    const railStationsTableHTML = computed(() => {
        return generateRailStationsTableHTML(formData.value?.rail_stations?.national_rail_stations ?? []);
    });
    const railStationsTableXML = computed(() => {
        return generateRailStationsXMLString(formData.value?.rail_stations?.national_rail_stations ?? []);
    });
    const railServicesTableHTML = computed(() => {
        return generateRailServicesTableHTML(formData.value?.rail_stations?.national_rail_stations ?? []);
    });
    const railServicesTableXML = computed(() => {
        return generateRailServicesXMLString(formData.value?.rail_stations?.national_rail_stations ?? []);
    });
    const railStationAmenitiesHTML = computed(() => {
        return generateRailStationAmenitiesHTML(formData.value?.rail_stations?.national_rail_stations ?? []);
    });

    // London Underground tables
    const undergroundStationsTableHTML = computed(() => {
        return generateRailStationsTableHTML(formData.value?.rail_stations?.london_underground_stations ?? []);
    });
    const undergroundStationsTableXML = computed(() => {
        return generateRailStationsXMLString(
            formData.value?.rail_stations?.london_underground_stations ?? [],
            "london_underground_stations_table"
        );
    });
    const undergroundServicesTableHTML = computed(() => {
        return generateUndergroundServicesTableHTML(formData.value?.rail_stations?.london_underground_stations ?? []);
    });

    // London DLR tables
    const dlrStationsTableHTML = computed(() => {
        return generateRailStationsTableHTML(formData.value?.rail_stations?.london_dlr_stations ?? []);
    });
    const dlrStationsTableXML = computed(() => {
        return generateRailStationsXMLString(
            formData.value?.rail_stations?.london_dlr_stations ?? [],
            "london_dlr_stations_table"
        );
    });

    // London Elizabeth Line tables
    const elizabethLineStationsTableHTML = computed(() => {
        return generateRailStationsTableHTML(formData.value?.rail_stations?.london_elizabeth_line_stations ?? []);
    });
    const elizabethLineStationsTableXML = computed(() => {
        return generateRailStationsXMLString(
            formData.value?.rail_stations?.london_elizabeth_line_stations ?? [],
            "london_elizabeth_line_stations_table"
        );
    });
    const elizabethLineServicesTableHTML = computed(() => {
        return generateRailServicesTableHTML(formData.value?.rail_stations?.london_elizabeth_line_stations ?? []);
    });
    const elizabethLineServicesTableXML = computed(() => {
        return generateRailServicesXMLString(
            formData.value?.rail_stations?.london_elizabeth_line_stations ?? [],
            "london_elizabeth_line_services_table"
        );
    });

    // London Overground tables
    const overgroundStationsTableHTML = computed(() => {
        return generateRailStationsTableHTML(formData.value?.rail_stations?.london_overground_stations ?? []);
    });
    const overgroundStationsTableXML = computed(() => {
        return generateRailStationsXMLString(
            formData.value?.rail_stations?.london_overground_stations ?? [],
            "london_overground_stations_table"
        );
    });
    const overgroundServicesTableHTML = computed(() => {
        return generateRailServicesTableHTML(formData.value?.rail_stations?.london_overground_stations ?? []);
    });
    const overgroundServicesTableXML = computed(() => {
        return generateRailServicesXMLString(
            formData.value?.rail_stations?.london_overground_stations ?? [],
            "london_overground_services_table"
        );
    });

    // Non-london metro tables
    const metroStationsTableHTML = computed(() => {
        return generateRailStationsTableHTML(formData.value?.rail_stations?.non_london_metro_stations ?? []);
    });
    const metroStationsTableXML = computed(() => {
        return generateRailStationsXMLString(
            formData.value?.rail_stations?.non_london_metro_stations ?? [],
            "metro_stations_table"
        );
    });

    const collisionSummaryTableHTML = computed(() => {
        return generateCollisionSummaryTable({
            slight: formData.value.slight_collisions,
            serious: formData.value.serious_collisions,
            fatal: formData.value.fatal_collisions,
            total: formData.value.total_collisions,
        });
    });

    async function loadFields(id = null) {
        // If id paramter is given, use it to select project, else
        // use the current route
        if (id == null) {
            id = this.router.currentRoute.value.params.id;
        }

        const apiUrl = `/api/projects/${id}`;

        // Set loading state to true
        isLoading.value = true;

        let response = await axios
            .get(apiUrl)
            .catch((error) => {
                console.error("Error fetching data:", error);
                if (error.response.status === 404) {
                    this.router.push({ name: "home" });
                }
                errorBus.emit("Error loading project. Please try again.");
            })
            .finally(() => {
                // Set loading state to false when the response is received
                isLoading.value = false;
            });

        // Update the form data with the received JSON response
        formData.value = response.data;
        lastModified.value = formatTimeAsLocalTime(response.data.user_last_modified);
    }

    async function patchRecord(id = null) {
        // If id paramter is given, use it to select project, else
        // use the current route
        if (id == null) {
            id = this.router.currentRoute.value.params.id;
        }

        const apiUrl = `/api/projects/${id}`;

        isSaving.value = true;

        // Send a PATCH request with the payload
        return axios
            .patch(apiUrl, formData.value)
            .then((response) => {
                lastModified.value = formatTimeAsLocalTime(response.data.user_last_modified);
            })
            .catch((error) => {
                console.error("Error sending PATCH request:", error);
                if (error.response.status === 404) {
                    this.router.push({ name: "home" });
                }

                errorBus.emit("Error saving project. Please try again.");
            })
            .finally(() => {
                isSaving.value = false;
            });
    }

    const fetchBusStops = async () => {
        const apiUrl = "/api/amenities/bus_stops";
        const [min_longitude, min_latitude, max_longitude, max_latitude] = maps.value.bus_stops_map
            .getMap()
            .getView()
            .calculateExtent();
        const params = {
            latitude: formData.value.analysis_latitude,
            longitude: formData.value.analysis_longitude,
            min_longitude: min_longitude,
            min_latitude: min_latitude,
            max_longitude: max_longitude,
            max_latitude: max_latitude,
        };
        loadingBusStops.value = true;
        let response = await axios
            .get(apiUrl, { params: params })
            .catch((error) => {
                console.error("Error sending GET request:", error);
                errorBus.emit("Error fetching bus stops. Please try again.");
            })
            .finally(() => (loadingBusStops.value = false));

        formData.value.bus_stops_map.bus_stops_geojson = response.data;
        const amenities_geojson = {
            bus_stop: formData.value.bus_stops_map.bus_stops_geojson,
        };
        maps.value.bus_stops_map.setAmenities(amenities_geojson);
    };

    const fetchIsochrones = async (mapKey, options = {}) => {
        const profile = formData.value[mapKey].isochrone_config.profile;
        const range_list = formData.value[mapKey].isochrone_config.range_list;
        const range_type = formData.value[mapKey].isochrone_config.range_type;
        const walking_speed = formData.value[mapKey].isochrone_config.costing_options.walking_speed;
        const cycling_speed = formData.value[mapKey].isochrone_config.costing_options.cycling_speed;

        // If isochrone config has origin lat and long set, use them, else use
        // default site location point
        let origin_latitude =
            formData.value[mapKey].isochrone_config.origin_latitude || formData.value.analysis_latitude;
        let origin_longitude =
            formData.value[mapKey].isochrone_config.origin_longitude || formData.value.analysis_longitude;

        const params = {
            latitude: origin_latitude,
            longitude: origin_longitude,
            profile: profile,
            range: range_list,
            range_type: range_type,
            walking_speed: walking_speed,
            cycling_speed: cycling_speed,
        };
        isochroneIsGenerating.value = true;
        return axios
            .get("/api/isochrone", {
                params: params,
                paramsSerializer: { indexes: null },
            })
            .then((response) => {
                const isochrones = response.data;

                formData.value[mapKey].isochrone_geojson = isochrones;
            })
            .catch((error) => {
                if (error.response.data.detail === "Valhalla could not compute isochrone") {
                    console.error("Valhalla vector index bug/error. Speed/time combo returned error:", error);

                    if (!options.suppressErrors) {
                        errorBus.emit(
                            "The requested isochrone could not be computed. Please reduce the speed or time and try again."
                        );
                    }
                } else {
                    console.error("Error sending GET request:", error);

                    if (!options.suppressErrors) {
                        errorBus.emit("Error fetching isochrones. Please try again.");
                    }
                }
            })
            .finally(() => {
                isochroneIsGenerating.value = false;
            });
    };

    const updateIsochroneLegend = (mapKey) => {
        const profile = formData.value[mapKey].isochrone_config.profile;
        const range_list = formData.value[mapKey].isochrone_config.range_list;
        const range_type = formData.value[mapKey].isochrone_config.range_type;
        const distanceDisplayUnit = formData.value[mapKey].isochrone_config.distance_display_unit;

        const profileToTravelMode = {
            pedestrian: "walk",
            bicycle: "cycle",
            auto: "drive",
        };

        const isochroneLegendItems = [];

        if (formData.value[mapKey].isochrone_config.show_isochrones) {
            for (let idx = 0; idx < range_list.length; idx++) {
                let title = "";

                if (range_type == "time") {
                    title += `${range_list[idx]} min `;
                } else {
                    title += `${range_list[idx] * isochroneDistanceDisplayUnits[distanceDisplayUnit].conversion} ${isochroneDistanceDisplayUnits[distanceDisplayUnit].symbol} `;
                }

                title += profileToTravelMode[profile];

                const style = formData.value[mapKey].isochrone_styles[idx] ?? isochroneDefaultStyles[idx];

                isochroneLegendItems.push(
                    new SFLegendItem({
                        title: title,
                        icon: new SFLegendIconRectangle({
                            fillColor: style.fill_color.substring(0, 7),
                            fillOpacity: style.show_fill ? parseInt(style.fill_color.substring(7, 9), 16) / 255 : 0.0,
                            outlineColor: style.outline_color.substring(0, 7),
                            outlineStyle: style.show_outline ? style.outline_style : "none",
                            outlineWidth: 2,
                        }),
                    })
                );
            }
        }

        maps.value[mapKey].legend_config.isochrones = isochroneLegendItems;
    };

    const loadingRailStations = ref(null);
    const loadingRailStationAmenitiesSummary = ref(null);
    const loadingBusStops = ref(null);
    const loadingBusTimetables = ref(null);

    async function fetchRailStations() {
        const [minLong, minLat, maxLong, maxLat] = maps.value.rail_stations_map.getMap().getView().calculateExtent();

        loadingRailStations.value = true;

        axios
            .get("/api/amenities/rail_services", {
                params: {
                    site_latitude: formData.value.analysis_latitude,
                    site_longitude: formData.value.analysis_longitude,
                    min_longitude: minLong,
                    min_latitude: minLat,
                    max_longitude: maxLong,
                    max_latitude: maxLat,
                },
            })
            .then((response) => {
                formData.value.rail_stations = response.data;
            })
            .catch((error) => {
                console.error("Error sending GET request:", error);
                errorBus.emit("Error fetching rail stations. Please try again.");
            })
            .finally(() => {
                loadingRailStations.value = false;
            });
    }

    async function fetchBusTimetables() {
        const apiUrl = "/api/bus_timetables/";
        loadingBusTimetables.value = true;
        const hiddenBusStops = formData.value.bus_timetables.filter((stop) => !stop.included).map((stop) => stop.id);
        const hiddenBusStopsSet = new Set(hiddenBusStops);

        let stops = [];
        const extent = maps.value.bus_stops_map.getMap().getView().calculateExtent();
        maps.value.bus_stops_map.busStopsSource.forEachFeatureInExtent(extent, function (feature) {
            stops.push(feature.getId());
        });

        await axios
            .post(apiUrl, {
                stops: stops,
                latitude: formData.value.analysis_latitude,
                longitude: formData.value.analysis_longitude,
            })
            .then((response) => {
                let bus_timetables = response.data.stops;
                // Loop over new bus timetables
                // Show each stop on the map
                // Check if it was previously hidden - if so, hide it
                // TODO hide hidden stops when loading map from scratch
                bus_timetables.forEach((stop) => {
                    maps.value.bus_stops_map.busStopsSource.getFeatureById(stop.id).setProperties({ visible: true });
                    if (hiddenBusStopsSet.has(stop.id)) {
                        stop.included = false;
                        maps.value.bus_stops_map.busStopsSource
                            .getFeatureById(stop.id)
                            .setProperties({ visible: false });
                    }
                });
                formData.value.bus_timetables = bus_timetables;
            })
            .catch((error) => {
                console.error("Error sending POST request:", error);

                errorBus.emit("Error fetching bus timetables. Please try again.");
            })
            .finally(() => {
                loadingBusTimetables.value = false;
            });
    }

    async function refreshBusServices() {
        const zoom = maps.value.bus_stops_map.getZoom();
        if (zoom >= 14) {
            await fetchBusTimetables();
        } else {
            formData.value.bus_timetables = [];
        }
    }

    const redlinePolygonSource = new VectorSource({ wrapX: false });

    /////////////////////////
    // Bus Timetables      //
    /////////////////////////

    const busTableHTML = computed(() => {
        return generateBusTableHTML(formData.value.bus_timetables);
    });

    const busTableXML = computed(() => {
        return generateBusTableXML(formData.value.bus_timetables);
    });

    const highlightedBusStop = ref(null);

    const highlightBusStop = (atcocode) => {
        // Highlight bus stop on map
        maps.value.bus_stops_map.busStopsSource.getFeatureById(atcocode).setStyle(highlightedBusStopStyle);

        // Highlight bus stop in list
        highlightedBusStop.value = atcocode;
    };

    const resetBusStopStyle = (atcocode) => {
        // Reset style of bus stop on map
        let feature = maps.value.bus_stops_map.busStopsSource.getFeatureById(atcocode);

        if (feature.get("visible")) {
            feature.setStyle(null);
        } else {
            feature.setStyle(new Style({}));
        }
    };

    const unhighlightBusStop = (atcocode) => {
        // Reset style of bus stop on map
        resetBusStopStyle(atcocode);

        // unhighlight bus stop in list
        highlightedBusStop.value = null;
    };

    const deduplicateBusStops = () => {
        const uniqueServiceMap = new Map(); // To track unique services

        // For each bus stop, check all the services
        // If the service is the first appearance of that service, include the service AND include the bus stop
        // else exclude the service
        // (i.e bus stops only require ONE included service for the stop be included)

        // TODO when a user unchecks bus stop #1, how do we re-include the services that were hidden?
        // idea 1 - checkboxes/toggles for each service on each stop
        // idea 2 - always (re-)dedupe services AFTER deduping stops

        // => go with idea 2 for now
        formData.value.bus_timetables.forEach((busStop) => {
            busStop.included = false;

            busStop.services.forEach((service) => {
                const serviceId = `${service.operator_code}-${service.route_short_name}-${service.route_description}`;
                // If this service hasn't been seen before
                if (!uniqueServiceMap.has(serviceId)) {
                    uniqueServiceMap.set(serviceId, true); // Mark this service as seen (add to uniqueServiceMap)
                    busStop.included = true;
                    service.included = true;
                } else {
                    service.included = false;
                }
            });

            // Update bus stop on map
            maps.value.bus_stops_map.busStopsSource
                .getFeatureById(busStop.id)
                .setProperties({ visible: busStop.included });
            resetBusStopStyle(busStop.id);
        });
    };

    const fetchCollisionLocations = async (regionConfig) => {
        const apiUrl = regionConfig.apiUrls.collisions_source;

        // If there is a distinct analysis point set for accident map, use it.
        // Otherwise, use the scheme analysis point
        const accidentAnalysisLocation = {
            latitude: formData.value.collision_map?.analysis_location?.latitude ?? formData.value.analysis_latitude,
            longitude: formData.value.collision_map?.analysis_location?.longitude ?? formData.value.analysis_longitude,
        };

        const params = {
            latitude: accidentAnalysisLocation.latitude,
            longitude: accidentAnalysisLocation.longitude,
            radius: formData.value.collision_map?.radius ?? 300,
        };

        fetchingCollisionLocations.value = true;

        axios
            .get(apiUrl, { params: params })
            .then((response) => {
                let collisions_geojson = response.data;

                if (!collisions_geojson?.features.length) {
                    // If no accident locations returned, set stored value to
                    // null and remove any current accidents from map
                    formData.value.collision_locations = null;
                    maps.value.collision_map.clearRoadAccidents();
                } else {
                    // If accident locations returned, store accident locations
                    // and set on map
                    formData.value.collision_locations = collisions_geojson;
                    maps.value.collision_map.setRoadAccidents(collisions_geojson);
                }

                // Update numbers of collisions by category
                const accidentCounter = {
                    fatal: 0,
                    serious: 0,
                    slight: 0,
                };

                collisions_geojson.features.forEach((feature) => {
                    ++accidentCounter[feature.properties.severity];
                });

                formData.value.fatal_collisions = accidentCounter.fatal;
                formData.value.serious_collisions = accidentCounter.serious;
                formData.value.slight_collisions = accidentCounter.slight;
                formData.value.total_collisions =
                    accidentCounter.fatal + accidentCounter.serious + accidentCounter.slight;
            })
            .finally(() => (fetchingCollisionLocations.value = false));
    };

    const fetchingCollisionLocations = ref(false);

    const isochroneIsGenerating = ref(false);

    const mapsReadyForExport = ref({});

    const snippets = ref([]);
    const loadingSnippets = ref(false);
    const totalSnippets = ref(null);

    async function searchSnippets(searchQuery, snippetType, resultCount = 10, resultPage = 1, chapter = null) {
        loadingSnippets.value = true;

        const apiUrl = "/api/snippets/search";

        const params = {
            snippet_type: snippetType,
            search_query: searchQuery ?? "",
            result_count: resultCount,
            result_page: resultPage,
            report_chapter: chapter,
        };

        axios
            .get(apiUrl, { params: params })
            .then((response) => {
                snippets.value = response.data.snippets;
                totalSnippets.value = response.data.count;
            })
            .catch((error) => {
                console.error("Error sending GET request:", error);
                errorBus.emit("Error fetching snippets. Please try again.");
            })
            .finally(() => {
                loadingSnippets.value = false;
            });
    }

    async function deleteSnippet(snippetId) {
        const apiUrl = `/api/snippets/${snippetId}`;

        try {
            await axios.delete(apiUrl);

            // Delete from stored snippets list, which will trigger a UI update
            const idx = snippets.value.findIndex((snippet) => snippet.snippet_id == snippetId);
            if (idx == -1) {
                console.error(`Snippet ${snippetId} to be deleted not found.`);
            } else {
                snippets.value.splice(idx, 1);
            }
        } catch (error) {
            console.error(`Error deleting snippet ${snippetId}, DELETE request:`, error);
            errorBus.emit("Error deleting snippet. Please try again.");
        }
    }

    return {
        mapsReadyForExport,
        loadingRailStations,
        loadingRailStationAmenitiesSummary,
        loadingBusStops,
        loadingBusTimetables,
        fetchIsochrones,
        updateIsochroneLegend,
        redlinePolygonSource,
        formData,
        isLoading,
        isSaving,
        longitude,
        latitude,
        lastModified,
        loadFields,
        patchRecord,
        fetchBusStops,
        fetchBusTimetables,
        fetchRailStations,
        maps,
        railStationsTableHTML,
        railStationsTableXML,
        railServicesTableHTML,
        railServicesTableXML,
        undergroundStationsTableHTML,
        undergroundStationsTableXML,
        undergroundServicesTableHTML,
        dlrStationsTableHTML,
        dlrStationsTableXML,
        elizabethLineStationsTableHTML,
        elizabethLineStationsTableXML,
        elizabethLineServicesTableHTML,
        elizabethLineServicesTableXML,
        overgroundStationsTableHTML,
        overgroundStationsTableXML,
        overgroundServicesTableHTML,
        overgroundServicesTableXML,
        metroStationsTableHTML,
        metroStationsTableXML,
        railStationAmenitiesHTML,
        busTableHTML,
        busTableXML,
        collisionSummaryTableHTML,
        refreshBusServices,
        highlightedBusStop,
        highlightBusStop,
        unhighlightBusStop,
        deduplicateBusStops,
        isochroneIsGenerating,
        fetchCollisionLocations,
        fetchingCollisionLocations,
        snippets,
        loadingSnippets,
        totalSnippets,
        searchSnippets,
        deleteSnippet,
    };
});
