<template>
    <SchemeflowMap
        mapKey="rail_stations_map"
        isochrone-profile="select"
        :legendConfigMasterList="['site_loc', 'isochrones', 'rail_stations', 'buffer_zones']"
        :markReadyForExportMilliseconds="5000"
    />
</template>

<script setup>
import { useFormDataStore } from "@/stores/FormDataStore";
import SchemeflowMap from "@/components/SchemeflowMap.vue";
import { onMounted, watch } from "vue";
import { legendItemFromConfig } from "@/maps/configIcons";

const store = useFormDataStore();

onMounted(() => {
    // Include rail stations and metro stops overlay layers
    store.maps.rail_stations_map.toggleOverlayLayer("rail_stations", true);
    store.maps.rail_stations_map.toggleOverlayLayer("london_underground_stations", true);
    store.maps.rail_stations_map.toggleOverlayLayer("london_dlr_stations", true);
    store.maps.rail_stations_map.toggleOverlayLayer("london_elizabeth_line_stations", true);
    store.maps.rail_stations_map.toggleOverlayLayer("london_overground_stations", true);
    store.maps.rail_stations_map.toggleOverlayLayer("non_london_metro_stations", true);

    // Fetch station data when map moves
    store.maps.rail_stations_map.getMap().on("moveend", () => {
        store.maps.rail_stations_map.getMap().once("rendercomplete", store.fetchRailStations);
    });

    // Update legend based on whether stations or tram/metro stops are on map
    watch(
        [
            () => store.formData.rail_stations,
            () => store.formData.rail_stations_map.styles.site_marker,
            () => store.formData.rail_stations_map.styles.site_boundary,
        ],
        ([newStations]) => {
            store.maps.rail_stations_map.legend_config.rail_stations = [
                ...(newStations?.national_rail_stations && newStations?.national_rail_stations.length > 0
                    ? [legendItemFromConfig({ item: "rail_station", mapKey: "rail_stations_map" })]
                    : []),
                ...(newStations?.london_underground_stations && newStations?.london_underground_stations.length > 0
                    ? [legendItemFromConfig({ item: "underground_station", mapKey: "rail_stations_map" })]
                    : []),
                ...(newStations?.london_dlr_stations && newStations?.london_dlr_stations.length > 0
                    ? [legendItemFromConfig({ item: "dlr_station", mapKey: "rail_stations_map" })]
                    : []),
                ...(newStations?.london_elizabeth_line_stations &&
                newStations?.london_elizabeth_line_stations.length > 0
                    ? [legendItemFromConfig({ item: "elizabeth_line_station", mapKey: "rail_stations_map" })]
                    : []),
                ...(newStations?.london_overground_stations && newStations?.london_overground_stations.length > 0
                    ? [legendItemFromConfig({ item: "overground_station", mapKey: "rail_stations_map" })]
                    : []),
                ...(newStations?.non_london_metro_stations && newStations?.non_london_metro_stations.length > 0
                    ? [legendItemFromConfig({ item: "metro_station", mapKey: "rail_stations_map" })]
                    : []),
            ];
        },
        {
            immediate: true,
        }
    );
});
</script>
