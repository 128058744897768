import { FeatureCollection } from '@turf/helpers'
import { Style, Fill, Stroke, Circle as CircleStyle, RegularShape as RegularShapeStyle } from 'ol/style'

export enum AdditionalLayerShape {
  CIRCLE = 'circle',
  SQUARE = 'square',
  TRIANGLE = 'triangle',
}

export interface AdditionalLayerStyle {
  shape?: AdditionalLayerShape;
  radius?: number;
  fill_color: string;
  outline_color: string;
  outline_width: number;
}

export enum AdditionalLayerType {
  POINT = 'Point',
  LINE = 'LineString',
  POLYGON = 'Polygon',
}


export default interface SchemeflowAdditionalDrawingLayer {
  id: string;
  name: string;
  style: AdditionalLayerStyle;
  type: AdditionalLayerType;
  features: FeatureCollection;
}


export function generateOpenlayersStylesFromAdditionalLayer(additionalLayer: SchemeflowAdditionalDrawingLayer): Style[] {

  const styles: Style[] = [];

  switch (additionalLayer.type) {
    case AdditionalLayerType.POINT:
      switch (additionalLayer.style.shape) {
        case AdditionalLayerShape.CIRCLE:
          return generateCirclePointStyleFromAdditionalLayer(additionalLayer);
        case AdditionalLayerShape.SQUARE:
          return generateSquarePointStyleFromAdditionalLayer(additionalLayer);
        case AdditionalLayerShape.TRIANGLE:
          return generateTrianglePointStyleFromAdditionalLayer(additionalLayer);
      }
      break;
    case AdditionalLayerType.POLYGON:
      return generatePolygonStyleFromAdditionalLayer(additionalLayer);
    case AdditionalLayerType.LINE:
      return generateLineStyleFromAdditionalLayer(additionalLayer);
    default:
      break;
  }
  return styles;
}

export function generateCirclePointStyleFromAdditionalLayer(additionalLayer: SchemeflowAdditionalDrawingLayer): Style[] {
  const styles: Style[] = [];
  const stroke = additionalLayer.style.outline_width > 0 ? new Stroke({ color: additionalLayer.style.outline_color, width: additionalLayer.style.outline_width }) : undefined;
  styles.push(new Style({
    image: new CircleStyle({
      radius: additionalLayer.style.radius,
      fill: new Fill({ color: additionalLayer.style.fill_color }),
      stroke: stroke,
    }),
  }));
  return styles;
}

export function generateSquarePointStyleFromAdditionalLayer(additionalLayer: SchemeflowAdditionalDrawingLayer): Style[] {
  const styles: Style[] = [];
  const stroke = additionalLayer.style.outline_width > 0 ? new Stroke({ color: additionalLayer.style.outline_color, width: additionalLayer.style.outline_width }) : undefined;
  styles.push(new Style({
    image: new RegularShapeStyle({
      points: 4,
      radius: additionalLayer.style.radius,
      fill: new Fill({ color: additionalLayer.style.fill_color }),
      stroke: stroke,
      rotation: Math.PI / 4,
    }),
  }));
  return styles;
}

export function generateTrianglePointStyleFromAdditionalLayer(additionalLayer: SchemeflowAdditionalDrawingLayer): Style[] {
  const styles: Style[] = [];
  const stroke = additionalLayer.style.outline_width > 0 ? new Stroke({ color: additionalLayer.style.outline_color, width: additionalLayer.style.outline_width }) : undefined;
  styles.push(new Style({
    image: new RegularShapeStyle({
      points: 3,
      radius: additionalLayer.style.radius,
      fill: new Fill({ color: additionalLayer.style.fill_color }),
      stroke: stroke,
      angle: 0,
    }),
  }));
  return styles;
}

export function generatePolygonStyleFromAdditionalLayer(additionalLayer: SchemeflowAdditionalDrawingLayer): Style[] {
  const stroke = additionalLayer.style.outline_width > 0 ? new Stroke({ color: additionalLayer.style.outline_color, width: additionalLayer.style.outline_width }) : undefined;
  const styles: Style[] = [
    new Style({
      fill: new Fill({ color: additionalLayer.style.fill_color }),
    }),
    new Style({
      stroke: stroke,
    }),
  ];
  return styles;
}

export function generateLineStyleFromAdditionalLayer(additionalLayer: SchemeflowAdditionalDrawingLayer): Style[] {
  const stroke = additionalLayer.style.outline_width > 0 ? new Stroke({ color: additionalLayer.style.outline_color, width: additionalLayer.style.outline_width }) : undefined;
  const styles: Style[] = [
    new Style({
      stroke: stroke,
    }),
  ];
  return styles;
}
