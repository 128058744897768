<template>
    <div v-if="runningInOffice" class="flex justify-end text-sf-orange hover:text-gray-700 text-xs items-center">
        <PlusIcon class="size-4" />
        <button type="button" @click="addStreetSegmentToForm">Add</button>
    </div>
    <h2 class="text-base font-bold mb-1">{{ roadDisplayName }}</h2>
    <table class="w-full border-collapse mb-2">
        <tr v-if="props.overlay_information.properties?.segclass">
            <MapOverlayTableCell>Segment Class</MapOverlayTableCell>
            <MapOverlayTableCell class="capitalize">
                {{ props.overlay_information.properties.segclass }} -
                {{ sanDiegoStreetSegmentClasses[props.overlay_information.properties.segclass] }}
            </MapOverlayTableCell>
        </tr>
        <tr v-if="props.overlay_information.properties?.funclass">
            <MapOverlayTableCell>Functional Class</MapOverlayTableCell>
            <MapOverlayTableCell class="capitalize">
                {{ props.overlay_information.properties.funclass }} -
                {{ sanDiegoStreetFunctionalClasses[props.overlay_information.properties.funclass] }}
            </MapOverlayTableCell>
        </tr>
        <tr v-if="props.overlay_information.properties?.oneway">
            <MapOverlayTableCell>One-way</MapOverlayTableCell>
            <MapOverlayTableCell class="capitalize">
                <template v-if="['F', 'T'].includes(props.overlay_information.properties?.oneway)">
                    Yes ({{ props.overlay_information.properties?.oneway }})
                </template>
                <template v-else-if="props.overlay_information.properties?.oneway === 'B'">
                    No ({{ props.overlay_information.properties?.oneway }})
                </template>
                <template v-else>Unknown</template>
            </MapOverlayTableCell>
        </tr>
        <tr v-if="props.overlay_information.properties?.speed">
            <MapOverlayTableCell>Speed</MapOverlayTableCell>
            <MapOverlayTableCell>{{ props.overlay_information.properties?.speed }} mph</MapOverlayTableCell>
        </tr>
    </table>
    <MapOverlaySourceLink
        href="https://geo.sandag.org/portal/apps/mapviewer/index.html?layers=66ca42638c794646aa85f689a613a5f2"
    >
        SanGIS Viewer (Roads)
    </MapOverlaySourceLink>
    <MapOverlaySourceLink href="https://geo.sandag.org/server/rest/directories/downloads/Roads_All.pdf">
        SanGIS Data Definition (Roads)
    </MapOverlaySourceLink>
</template>

<script setup>
import {
    sanDiegoStreetNameSuffixMapping,
    sanDiegoStreetNameDirectionsMapping,
    sanDiegoStreetSegmentClasses,
    sanDiegoStreetFunctionalClasses,
} from "@/analyses/streetSegments.ts";
import { PlusIcon } from "@heroicons/vue/24/solid";
import { useStreetSegmentsStore } from "@/stores/StreetSegmentsStore.ts";
import { computed, inject } from "vue";
import MapOverlayTableCell from "@/components/MapOverlays/MapOverlayTableCell.vue";
import MapOverlaySourceLink from "@/components/MapOverlays/MapOverlaySourceLink.vue";

const props = defineProps({
    overlay_information: {
        type: Object,
        required: true,
    },
});

const store = useStreetSegmentsStore();

const runningInOffice = inject("appIsRunningInOffice");

const toTitleCase = (str) => {
    return str
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
        .join(" ");
};

const formatStreetSegmentDisplayName = (properties) => {
    // If there is a road_name property, just return that
    if (properties?.road_name) {
        return properties.road_name;
    }

    // Base name of street. Doesn't include pre/post direction or suffix
    const rd30name = properties?.rd30name;
    if (!rd30name) {
        return "Unknown";
    }
    const name = toTitleCase(rd30name);

    // Street suffix - i.e. type of street abbreviation e.g. (RD, LN, ST, etc.)
    const rd30sfx = properties?.rd30sfx;
    // Get the full suffix from the mapping
    const suffix = rd30sfx ? sanDiegoStreetNameSuffixMapping[rd30sfx] : null;

    // Street predirection - i.e. direction of street e.g. (N, S, E, W, NE, NW, SE, SW)
    // BEFORE the street name
    const rd30pred = properties?.rd30pred;
    // Get the full predirection from the mapping
    const predirection = rd30pred ? sanDiegoStreetNameDirectionsMapping[rd30pred] : null;

    // Street postdirection - i.e. direction of street e.g. (N, S, E, W, NE, NW, SE, SW)
    // AFTER the street name
    const rd30postd = properties?.rd30postd;
    // Get the full postdirection from the mapping
    const postdirection = rd30postd ? sanDiegoStreetNameDirectionsMapping[rd30postd] : null;

    let displayName = "";
    if (predirection) {
        displayName += `${predirection} `;
    }
    displayName += `${name}`;

    if (suffix) {
        displayName += ` ${suffix}`;
    }

    if (postdirection) {
        displayName += ` ${postdirection}`;
    }

    return displayName;
};

const roadDisplayName = computed(() => {
    return formatStreetSegmentDisplayName(props.overlay_information.properties);
});

const addStreetSegmentToForm = () => {
    const streetSegment = {
        name: roadDisplayName.value,
    };
    store.addStreetSegment(streetSegment);
};
</script>

<style scoped>
tr:nth-child(odd) {
    background-color: #f2f2f2; /* Light gray for odd rows */
}
tr:nth-child(even) {
    background-color: #ffffff; /* White for even rows */
}
</style>
