<template>
    <SchemeflowMap
        mapKey="bus_stops_map"
        isochrone-profile="select"
        :legendConfigMasterList="['site_loc', 'isochrones', 'bus_stops', 'buffer_zones']"
        :markReadyForExportMilliseconds="2500"
    ></SchemeflowMap>
</template>

<script setup>
import { useFormDataStore } from "@/stores/FormDataStore";
import { onMounted } from "vue";
import { legendItemFromConfig } from "@/maps/configIcons";
import SchemeflowMap from "@/components/SchemeflowMap.vue";

const store = useFormDataStore();

onMounted(async () => {
    // Show bus stops overlay layer on map
    store.maps.bus_stops_map.toggleOverlayLayer("bus_stops", true);
    store.maps.bus_stops_map.legend_config.bus_stops = [
        legendItemFromConfig({ item: "bus_stop", mapKey: "bus_stops_map" }),
    ];

    // Highlight bus stops when hovered over
    store.maps.bus_stops_map.getMap().on("pointermove", function (e) {
        store.maps.bus_stops_map.busStopsSource.forEachFeature((feature) => {
            store.unhighlightBusStop(feature.get("atcocode"));
        });
        store.maps.bus_stops_map.getMap().forEachFeatureAtPixel(e.pixel, function (feature) {
            if (feature.get("atcocode")) {
                store.highlightBusStop(feature.get("atcocode"));
            }
        });
    });
});
</script>
