
/**
 * Parses a value from XML.
 * @param value - The value to parse.
 * @param fallbackValue - The fallback value to return if the value is null, undefined, or an object.
 * @returns The parsed value as a string, or the fallback value if the value is null, undefined, or an object.
 */
export const parseValue = (value: any, fallbackValue: any = undefined) => {
    if (value === null || value === undefined || typeof value === 'object') {
        return fallbackValue;
    }
    return value.toString();
};

/**
 * Parses a number from XML.
 * @param value - The value to parse.
 * @returns The parsed number, or undefined if the value is null, undefined, or an object.
 */
export const parseNumber = (value: any) => {
    const parsed = parseValue(value);

    // If the parsed value is a string, try to parse it as an integer
    if ( typeof parsed === 'string' ) {
        return parseInt(parsed);
    }

    // If the parsed value is not a string, return it as is
    return parsed;
};
