<template>
    <div ref="overlay_element" class="relative">
        <!-- Triangle pointer -->
        <div
            class="absolute left-1/2 -translate-x-1/2 -top-2 w-0 h-0 border-l-8 border-r-8 border-b-8 border-l-transparent border-r-transparent border-b-white"
        ></div>
        <div class="w-64 w-max-64 h-54 h-max-64 bg-white text-black p-2 text-xs mt-2">
            <div class="w-full h-full overflow-y-auto">
                <component
                    v-if="overlayComponent"
                    :is="overlayComponent"
                    :overlay_information="props.overlay_information"
                    @setRedlinePolygon="handleSetRedlinePolygon"
                />
                <div
                    v-if="
                        props.overlay_information.latitude !== undefined &&
                        props.overlay_information.longitude !== undefined
                    "
                    class="w-full h-40 mt-1"
                >
                    <GoogleStreetView
                        :latitude="props.overlay_information.latitude"
                        :longitude="props.overlay_information.longitude"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
import GoogleStreetView from "@/components/GoogleStreetView.vue";
const overlay_element = ref(null);
import SanGISRoadsOverlay from "@/components/MapOverlays/SanGISRoadsOverlay.vue";
import SanGISBikewaysOverlay from "@/components/MapOverlays/SanGISBikewaysOverlay.vue";
import SanGISAPNParcelOverlay from "@/components/MapOverlays/SanGISAPNParcelOverlay.vue";
const props = defineProps({
    overlay_information: {
        type: Object,
        required: true,
    },
});

const overlayComponent = computed(() => {
    if (!props.overlay_information?.properties) {
        return null;
    }

    if (Object.hasOwn(props.overlay_information?.properties, "apn")) {
        return SanGISAPNParcelOverlay;
    }

    if (Object.hasOwn(props.overlay_information?.properties, "road_name")) {
        return SanGISBikewaysOverlay;
    }

    if (Object.hasOwn(props.overlay_information?.properties, "rd30name")) {
        return SanGISRoadsOverlay;
    }

    return null;
});

defineExpose({
    overlay_element,
});

const emit = defineEmits(["setRedlinePolygon"]);

const handleSetRedlinePolygon = (polygon) => {
    emit("setRedlinePolygon", polygon);
};
</script>
