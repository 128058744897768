/* global Office */
import "./style.css";

import { createApp, markRaw, ref } from "vue";
import { createPinia } from "pinia";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import SchemeflowMap from "./components/SchemeflowMap.vue";
import FormSection from "./components/FormSection.vue";
import { useUserStore } from "./stores/UserStore";
import posthog from "posthog-js";
import axiosRetry from "axios-retry";
import FloatingVue from "floating-vue";
import { plugin, defaultConfig } from "@formkit/vue";
import { createAutoHeightTextareaPlugin } from "@formkit/addons";
import * as Sentry from "@sentry/vue";
import { createProPlugin, autocomplete, repeater, colorpicker } from "@formkit/pro";
import { check, circle, genesisIcons } from "@formkit/icons";
import { VueQueryPlugin } from "@tanstack/vue-query";

const axiosInstance = axios.create();
axiosInstance.interceptors.response.use(
    (request) => {
        return request;
    },
    (error) => {
        const status = error.response ? error.response.status : null;

        if (status === 401) {
            // Handle unauthorized access
            const store = useUserStore();
            store.userThinksTheyAreLoggedIn = false;
            router.push({ name: "login" });
        }

        return Promise.reject(error);
    }
);
axiosRetry(axiosInstance, { retryDelay: axiosRetry.exponentialDelay });

const pinia = createPinia();
// Add router to store
pinia.use(({ store }) => {
    store.router = markRaw(router);
});

const app = createApp(App);

posthog.init("phc_85vblFDXC1IdL3kpXJSD6giT0QJmyE0MIVG4CGVDTqY", {
    api_host: "https://eu.i.posthog.com",
    person_profiles: "identified_only",
});

// detect if running in dev environment
if (import.meta.env.PROD) {
    // Only enable Sentry in production
    Sentry.init({
        app,
        dsn: "https://7e4b9fcb65dddd229a7d9fbbfcf411fb@o4507632672243712.ingest.de.sentry.io/4507632684957776",
        integrations: [Sentry.browserTracingIntegration({ router })],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/app\.schemeflow\.com\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        trackComponents: true,
    });
}

app.component("SchemeflowMap", SchemeflowMap);
app.component("FormSection", FormSection);

app.provide("axios", axiosInstance);
app.provide("posthog", posthog);

// Office JS - determine if app is being run in Office add-in or not
const runningInOffice = ref(null);
app.provide("appIsRunningInOffice", runningInOffice);
Office.onReady().then((info) => {
    if (info.host && info.platform) {
        runningInOffice.value = true;
    } else {
        runningInOffice.value = false;
    }
});

app.use(router);
app.use(pinia);

app.use(FloatingVue);

// add FormKit plugin with custom config
import { rootClasses } from "../formkit.theme.mjs";

const pro = createProPlugin("fk-6d98ce0a2a1", {
    autocomplete,
    repeater,
    colorpicker,
});

app.use(
    plugin,
    defaultConfig({
        config: {
            rootClasses,
        },
        icons: {
            check,
            circle,
            ...genesisIcons,
        },
        plugins: [pro, createAutoHeightTextareaPlugin()],
    })
);

import "floating-vue/dist/style.css";

import "@cyhnkckali/vue3-color-picker/dist/style.css";

app.use(VueQueryPlugin);

app.mount("#app");
